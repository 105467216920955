import { cohost } from "../Configs/cohost.config";
import { CustomReducers } from "@reactables/forms";

export interface CohostActions {
  addCohost: () => void;
  removeCohost: () => void;
}

export const cohostReducers: CustomReducers<CohostActions> = {
  addCohost: ({ addControl }, state) =>
    addControl(state, { controlRef: ["cohost"], config: cohost() }),
  removeCohost: ({ removeControl }, state) => removeControl(state, ["cohost"]),
};
