import { useReactable } from "@reactables/react";
import { Form, Field, FormArray, ControlModels } from "@reactables/react-forms";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { EDIT_POLICY_CONTACTS_PAGE, getUrl } from "Helpers/URLParser";
import {
  CheckboxInput,
  DefaultTimezoneContext,
  usePollDocuments,
} from "@jauntin/react-ui";
import { AsyncButton, Button } from "@jauntin/react-ui";
import { RxPolicyProp } from "./RxPolicy";
import { CustomContact } from "Features/Policies/Models/policyContacts.model";
import LoadingSpinner from "Components/LoadingSpinner";
import { RxPolicyContactsForm } from "./RxPolicyContactsForm";
import { getIsAllEmailsSelected } from "Features/Policies/Selectors/policyContactsForm.selector";
import { stringHelpers } from "Helpers/FormattingHelpers";
import PolicyUpdateAlert from "./PolicyUpdateAlert";
import { dateHelpers } from "@jauntin/utilities";
import { downloadDocument, getDocumentsStatus } from "Helpers/DocumentDownload";
import download from "downloadjs";
import { useContext } from "react";

const ViewPolicyContacts = ({ rxPolicy }: { rxPolicy: RxPolicyProp }) => {
  const [
    {
      entity: { data: coverage },
      policyEmails,
    },
    rxPolicyActions,
  ] = rxPolicy;

  const [state, actions] = useReactable(RxPolicyContactsForm, {
    coverage,
    sources: [],
    edit: false,
  });

  const timezone = useContext(DefaultTimezoneContext);

  const { downloading, startDownload } = usePollDocuments({
    getDocumentsStatus: () => getDocumentsStatus(coverage.latestOrderNumber),
    getDocuments: () => downloadDocument(coverage),
    downloadFn: download,
    fileDownloadName: coverage.policyDocumentFilename,
  });

  if (!state) return;

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">View Contacts</h4>
          <div className="d-flex float-right">
            {coverage.status !== "expired" && (
              <>
                <Button
                  text="Send Document"
                  disabled={!state.root.valid || policyEmails.loading}
                  className="btn btn-outline-secondary ml-auto border-radius-0"
                  onClick={() =>
                    rxPolicyActions.policyEmails.send({
                      form: state,
                      coverage,
                    })
                  }
                />
                <Link to={getUrl(EDIT_POLICY_CONTACTS_PAGE, coverage.id)}>
                  <Button
                    text="Edit Contacts"
                    className="btn btn-primary px-4 mx-2 text-nowrap"
                    onClick={() => {}}
                  />
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="content__body">
        {coverage.policyHasUnacknowledgedChanges && (
          <PolicyUpdateAlert
            close={() =>
              rxPolicyActions.acknowledgePolicyUpdated.send(coverage)
            }
          />
        )}
        <Form rxForm={[state, actions]}>
          <div className="mb-4">
            {coverage.status !== "expired" && getIsAllEmailsSelected(state) ? (
              <Button
                text="Deselect All Recipients"
                className="btn btn-outline-primary small"
                onClick={actions.deselectAllEmails}
              ></Button>
            ) : (
              <Button
                text="Select All Recipients"
                className="btn btn-outline-primary small"
                onClick={actions.selectAllEmails}
              ></Button>
            )}
          </div>
          <div className="d-flex">
            <Field
              name="emailInsuranceContact"
              component={CheckboxInput}
              className="mt-2 mr-2"
            />
            <Col>
              <Card className="mb-4">
                <Card.Header className="bg-white font-weight-bold">
                  Insurance Contact
                </Card.Header>
                <Card.Body>
                  <>
                    <p>
                      {coverage.insuranceContactFirstName}{" "}
                      {coverage.insuranceContactLastName}
                    </p>
                    {coverage.contactSame !== "yes" &&
                      coverage.insuranceContactType !== "Person" && (
                        <p>{coverage.insuranceContactCompanyName}</p>
                      )}
                    {coverage.contactSame !== "yes" && (
                      <p>
                        {stringHelpers.address(
                          coverage.insuranceContactAddress1,
                          coverage.insuranceContactAddress2,
                          coverage.insuranceContactCity,
                          coverage.insuranceContactState,
                          coverage.insuranceContactCountry,
                          coverage.insuranceContactZip
                        )}
                      </p>
                    )}
                    <p>{`${coverage.insuranceContactPhone} - ${coverage.insuranceContactEmail}`}</p>
                  </>
                </Card.Body>
              </Card>
            </Col>
          </div>
          <FormArray name="emailProducerContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email, producerName, role } =
                    coverage.producerContacts[index];
                  return (
                    <div className="d-flex" key={key}>
                      <Field
                        name={`emailProducerContacts.${index}`}
                        component={CheckboxInput}
                        className="mt-2 mr-2"
                      />
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Producer Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            {fullName && <div>{fullName}</div>}
                            {producerName && <div>{producerName}</div>}
                            {role && <div>{role}</div>}
                            <div>{email}</div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </div>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="emailFacilityContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email, role } =
                    coverage.facilityContacts[index];

                  return (
                    <div className="d-flex" key={key}>
                      <Field
                        name={`emailFacilityContacts.${index}`}
                        component={CheckboxInput}
                        className="mt-2 mr-2"
                      />
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Facility Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            {fullName && <div>{fullName}</div>}
                            {role && <div>{role}</div>}
                            <div>{email}</div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </div>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="emailVenueContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email, role } =
                    coverage.venueContacts[index];

                  return (
                    <div className="d-flex" key={key}>
                      <Field
                        name={`emailVenueContacts.${index}`}
                        component={CheckboxInput}
                        className="mt-2 mr-2"
                      />
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Venue Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            {fullName && <div>{fullName}</div>}
                            {role && <div>{role}</div>}
                            <div>{email}</div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </div>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="customContacts">
            {({ items }) => (
              <>
                {items.map(
                  (
                    {
                      key,
                      value: { contactInfo },
                    }: ControlModels.FormControl<{
                      sendEmail: boolean;
                      contactInfo: CustomContact;
                    }>,
                    index
                  ) => {
                    return (
                      <div className="d-flex" key={key}>
                        <Field
                          name={`customContacts.${index}.sendEmail`}
                          component={CheckboxInput}
                          className="mt-2 mr-2"
                        />
                        <Col>
                          <Card className="mb-4">
                            <Card.Header className="bg-white font-weight-bold d-flex justify-content-between">
                              Custom Contact #{index + 1}
                            </Card.Header>
                            <Card.Body>
                              <Row>
                                <Col md={6}>{contactInfo.fullName}</Col>
                              </Row>
                              <Row>
                                <Col md={6}>{contactInfo.email}</Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </div>
                    );
                  }
                )}
              </>
            )}
          </FormArray>
          <div className="d-flex mt-3">
            <span className="d-flex align-items-center p-3">
              <i className="far fa-file-pdf fa-2x" />
            </span>
            <div className="py-3">
              <AsyncButton
                spinning={downloading}
                disabled={downloading}
                onClick={async () => {
                  setTimeout(startDownload, 2000);
                }}
                className="btn btn-link policy__download d-flex"
              >
                Latest Policy Document
              </AsyncButton>
              <div className="contacts__updated">
                <em>
                  {`Updated on ${dateHelpers.lettersAndNumbers(
                    coverage.updated,
                    timezone
                  )} at ${dateHelpers.simpleTime(coverage.updated, timezone)}`}
                </em>
              </div>
            </div>
          </div>
        </Form>
      </div>

      {policyEmails.loading && (
        <div className="disable-overlay">
          <div className="disable-overlay__inner">
            <LoadingSpinner />
            <div className="h6 mt-2">Sending...</div>
          </div>
        </div>
      )}
      <Modal
        show={policyEmails.success || Boolean(policyEmails.error)}
        size="lg"
      >
        <Modal.Header
          className="w-100 border-0 pb-0"
          closeButton
          onHide={rxPolicyActions.policyEmails.resetState}
        />
        <Modal.Body className="text-center px-5">
          <p>
            {policyEmails.success
              ? "Policy documents successfully sent."
              : "There was an error sending policy documents. Please try again."}
          </p>
          <br />
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 mb-3">
          <Button
            className="btn btn-primary px-5"
            onClick={rxPolicyActions.policyEmails.resetState}
            text="OK"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewPolicyContacts;
