import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import notePropType from "./NoteModel";
import { differenceInDays, addDays, isSameDay } from "date-fns";
import { toDate } from "date-fns-tz";
import {
  PROVIDER_TYPE_PERFORMER,
  PROVIDER_TYPE_SELLING_FOOD,
  PROVIDER_TYPE_SELLING_GOODS,
  PROVIDER_TYPE_EXHIBITOR,
} from "../constants";
import { BY_MANUAL_ADDRESS } from "./VenueSearchTypes";
import { dateHelpers } from "@jauntin/utilities";
const { getFirstEntry, getLastEntry } = dateHelpers;

/**
 *
 * Model used to represent a `Coverage`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyAdditionalInsured = {
  id: 0,
  text: "",
  companyName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  zip: "",
};
const emptyCoverageNotes = {
  id: 0,
  orderNumber: "",
  type: "",
  message: "",
  userName: "",
  created: "",
};
const emptyCoverage = {
  id: 0,
  created: "",
  updated: "",
  status: "",
  eventType: "",
  eventTypeName: "",
  eventName: "",
  venueGll: "1000000",
  eventDates: [],
  effectiveDate: null,
  purchaseDate: null,
  daysOfWeekField: [],
  eventDateRange: {
    startDate: null,
    endDate: null,
  },
  eventFrequencyField: "continuous",
  isOneTime: true,
  eventDailyGuests: 1,
  groupSize: 1,
  eventPerformers: 0,
  eventGoods: 0,
  eventFood: 0,
  eventExhibition: 0,
  performersFrequency: "",
  goodsVendorsFrequency: "",
  foodVendorsFrequency: "",
  exhibitorsFrequency: "",
  performersList: [],
  goodsVendorsList: [],
  foodVendorsList: [],
  exhibitorsList: [],

  insuranceContactPhone: "",
  insuranceContactEmail: "",
  insuranceContactAddressId: 0,
  insuranceContactType: "",
  insuranceContactFirstName: "",
  insuranceContactLastName: "",
  insuranceContactCompanyName: "",
  insuranceContactAddress1: "",
  insuranceContactAddress2: "",
  insuranceContactCity: "",
  insuranceContactState: "",
  insuranceContactCountry: "",
  insuranceContactZip: "",

  renterAddressId: 0,
  renterAddressType: "",
  renterFirstName: "",
  renterLastName: "",
  renterCompanyName: "",
  renterAddress1: "",
  renterAddress2: "",
  renterCity: "",
  renterState: "",
  renterCountry: "",
  renterZip: "",

  insuredCompanyType: "",
  jointVentureType: "Joint Venture Corporation",

  venueSearchTypeRadio: "bySearch",
  venueAddressId: 0,
  venueName: "",
  venueGooglePlaceId: "",
  venueAddressType: "",
  venueFirstName: "",
  venueLastName: "",
  venueCompanyName: "",
  venueAddress1: "",
  venueAddress2: "",
  venueCity: "",
  venueState: "",
  venueCountry: "",
  venueZip: "",
  venueUtcOffset: 0,
  venueMunicipalityCode: "",
  venueGlLimits: [],
  venueFacilityId: 0,
  venueId: 0,
  facilityReferralCode: "",
  facilityCode: "",
  hideFacilityInfo: false,
  venueCode: "",
  manualVenueCompanyName: "",
  manualVenuePlaceId: null,
  manualVenueAddress: "",
  manualVenueCity: "",
  manualVenueState: "",
  manualVenueZip: "",
  manualVenueCountry: "US",
  isValidManualAddress: true,
  isValidGll: true,
  isValidDrp: true,
  isValidLL: true,
  isBlocked: false,

  quoteBasicCoverageAmount: 0,
  quoteSurchargeAmount: 0,
  quoteSurcharges: {
    federalSurcharge: 0,
    stateSurcharge: 0,
  },
  isFederalEntity: true,
  isKentuckyEntity: true,
  quoteAdditionalCoverageAmount: 0,
  quoteSubtotal: 0,
  quoteTotal: 0,
  quoteHostLiquorAmount: 0,
  quoteTerrorismAmount: 0,
  quoteDamageToRentedPropertyAmount: 0,
  quoteLiquorLiabilityAmount: 0,
  quotePersonalPropertyAmount: 0,

  policyNumberGll: "",
  policyNumberHostLiquor: "",
  policyNumberLiquorLiability: "",
  policyNumberPersonalProperty: "",
  policyNumberCancellation: "",

  additionalCoverageHostLiquor: true,
  additionalCoverageTerrorism: true,
  additionalCoverageDamageToRentedProperty: false,
  additionalCoverageLiquorLiability: false,
  additionalCoveragePersonalProperty: false,

  policyHasUnacknowledgedChanges: false,

  additionalInsured: [],

  coverageNotes: [],

  transactionId: "",
  nameOnCard: "",

  producerContacts: [],
  facilityContacts: [],
  venueContacts: [],
  customContacts: [],
  decFormsAreTheSame: true,
};

/**
 *
 * PROP TYPES
 *
 */
const additionalInsuredPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  text: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
});
const coverageNotesPropType = notePropType;
const contactsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string,
    email: PropTypes.string.isRequired,
  })
);
const coveragePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  eventTypeName: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  venueGll: PropTypes.string.isRequired,
  eventDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  effectiveDate: PropTypes.string,
  purchaseDate: PropTypes.string,
  daysOfWeekField: PropTypes.arrayOf(PropTypes.bool).isRequired,
  eventDateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  isOneTime: PropTypes.bool.isRequired,
  eventFrequencyField: PropTypes.string.isRequired,
  eventDailyGuests: PropTypes.number.isRequired,
  // Group size is for legacy policies without averageDailyAttendance
  groupSize: PropTypes.number,
  eventPerformers: PropTypes.number.isRequired,
  eventGoods: PropTypes.number.isRequired,
  eventFood: PropTypes.number.isRequired,
  eventExhibition: PropTypes.number.isRequired,
  performersFrequency: PropTypes.string.isRequired,
  goodsVendorsFrequency: PropTypes.string.isRequired,
  foodVendorsFrequency: PropTypes.string.isRequired,
  exhibitorsFrequency: PropTypes.string.isRequired,
  performersList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      days: PropTypes.number,
    })
  ),
  goodsVendorsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      days: PropTypes.number,
    })
  ),
  foodVendorsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      days: PropTypes.number,
    })
  ),
  exhibitorsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      days: PropTypes.number,
    })
  ),

  insuranceContactPhone: PropTypes.string.isRequired,
  insuranceContactEmail: PropTypes.string.isRequired,
  insuranceContactAddressId: PropTypes.number.isRequired,
  insuranceContactType: PropTypes.string.isRequired,
  insuranceContactFirstName: PropTypes.string.isRequired,
  insuranceContactLastName: PropTypes.string.isRequired,
  insuranceContactCompanyName: PropTypes.string.isRequired,
  insuranceContactAddress1: PropTypes.string.isRequired,
  insuranceContactAddress2: PropTypes.string.isRequired,
  insuranceContactCity: PropTypes.string.isRequired,
  insuranceContactState: PropTypes.string.isRequired,
  insuranceContactCountry: PropTypes.string.isRequired,
  insuranceContactZip: PropTypes.string.isRequired,

  renterAddressId: PropTypes.number.isRequired,
  renterAddressType: PropTypes.string.isRequired,
  renterFirstName: PropTypes.string.isRequired,
  renterLastName: PropTypes.string.isRequired,
  renterCompanyName: PropTypes.string.isRequired,
  renterAddress1: PropTypes.string.isRequired,
  renterAddress2: PropTypes.string.isRequired,
  renterCity: PropTypes.string.isRequired,
  renterState: PropTypes.string.isRequired,
  renterCountry: PropTypes.string.isRequired,
  renterZip: PropTypes.string.isRequired,

  insuredCompanyType: PropTypes.string.isRequired,
  jointVentureType: PropTypes.string.isRequired,

  venueSearchTypeRadio: PropTypes.string.isRequired,
  venueAddressId: PropTypes.number.isRequired,
  venueName: PropTypes.string.isRequired,
  venueGooglePlaceId: PropTypes.string,
  venueAddressType: PropTypes.string.isRequired,
  venueFirstName: PropTypes.string.isRequired,
  venueLastName: PropTypes.string.isRequired,
  venueCompanyName: PropTypes.string.isRequired,
  venueAddress1: PropTypes.string.isRequired,
  venueAddress2: PropTypes.string.isRequired,
  venueCity: PropTypes.string.isRequired,
  venueState: PropTypes.string.isRequired,
  venueCountry: PropTypes.string.isRequired,
  venueZip: PropTypes.string.isRequired,
  venueUtcOffset: PropTypes.number.isRequired,
  venueMunicipalityCode: PropTypes.string.isRequired,
  venueGlLimits: PropTypes.arrayOf(PropTypes.number).isRequired,
  venueFacilityId: PropTypes.number.isRequired,
  venueId: PropTypes.number.isRequired,
  facilityReferralCode: PropTypes.string.isRequired,
  facilityCode: PropTypes.string.isRequired,
  hideFacilityInfo: PropTypes.bool.isRequired,
  venueCode: PropTypes.string.isRequired,
  manualVenueCompanyName: PropTypes.string.isRequired,
  manualVenuePlaceId: PropTypes.string,
  manualVenueAddress: PropTypes.string.isRequired,
  manualVenueCity: PropTypes.string.isRequired,
  manualVenueState: PropTypes.string.isRequired,
  manualVenueZip: PropTypes.string.isRequired,
  manualVenueCountry: PropTypes.string.isRequired,
  isValidManualAddress: PropTypes.bool.isRequired,
  isValidGll: PropTypes.bool.isRequired,
  isValidDrp: PropTypes.bool.isRequired,
  isValidLL: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool.isRequired,

  quoteBasicCoverageAmount: PropTypes.number.isRequired,
  quoteSurchargeAmount: PropTypes.number.isRequired,
  quoteSurcharges: PropTypes.shape({
    federalSurcharge: PropTypes.number,
    stateSurcharge: PropTypes.number,
  }).isRequired,
  isFederalEntity: PropTypes.bool.isRequired,
  isKentuckyEntity: PropTypes.bool.isRequired,
  quoteAdditionalCoverageAmount: PropTypes.number.isRequired,
  quoteSubtotal: PropTypes.number.isRequired,
  quoteTotal: PropTypes.number.isRequired,
  quoteHostLiquorAmount: PropTypes.number.isRequired,
  quoteTerrorismAmount: PropTypes.number.isRequired,
  quoteDamageToRentedPropertyAmount: PropTypes.number.isRequired,
  quoteLiquorLiabilityAmount: PropTypes.number.isRequired,
  quotePersonalPropertyAmount: PropTypes.number.isRequired,

  policyNumberGll: PropTypes.string.isRequired,
  policyNumberHostLiquor: PropTypes.string.isRequired,
  policyNumberLiquorLiability: PropTypes.string.isRequired,
  policyNumberPersonalProperty: PropTypes.string.isRequired,
  policyNumberCancellation: PropTypes.string.isRequired,

  additionalCoverageHostLiquor: PropTypes.bool.isRequired,
  additionalCoverageDamageToRentedProperty: PropTypes.bool.isRequired,
  additionalCoverageLiquorLiability: PropTypes.bool.isRequired,
  additionalCoveragePersonalProperty: PropTypes.bool.isRequired,

  policyHasUnacknowledgedChanges: PropTypes.bool.isRequired,

  additionalInsured: PropTypes.arrayOf(additionalInsuredPropType),

  coverageNotes: PropTypes.arrayOf(coverageNotesPropType),

  transactionId: PropTypes.string.isRequired,
  nameOnCard: PropTypes.string.isRequired,

  producerContacts: contactsPropType,
  facilityContacts: contactsPropType,
  venueContacts: contactsPropType,
  customContacts: contactsPropType,

  decFormsAreTheSame: PropTypes.bool,
});

const getAdditionalCoverageQuoteAmount = (quote, type) =>
  parseFloat(
    quote.additional_coverages.reduce(
      (prev, curr) =>
        curr.additional_coverage.type === type ? curr.amount : prev,
      0
    )
  );

const hasAdditionalCoverage = (additionalCoverages, type) => {
  return additionalCoverages.reduce(
    (prev, curr) => curr.additional_coverage.type === type || prev,
    false
  );
};

const getDamageToRentedPropertyCoverageAmount = (coverage) =>
  parseFloat(
    coverage.reduce(
      (prev, curr) =>
        curr.additional_coverage.type === "damage-to-rented-property"
          ? curr.value
          : prev,
      0
    )
  );

const getPolicyHasUnacknowledgedChanges = (coverage) => {
  return coverage.status !== "cancelled"
    ? coverage.coverage_changes.reduce(
        (accumulator, current) => current.acknowledged === false || accumulator,
        false
      )
    : false;
};

const checkValidWeeklyDates = (dates) => {
  const datesArray = dates.map((d) => new Date(d));
  const firstDay = getFirstEntry(datesArray);
  const lastDay = getLastEntry(datesArray);

  if (differenceInDays(lastDay, firstDay) < 7) {
    return true;
  }

  const daysOfWeekAsDays = [];
  const patternAsInt = [];
  const patternDates = [];
  let patternSet = false;
  let patternCounter = 0;
  datesArray.forEach((day) => daysOfWeekAsDays.push(day.getDay()));

  for (let i = 0; i < daysOfWeekAsDays.length; i += 1) {
    // Logic to establish 'weekly' pattern
    if (!patternSet) {
      if (!patternAsInt.includes(daysOfWeekAsDays[i])) {
        patternAsInt.push(daysOfWeekAsDays[i]);
        patternDates[i] = datesArray[i];
      } else {
        patternSet = true;
        if (!isSameDay(addDays(patternDates[0], 7), datesArray[i])) {
          return false;
        }
        patternDates[0] = datesArray[i];
        patternCounter += 1;
      }
    } else {
      // Check subsequent dates 7 days apart from
      // the corresponding pattern dates
      if (patternCounter > patternDates.length - 1) {
        patternCounter = 0;
      }
      if (!isSameDay(addDays(patternDates[patternCounter], 7), datesArray[i])) {
        return false;
      }
      patternDates[patternCounter] = datesArray[i];
      patternCounter += 1;
    }
  }

  return true;
};

const getDaysOfWeekField = (dates) => {
  const datesArray = dates.map((d) => new Date(d));
  let daysOfWeekAsDays = [];
  const daysOfWeek = [];
  const patternAsInt = [];
  const firstDay = getFirstEntry(datesArray);
  const lastDay = getLastEntry(datesArray);

  datesArray.forEach((day) => daysOfWeekAsDays.push(day.getDay()));

  // If checkWeeklyDates() returns false, dates don't correspond
  // with 'weekly' selection. Returns empty daysOfWeek.
  if (checkValidWeeklyDates(datesArray)) {
    if (differenceInDays(lastDay, firstDay) > 7) {
      for (let i = 0; i < daysOfWeekAsDays.length; i += 1) {
        if (!patternAsInt.includes(daysOfWeekAsDays[i])) {
          patternAsInt.push(daysOfWeekAsDays[i]);
        } else {
          break;
        }
      }
      daysOfWeekAsDays = patternAsInt;
    }
    daysOfWeekAsDays.sort((a, b) => a - b);
    for (let i = 0; i < 7; i += 1) {
      if (daysOfWeekAsDays.includes(i)) {
        daysOfWeek[i] = true;
      } else {
        daysOfWeek[i] = null;
      }
    }
  }

  return daysOfWeek;
};

const setSurcharge = (surcharges, isFederalEntity, isKentuckyEntity) => {
  let federalSurcharge = 0;
  let stateSurcharge = 0;
  if (surcharges.length === 1) {
    if (
      (!isFederalEntity && isKentuckyEntity) ||
      (!isFederalEntity && !isKentuckyEntity)
    ) {
      stateSurcharge = parseInt(surcharges[0].amount, 10);
    } else {
      federalSurcharge = parseInt(surcharges[0].amount, 10);
    }
  } else if (surcharges.length === 2) {
    federalSurcharge = parseInt(surcharges[0].amount, 10);
    stateSurcharge = parseInt(surcharges[1].amount, 10);
  }
  return { federalSurcharge, stateSurcharge };
};

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerCoverageToCoverageModel = (coverage, timezone) => {
  const coverageApplication = coverage.coverage_application;
  const {
    producerContacts,
    facilityContacts,
    venueContacts,
    coverageContacts,
  } = coverage;
  const additionalCoverages = coverageApplication.additional_coverages;
  const billingAddress = coverageApplication.billing_address;
  const insuranceContactAddress = coverageApplication.insurance_contact_address;
  const renterAddress = coverageApplication.renter_address;
  const venue = coverageApplication.venue_addresses[0] || {
    venue: null,
    address: null,
  };
  const venueInfo = venue.venue;
  const venueAddress = venue.address;
  const coverageOrder = coverage.coverage_orders[0];
  const coverageChanged =
    coverage.coverage_changes[coverage.coverage_changes.length - 1];
  const { quote } = coverageOrder;
  const monetaryTransaction = coverageOrder.monetary_transaction;
  const model = cloneDeep(emptyCoverage);

  model.id = coverage.id;
  model.created = coverage.created_at;
  model.latestOrderNumber = coverage.coverage_orders.reduce(
    (prev, curr) => (curr.id > prev ? curr.id : prev),
    0
  );
  model.updated = coverageChanged
    ? coverageChanged.updated_at
    : coverage.updated_at;
  model.status = coverage.status;
  model.decFormsAreTheSame = coverage.decFormsAreTheSame;
  model.eventType = coverageApplication.event_type.identifier;
  model.eventTypeName = coverageApplication.event_type.name;
  model.eventName = coverageApplication.name;
  model.venueGll = parseInt(coverageApplication.gll, 10).toString();
  // Ensure dates from server are matched with the apps timezone
  model.eventDates = coverageApplication.event_dates.map(({ date }) =>
    toDate(date, { timeZone: timezone }).toISOString()
  );
  model.effectiveDate = coverageApplication.effective_date;
  model.purchaseDate = coverage.purchase_date;
  model.eventDateRange = {
    startDate: getFirstEntry(
      model.eventDates.map((d) => new Date(d))
    ).toISOString(),
    endDate: getLastEntry(
      model.eventDates.map((d) => new Date(d))
    ).toISOString(),
  };

  model.eventFrequencyField = coverageApplication.eventFrequency;

  // If getDaysOfWeekField() returns empty array, eventDates verified
  // as not having 'weekly' pattern, therefore set eventFrequencyField
  // to 'custom' instead
  if (
    model.eventFrequencyField === "weekly" &&
    !getDaysOfWeekField(model.eventDates).length
  ) {
    model.eventFrequencyField = "custom";
  }

  model.isOneTime = coverageApplication.isOneTime;
  model.daysOfWeekField =
    model.eventFrequencyField === "weekly"
      ? getDaysOfWeekField(model.eventDates)
      : [];
  model.eventDailyGuests = parseInt(
    coverageApplication.averageDailyAttendance,
    10
  );
  model.groupSize = coverageApplication.groupSize;
  model.eventPerformers = parseInt(coverageApplication.numberOfPerformers, 10);
  model.eventGoods = parseInt(coverageApplication.numberSellingGoods, 10);
  model.eventFood = parseInt(coverageApplication.numberSellingFood, 10);
  model.eventExhibitors = parseInt(coverageApplication.numberOfExhibitors, 10);

  [
    ["performersFrequency", "performersEveryDay"],
    ["goodsVendorsFrequency", "sellingGoodsEveryDay"],
    ["foodVendorsFrequency", "sellingFoodEveryDay"],
    ["exhibitorsFrequency", "exhibitorsEveryDay"],
  ].forEach(([frequency, everyday]) => {
    model[frequency] = coverageApplication[everyday] ? "everyday" : "";
  });

  coverageApplication.event_providers.forEach((provider) => {
    if (provider.type === PROVIDER_TYPE_PERFORMER) {
      model.performersList.push({
        name: provider.name,
        days: provider.numberOfDays,
      });
    }
    if (provider.type === PROVIDER_TYPE_SELLING_GOODS) {
      model.goodsVendorsList.push({
        name: provider.name,
        days: provider.numberOfDays,
      });
    }
    if (provider.type === PROVIDER_TYPE_SELLING_FOOD) {
      model.foodVendorsList.push({
        name: provider.name,
        days: provider.numberOfDays,
      });
    }
    if (provider.type === PROVIDER_TYPE_EXHIBITOR) {
      model.exhibitorsList.push({
        name: provider.name,
        days: provider.numberOfDays,
      });
    }
  });

  model.insuranceContactPhone = coverageApplication.insuranceContactPhone;
  model.insuranceContactEmail = coverageApplication.insuranceContactEmail;
  model.insuranceContactAddressId = insuranceContactAddress.id;
  model.insuranceContactType =
    insuranceContactAddress.contactType === "Person"
      ? insuranceContactAddress.contactType
      : "company";
  model.insuranceContactFirstName =
    insuranceContactAddress.contactFirstName || "";
  model.insuranceContactLastName =
    insuranceContactAddress.contactLastName || "";
  model.insuranceContactCompanyName = insuranceContactAddress.companyName || "";
  model.insuranceContactAddress1 = insuranceContactAddress.address1;
  model.insuranceContactAddress2 = insuranceContactAddress.address2 || "";
  model.insuranceContactCity = insuranceContactAddress.city;
  model.insuranceContactState = insuranceContactAddress.state;
  model.insuranceContactCountry = insuranceContactAddress.country;
  model.insuranceContactZip = insuranceContactAddress.zip;

  model.renterAddressId = renterAddress.id;
  model.renterAddressType =
    renterAddress.contactType === "Person"
      ? renterAddress.contactType
      : "company";
  model.renterContactType = renterAddress.contactType;
  model.renterFirstName = renterAddress.contactFirstName || "";
  model.renterLastName = renterAddress.contactLastName || "";
  model.renterCompanyName = renterAddress.companyName || "";
  model.renterAddress1 = renterAddress.address1;
  model.renterAddress2 = renterAddress.address2 || "";
  model.renterCity = renterAddress.city;
  model.renterState = renterAddress.state;
  model.renterCountry = renterAddress.country;
  model.renterZip = renterAddress.zip;
  model.venueMunicipalityCode = venue.municipalTaxCode || "";

  model.contactSame =
    model.insuranceContactType === model.renterAddressType &&
    model.insuranceContactAddress1 === model.renterAddress1 &&
    model.insuranceContactCity === model.renterCity &&
    model.insuranceContactState === model.renterState &&
    model.insuranceContactZip === model.renterZip &&
    model.insuranceContactCountry === model.renterCountry &&
    (model.insuranceContactType !== "Person"
      ? model.insuranceContactCompanyName === model.renterCompanyName
      : true)
      ? "yes"
      : "no";

  model.insuredCompanyType =
    renterAddress.contactType !== "Person"
      ? renterAddress.contactType
      : "Individual";

  if (model.insuredCompanyType.toLowerCase().includes("joint venture")) {
    model.jointVentureType = model.insuredCompanyType;
    model.insuredCompanyType = "jointVenture";
  }

  model.billingTo =
    billingAddress.contactType === "Person"
      ? `${billingAddress.contactFirstName} ${billingAddress.contactLastName}`
      : `${billingAddress.companyName}`;

  model.facilityReferralCode = coverageApplication.facilityReferralCode || "";
  model.facilityCode = coverageApplication.facilityCode;
  model.hideFacilityInfo = coverageApplication.hideFacilityInfo;
  model.venueCode = coverageApplication.venueCode;
  model.venueSearchTypeRadio = coverageApplication.venueSearchType;

  if (venueAddress) {
    if (model.venueSearchTypeRadio === BY_MANUAL_ADDRESS) {
      model.manualVenueCompanyName = venueAddress.companyName || "";
      model.manualVenuePlaceId = venueAddress.placeId;
      model.manualVenueAddress = venueAddress.address1;
      model.manualVenueCity = venueAddress.city;
      model.manualVenueState = venueAddress.state;
      model.manualVenueCountry = venueAddress.country;
      model.manualVenueZip = venueAddress.zip;
    } else {
      model.venueGooglePlaceId = venueAddress.placeId;
      model.venueAddressType = venueAddress.contactType;
      model.venueCompanyName = venueAddress.companyName || "";
      model.venueAddress1 = venueAddress.address1;
      model.venueAddress2 = venueAddress.address2 || "";
      model.venueCity = venueAddress.city;
      model.venueState = venueAddress.state;
      model.venueCountry = venueAddress.country;
      model.venueZip = venueAddress.zip;
    }
    model.venueUtcOffset = parseInt(venueAddress.utcOffset || 0, 10);
    model.venueAddressId = venueAddress.id;
  }

  model.isBlocked = !!venue.blockedAt;

  if (producerContacts) {
    model.producerContacts = producerContacts.map((m) => {
      return {
        id: m.id,
        fullName: m.fullName ? m.fullName : "",
        producerName: m.producer.name ? m.producer.name : "",
        role: m.role ? m.role : "",
        email: m.email,
      };
    });
  }

  if (facilityContacts) {
    model.facilityContacts = facilityContacts.map((m) => {
      return {
        id: m.id,
        fullName: m.fullName ? m.fullName : "",
        role: m.role ? m.role : "",
        email: m.email,
      };
    });
  }

  if (venueContacts) {
    model.venueContacts = venueContacts.map((m) => {
      return {
        id: m.id,
        fullName: m.fullName ? m.fullName : "",
        role: m.role ? m.role : "",
        email: m.email,
      };
    });
  }

  if (coverageContacts) {
    model.customContacts = coverageContacts.map((m) => {
      return {
        id: m.id,
        fullName: m.fullName ? m.fullName : "",
        email: m.email,
      };
    });
  }

  if (venueInfo) {
    model.venueGlLimits = venueInfo.gl_limits.map((l) => l.limit);
    model.venueFacilityId = venueInfo.facilityId;
    model.venueId = venueInfo.id;
  }

  if (quote) {
    model.quoteBasicCoverageAmount = quote.basicCoverageAmount;
    model.quoteSurchargeAmount = quote.surchargeAmount;
    model.quoteSurcharges = setSurcharge(
      quote.surcharges,
      coverageApplication.isFederalEntity,
      coverageApplication.isKentuckyEntity
    );
    model.isFederalEntity = coverageApplication.isFederalEntity;
    model.isKentuckyEntity = coverageApplication.isKentuckyEntity;
    model.quoteAdditionalCoverageAmount = quote.additionalCoverageAmount;
    model.quoteSubtotal = quote.subtotal;
    model.quoteTotal = quote.total;
    model.quoteHostLiquorAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "host-liquor"
    );
    model.quoteTerrorismAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "terrorism"
    );
    model.quoteDamageToRentedPropertyAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "damage-to-rented-property"
    );
    model.quoteLiquorLiabilityAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "liquor-liability"
    );
    model.quotePersonalPropertyAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "personal-property"
    );
  }

  model.policyNumberGll = coverageApplication.formattedPolicyNumber || "";
  // Host liquor policy number doesn't exist
  model.policyNumberHostLiquor =
    coverageApplication.formattedHostLiquorPolicyNumber || "";
  model.policyNumberPersonalProperty =
    coverageApplication.formattedPersonalPropertyPolicyNumber || "";
  model.policyNumberCancellation =
    coverageApplication.formattedCancellationPolicyNumber || "";

  model.additionalCoverageHostLiquor = hasAdditionalCoverage(
    additionalCoverages,
    "host-liquor"
  );
  model.additionalCoverageTerrorism = hasAdditionalCoverage(
    additionalCoverages,
    "terrorism"
  );
  model.additionalCoverageDamageToRentedProperty = hasAdditionalCoverage(
    additionalCoverages,
    "damage-to-rented-property"
  );
  model.additionalCoverageLiquorLiability = hasAdditionalCoverage(
    additionalCoverages,
    "liquor-liability"
  );

  model.additionalCoveragePersonalProperty = hasAdditionalCoverage(
    additionalCoverages,
    "personal-property"
  );

  model.damageToRentedPropertyCoverageAmount =
    getDamageToRentedPropertyCoverageAmount(
      coverageApplication.additional_coverages
    );

  model.policyHasUnacknowledgedChanges =
    getPolicyHasUnacknowledgedChanges(coverage);

  model.additionalInsured = coverage.additional_insureds.map((m) => {
    return {
      id: m.id,
      text: m.text ? m.text : "",
      type: m.type,
      companyName:
        m.address && m.address.companyName ? m.address.companyName : "",
      address1: m.address && m.address.address1 ? m.address.address1 : "",
      address2: m.address && m.address.address2 ? m.address.address2 : "",
      city: m.address && m.address.city ? m.address.city : "",
      state: m.address && m.address.state ? m.address.state : "",
      country: m.address && m.address.country ? m.address.country : "",
      zip: m.address && m.address.zip ? m.address.zip : "",
    };
  });

  model.coverageNotes = coverage.coverage_notes.map((m) => ({
    id: m.id,
    orderNumber: m.orderNumber,
    type: m.type,
    message: m.message,
    userName: m.user?.name || "",
    created: m.created_at,
  }));

  model.transactionId = monetaryTransaction.requestId;
  model.nameOnCard = monetaryTransaction.nameOnCard;
  model.policyDocumentFilename = `${
    renterAddress.companyName
      ? renterAddress.companyName
      : `${renterAddress.contactFirstName}-${renterAddress.contactLastName}`
  }-event-insurance-documents.zip`;

  const { cohostAddressId, cohost_address } = coverageApplication;
  model.cohostAddressId = cohostAddressId;
  model.cohostAddress = cohost_address;

  return model;
};

const getAdditionalInsuredInfo = (coverage, type) =>
  coverage.additionalInsured.reduce(
    (prev, curr) => (curr.type === type ? curr : prev),
    null
  );

const initialCoverageFormValues = () => cloneDeep(emptyCoverage);

export {
  convertServerCoverageToCoverageModel,
  emptyAdditionalInsured,
  emptyCoverageNotes,
  emptyCoverage,
  coveragePropType,
  contactsPropType,
  getAdditionalInsuredInfo,
  initialCoverageFormValues,
};
