import { RxPolicyProp } from "../Policy/RxPolicy";
import { NotesContainer } from "@jauntin/react-ui";
import { useNotePublisher } from "Features/Shared/Hooks/useNotePublisher";

const PolicyNotes = ({ rxPolicy }: { rxPolicy: RxPolicyProp }) => {
  const [state, actions, , actions$] = rxPolicy;

  const { id, coverageNotes } = state.entity.data;
  const { addNote } = state;

  const publish = useNotePublisher({ actions$, publish: actions.addNote.send });

  return (
    <NotesContainer
      publish={publish}
      entityId={id}
      notes={coverageNotes.map(({ id, message, type, userName, created }) => ({
        id,
        message,
        type,
        userName,
        created,
      }))}
      publishing={addNote.loading}
    />
  );
};

export default PolicyNotes;
