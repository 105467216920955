import { EditPolicyProp } from "../RxEditPolicy";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Field } from "@reactables/react-forms";
import { TextInput, Button, TextArea } from "@jauntin/react-ui";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";
import {
  NO_AI_FACILITY,
  NO_AI_VENUE,
  NO_AI_VENUE_AI,
} from "../../../../../constants";
import { stringHelpers } from "Helpers/FormattingHelpers";
import hasMeaningfulAddressInfo from "Helpers/hasMeaningfulAddressInfo";
import { Address } from "Features/Shared/Models/venue.model";
import { getLgSelectStyleFromControl } from "Helpers/ReactSelectStyle";

export const parseAddress = (address) =>
  address
    ? stringHelpers.address(
        address.address,
        address.address2,
        address.city,
        address.state,
        "US",
        address.zip
      )
    : undefined;

export const addressView = (address, view, noInfo, textDanger = false) => {
  if (hasMeaningfulAddressInfo(address)) {
    return (
      <>
        <div>{address.companyName}</div>
        <div>{view}</div>
      </>
    );
  }
  if (address?.companyName) {
    return <div>{address.companyName}</div>;
  }
  return (
    <div className={textDanger ? "text-danger" : "additionalInsured--noInfo"}>
      {textDanger && <i className="fal fa-exclamation-triangle mr-2" />}
      {noInfo}
    </div>
  );
};

const AdditionalInsuredFields = ({
  rxEditPolicy,
}: {
  rxEditPolicy: EditPolicyProp;
}) => {
  const usStates = useSelector((state: { app }) => state.app.states);
  const [{ editFacilityAI, editVenueAddressAI, editVenueAI, form }, actions] =
    rxEditPolicy;
  return (
    <>
      <div className="card mt-4">
        <div className="card-header bg-transparent">
          <strong>Additional Insured</strong>
        </div>
        <div className="card-body">
          <>
            {/* Facility */}
            <div className="row">
              <div className="col-3 h6">Facility</div>
              <div className="col-9">
                {editFacilityAI ? (
                  <>
                    <Field
                      component={TextInput}
                      name="additionalInsured.facility.address.companyName"
                      label="Facility Name"
                      ariaLabel="Facility Name"
                      placeholder="Facility Name"
                      inputClassName="form-control-lg mb-3"
                    />
                    <AddressFieldGroup
                      groupName="additionalInsured.facility.address"
                      usStates={usStates}
                      includeAddress2
                      stateIsClearable
                    />
                    <div className="form-row">
                      <Button
                        onClick={actions.form.resetFacilityAdditionalInsured}
                        text="Discard"
                        className="btn-outline-secondary px-4 mx-1 ml-auto"
                      />
                    </div>
                  </>
                ) : (
                  (() => {
                    const facilityAddress = form[
                      "additionalInsured.facility.address"
                    ]?.value as Address;
                    const facilityAddressView = parseAddress(facilityAddress);

                    return (
                      <>
                        {addressView(
                          facilityAddress,
                          facilityAddressView,
                          NO_AI_FACILITY
                        )}
                        <Row>
                          {(hasMeaningfulAddressInfo(facilityAddress) ||
                            facilityAddress?.companyName) && (
                            <Col sm="auto">
                              <Button
                                onClick={
                                  actions.form.clearFacilityAdditionalInsured
                                }
                                text="Clear"
                                className="btn-link btn--edit"
                              />
                            </Col>
                          )}
                          <Col sm="auto">
                            <Button
                              text="Edit"
                              className="btn-link btn--edit"
                              onClick={
                                actions.form.editFacilityAdditionalInsured
                              }
                            />
                          </Col>
                        </Row>
                      </>
                    );
                  })()
                )}
              </div>
            </div>
            <br />

            {/* Venue */}
            <div className="row">
              <div className="col-3 h6">Venue</div>
              <div className="col-9">
                {editVenueAddressAI ? (
                  <>
                    <Field
                      component={TextInput}
                      name="additionalInsured.venueAddress.address.companyName"
                      label="Venue Name"
                      ariaLabel="Venue Name"
                      placeholder="Venue Name"
                      inputClassName="form-control-lg mb-3"
                    />
                    <AddressFieldGroup
                      groupName="additionalInsured.venueAddress.address"
                      includeAddress2
                      usStates={usStates}
                      stateSelectStyles={getLgSelectStyleFromControl(
                        form["additionalInsured.venueAddress.address.state"]
                      )}
                    />
                    <div className="form-row ml-left">
                      <Button
                        onClick={() =>
                          actions.form.resetControl([
                            "additionalInsured",
                            "venueAddress",
                            "address",
                          ])
                        }
                        text="Discard"
                        className="btn-outline-secondary px-4 mx-1 ml-auto"
                      />
                    </div>
                  </>
                ) : (
                  (() => {
                    const venueAddress = form[
                      "additionalInsured.venueAddress.address"
                    ].value as Address;
                    const venueAddressView = parseAddress(venueAddress);
                    return (
                      <>
                        {addressView(
                          venueAddress,
                          venueAddressView,
                          NO_AI_VENUE,
                          true
                        )}
                        <Row>
                          {(hasMeaningfulAddressInfo(venueAddress) ||
                            venueAddress.companyName) && (
                            <Col sm="auto">
                              <Button
                                onClick={() =>
                                  actions.form.clearFieldsAdditionalInsured(
                                    "venueAddress"
                                  )
                                }
                                text="Clear"
                                className="btn-link btn--edit"
                              />
                            </Col>
                          )}
                          <Col sm="auto">
                            <Button
                              text="Edit"
                              className="btn-link btn--edit"
                              onClick={actions.editVenueAddressAI.toggleOn}
                            />
                          </Col>
                        </Row>
                      </>
                    );
                  })()
                )}
              </div>
            </div>
            <br />

            {/* Venue Additional Insured */}
            <div className="row">
              <div className="col-3 h6">Venue Additional Insured</div>
              <div className="col-9">
                {editVenueAI ? (
                  <>
                    <Field
                      component={TextInput}
                      name="additionalInsured.venue.address.companyName"
                      label="Venue Additional Insured Name"
                      ariaLabel="Venue Additional Insured Name"
                      placeholder="Venue Additional Insured Name"
                      inputClassName="form-control-lg mb-3"
                    />
                    <AddressFieldGroup
                      groupName="additionalInsured.venue.address"
                      usStates={usStates}
                      includeAddress2
                      stateIsClearable
                    />
                    <div className="form-row ml-auto">
                      <Button
                        onClick={() => {
                          actions.form.resetControl([
                            "additionalInsured",
                            "venue",
                            "address",
                          ]);
                        }}
                        text="Discard"
                        className="btn-outline-secondary px-4 mx-1 ml-auto"
                      />
                    </div>
                  </>
                ) : (
                  (() => {
                    const venueAddressAdditionalInsured = form[
                      "additionalInsured.venue.address"
                    ].value as Address;
                    const venueAddressAdditionalInsuredView = parseAddress(
                      venueAddressAdditionalInsured
                    );
                    return (
                      <>
                        {addressView(
                          venueAddressAdditionalInsured,
                          venueAddressAdditionalInsuredView,
                          NO_AI_VENUE_AI
                        )}
                        <Row>
                          {(hasMeaningfulAddressInfo(
                            venueAddressAdditionalInsured
                          ) ||
                            venueAddressAdditionalInsured.companyName) && (
                            <Col sm="auto">
                              <Button
                                onClick={() => {
                                  actions.form.clearFieldsAdditionalInsured(
                                    "venue"
                                  );
                                }}
                                text="Clear"
                                className="btn-link btn--edit"
                              />
                            </Col>
                          )}
                          <Col sm="auto">
                            <Button
                              text="Edit"
                              className="btn-link btn--edit"
                              onClick={actions.editVenueAI.toggleOn}
                            />
                          </Col>
                        </Row>
                      </>
                    );
                  })()
                )}
              </div>
            </div>
            <br />

            {/* Other Additional Insured */}
            <div className="row">
              <div className="col-3 h6">Other additional insured</div>
              <div className="col-9 mb-2">
                <Field
                  component={TextArea}
                  name="additionalInsured.text.text"
                />
                <div className="text-right policy--charCount">
                  {form.root.value.additionalInsured.text.text.length}
                  /200 characters
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default AdditionalInsuredFields;
