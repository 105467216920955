import { group, control, array } from "@reactables/forms";
import { Coverage } from "../Models/coverage.model";
import { CustomContact } from "../Models/policyContacts.model";

export const policyContacts = (
  {
    producerContacts,
    facilityContacts,
    venueContacts,
    customContacts,
  }: Coverage,
  edit: boolean
) =>
  group({
    validators: [edit ? "uniqueCustomContactEmails" : "emailSelected"],
    controls: {
      emailInsuranceContact: control([true]),
      emailProducerContacts: array({
        controls: producerContacts.map(() => control([true])),
      }),
      emailFacilityContacts: array({
        controls: facilityContacts.map(() => control([true])),
      }),
      emailVenueContacts: array({
        controls: venueContacts.map(() => control([true])),
      }),
      customContacts: array({
        controls: customContacts.map((contact) =>
          customContact({ ...contact, fullName: contact.fullName || "" })
        ),
      }),
    },
  });

export const customContact = ({ id, fullName, email }: CustomContact) =>
  group({
    controls: {
      sendEmail: control([true]),
      contactInfo: group({
        controls: {
          id: control([id]),
          fullName: control([fullName]),
          email: control([email, ["required", "email"]]),
        },
      }),
    },
  });
