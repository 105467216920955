import { Modal } from "react-bootstrap";
import { FormBuilders } from "@jauntin/reactables";
import { AsyncButton } from "@jauntin/react-ui";
import { useReactable } from "@reactables/react";
import { control, group } from "@reactables/react-forms";
import { CheckboxInput, Button } from "@jauntin/react-ui";
import { Field, Form } from "@reactables/react-forms";

const RxConfirmForm = () =>
  FormBuilders.build(
    group({
      controls: {
        confirmUpdate: control([false, "required"]),
      },
    })
  );

const ModalUpdate = ({
  text,
  show,
  action,
  closeAction,
  updating,
  discardMsg = "Back to Edit",
  actionBtnTxt = "Update",
  actionBtnClassName = "btn-primary",
  updatingText = "Updating...",
  confirmMsg = "Are you sure you want to make this update?",
}) => {
  const rxForm = useReactable(RxConfirmForm);
  const [formState] = rxForm;
  if (!formState) return;
  return (
    <Modal show={show} onHide={closeAction} size="lg">
      {/* @ts-ignore */}
      <Modal.Header className="w-100 border-0 pb-0" closeButton />
      <Modal.Body className="text-center px-4">
        <p>{text}</p>
        <br />
        <Form rxForm={rxForm}>
          <Field
            component={CheckboxInput}
            name="confirmUpdate"
            labelClassName="font-weight-bold pl-2"
            label={confirmMsg}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0 pb-4 mb-3">
        <Button
          className="btn-outline-secondary"
          text={discardMsg}
          onClick={closeAction}
        />
        <AsyncButton
          onClick={() => {
            action();
          }}
          disabled={!formState.root.valid || updating}
          spinning={updating}
          spinningText={updatingText}
          className={`btn ${actionBtnClassName}`}
        >
          {!updating && <>{actionBtnTxt}</>}
        </AsyncButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalUpdate;
