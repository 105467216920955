import { DEFAULT_PRODUCER_CODE } from "./constants";

export const normalizePhone = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};

export const normalizeZip = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length <= 5) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 9)}`;
};

export const normalizeEmail = (value) => value && value.toLowerCase();

export const normalizeNoteMaxCharacters = (value) => {
  const maxLength = 200;
  const maxLines = 4;

  if (!value) {
    return value;
  }

  let newValue = value.slice(0, maxLength);

  if (newValue.split("\n").length > maxLines) {
    newValue = newValue.split("\n").slice(0, maxLines).join("\n");
  }

  return newValue;
};

export const normalizeEventName = (value) => {
  const maxLength = 50;

  if (!value) {
    return value;
  }

  const newValue = value.slice(0, maxLength);

  return newValue;
};

export const normalizeVenueName = (value) => {
  const maxLength = 20;

  if (!value) {
    return value;
  }

  const newValue = value.slice(0, maxLength);

  return newValue;
};

export const normalizeFacilityCode = (value) => {
  if (!value) {
    return value;
  }

  const onlyAlphaNumeric = value.replace(/[^A-Za-z0-9]/g, "");

  return onlyAlphaNumeric.slice(0, 4).toUpperCase();
};

export const normalizeVenueCode = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  return onlyNums.slice(0, 5);
};

export const normalizeProducerCommissionId = (value) => {
  const length = 7;

  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  return onlyNums.slice(0, length);
};

export const normalizeCommissionRate = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  return onlyNums.slice(0, 2);
};

export const setCommissionRate = (producerCode) => (value) =>
  producerCode === DEFAULT_PRODUCER_CODE ? 0 : normalizeCommissionRate(value);

export const maxLength200 = (value) => {
  const maxLength = 200;

  if (!value) return value;

  const newValue = value.slice(0, maxLength);

  return newValue;
};

export const sortedArray = (array) => array.toSorted();
