import React from "react";
import {
  SelectList as DefaultSelectList,
  SelectListProps as DefaultSelectListProps,
} from "@jauntin/react-ui";
import customStyles from "Helpers/ReactSelectStyle";
import { ExtendedMeta, mapExtendedErrors, classname } from "@jauntin/utilities";
import { StylesConfig } from "react-select";

type SelectListProps = {
  className?;
  label?: string | React.ReactElement;
  labelClassName?: string;
  errorClassName?: string;
  customStyles?: (hasErrors: boolean) => StylesConfig;
  showErrors?: (meta: ExtendedMeta) => boolean;
} & DefaultSelectListProps;

const SelectList = (props: SelectListProps) => {
  const mappedMeta = mapExtendedErrors(props.meta);

  const hasError = props.showErrors
    ? props.showErrors(mappedMeta)
    : Boolean(mappedMeta.touched && mappedMeta.error);

  return (
    <div className={classname("form-group", props.className)}>
      {props.label && (
        <label
          htmlFor={props.input.name}
          className={`label ${hasError && "form-error__label"} ${
            props.labelClassName
          }`}
        >
          {props.label}
        </label>
      )}

      {hasError && (
        <div className={`form-error ${props.errorClassName}`}>
          {mappedMeta.error}
        </div>
      )}
      <DefaultSelectList {...props} customStyles={customStyles} />
    </div>
  );
};

export default SelectList;
