import { Action } from "@reactables/core";
import { ControlRef, FormReducers } from "@reactables/forms";
import {
  differentAddressFields,
  differentAddressFieldsNoPersonName,
} from "../Configs/address.config";

/**
 * @description given a form group ref - toggles same address flag
 * - add/removes relevant controls
 */
export const sameAddress =
  (groupRef: ControlRef, toggleKey: string, includePersonNameFields = true) =>
  (
    { updateValues, removeControl, addControl }: FormReducers,
    state,
    { payload }: Action<"yes" | "no">
  ) => {
    state = updateValues(state, {
      controlRef: groupRef.concat(toggleKey),
      value: payload,
    });

    const controlRef = groupRef.concat("differentAddressFields");

    if (payload === "yes") {
      state = removeControl(state, controlRef);
    } else {
      state = addControl(state, {
        controlRef,
        config: includePersonNameFields
          ? differentAddressFields()
          : differentAddressFieldsNoPersonName(),
      });
    }

    return state;
  };
