import { ExtendedMeta } from "@jauntin/utilities";
import { ControlModels, RxFormActions } from "@reactables/react-forms";
import { EditPolicyFormValue } from "Features/Policies/Models/editPolicyFormValue.model";
import { PlacesSearch } from "@jauntin/react-ui";
import { Field } from "@reactables/react-forms";
import AddressError from "Features/Shared/Components/AddressError";
import { SelectPlaceAddressSearchPayload } from "Features/Shared/Models/venue.model";

const SearchByAddress = ({
  rxForm: [formState, formActions],
  hasPriceChangeError,
}: {
  rxForm: [ControlModels.Form<EditPolicyFormValue>, RxFormActions];
  hasPriceChangeError: boolean;
}) => {
  const {
    venue: {
      venueSearchResults: { knownVenue },
    },
  } = formState.root.value;
  const selectedPlace = formState["venue.bySearch.selectedPlace"]
    ?.value as SelectPlaceAddressSearchPayload;

  const checkingSearch =
    formState["venue.bySearch"]?.valid &&
    knownVenue === null &&
    (!formState["venue.venueSearchResults.apiError"].value as boolean);

  const errors = formState["venue.bySearch.selectedPlace"]?.errors;
  const showPriceChangeDirtyError = ({ touched, error, dirty }: ExtendedMeta) =>
    (touched && Boolean(error)) || (hasPriceChangeError && dirty);

  return (
    <>
      <Field
        component={PlacesSearch}
        placeholder="Search for your venue by name"
        label={
          <>
            <div>Where will the event take place?</div>
          </>
        }
        name="venue.bySearch.inputText"
        readOnly={Boolean(selectedPlace)}
        hasInput
        showSearchIcon={true}
        onSelect={(
          placeId,
          address,
          addressComponents,
          utcOffset,
          placeName
        ) => {
          formActions.selectPlaceAddressSearch({
            placeId,
            address,
            addressComponents,
            utcOffset,
            placeName,
          });
        }}
        clearForm={() => {
          formActions.clearPlaceAddressSearch();
        }}
        showErrors={showPriceChangeDirtyError}
      />
      {selectedPlace?.address && (
        <>
          {errors?.validateUsState ? (
            <AddressError
              errorMessage="GatherGuard is not available in Hawaii, Puerto Rico or the Virgin Islands"
              address={selectedPlace.address}
            />
          ) : errors?.venueNameRequired ? (
            <AddressError
              errorMessage="A venue name is required"
              address={selectedPlace.address}
            />
          ) : errors?.venueAddressRequired ? (
            <AddressError
              errorMessage="A specific venue address is required"
              address={selectedPlace.address}
            />
          ) : (
            <></>
          )}
        </>
      )}
      {checkingSearch && <div>Checking search..</div>}
    </>
  );
};

export default SearchByAddress;
