import DollarsAndCents from "Helpers/DollarsAndCents";
import { currencyFromInt } from "Helpers/CurrencyHelper";
import { Coverage } from "Features/Policies/Models/coverage.model";

const AdditionalCoverages = ({ policy }: { policy: Coverage }) => (
  <div className="card mt-4">
    <div className="card-header bg-transparent">
      <strong>Additional Coverages</strong>
    </div>
    <div className="card-body">
      {policy.additionalCoverageHostLiquor &&
        !policy.additionalCoverageLiquorLiability && (
          <div className="d-flex justify-content-between">
            <div>Host Liquor (included)</div>
            <div>
              <strong>
                <DollarsAndCents
                  currency={currencyFromInt(policy.quoteHostLiquorAmount)}
                />
              </strong>
            </div>
          </div>
        )}
      {policy.additionalCoverageTerrorism && (
        <div className="d-flex justify-content-between">
          <div>Terrorism (included)</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(policy.quoteTerrorismAmount)}
              />
            </strong>
          </div>
        </div>
      )}
      {policy.additionalCoverageDamageToRentedProperty && (
        <div className="d-flex justify-content-between">
          <div>Damage to Rented Premises</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(
                  policy.quoteDamageToRentedPropertyAmount
                )}
              />
            </strong>
          </div>
        </div>
      )}
      {policy.additionalCoverageLiquorLiability && (
        <div className="d-flex justify-content-between">
          <div>Selling Liquor (liquor liability)</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(policy.quoteLiquorLiabilityAmount)}
              />
            </strong>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default AdditionalCoverages;
