import { PolicyContactsFormState } from "Features/Policies/Components/Policy/RxPolicyContactsForm";

export const getIsAllEmailsSelected = (state: PolicyContactsFormState) => {
  const {
    emailInsuranceContact,
    emailProducerContacts,
    emailFacilityContacts,
    emailVenueContacts,
    customContacts,
  } = state.root.value;

  return [
    emailInsuranceContact,
    ...emailProducerContacts,
    ...emailFacilityContacts,
    ...emailVenueContacts,
    ...customContacts.map(({ sendEmail }) => sendEmail),
  ].every((sendEmail) => sendEmail);
};
