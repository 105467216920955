import SearchPoliciesContainer from "../../../../Containers/SearchPoliciesContainer/SearchPoliciesContainer";
import { Switch, Route } from "react-router-dom";
import { getUrl, POLICIES_PAGE } from "../../../../Helpers/URLParser";
import Policy from "./Policy";

const Policies = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={getUrl(POLICIES_PAGE)}
          component={SearchPoliciesContainer}
        />
        <Route
          path={`${getUrl(POLICIES_PAGE)}/:id/:pageType`}
          component={Policy}
        />
      </Switch>
    </>
  );
};

export default Policies;
