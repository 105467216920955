import { Coverage } from "Features/Policies/Models/coverage.model";
import { currencyFromDecimal } from "Helpers/CurrencyHelper";
import { DOLLAR_ONLY_FORMAT } from "Helpers/CurrencyHelper";

const BasicCoverage = ({ policy }: { policy: Coverage }) => {
  return (
    <div className="card">
      <div className="card-header bg-transparent">
        <strong>Basic Coverage</strong>
      </div>
      <div className="card-body">
        <div className="form-row justify-content-between mb-4">
          <div className="col-auto my-auto bold-1">Event type</div>
          <div className="col-auto">{policy.eventTypeName}</div>
        </div>
        <div className="form-row justify-content-between">
          <div className="col-auto">General Liability Coverage</div>
          <div className="col-auto my-auto mr-auto policy__reference">
            {policy.policyNumberGll}
          </div>
          <div className="col-auto bold-1 my-auto">
            {currencyFromDecimal(policy.venueGll).format(DOLLAR_ONLY_FORMAT)}
          </div>
        </div>
        <div className="form-row">
          <div className="col-auto">Limit for each incident (Occurrence)</div>
        </div>
        <div className="form-row">
          <div className="col-auto mt-3">Includes:</div>
        </div>
        <div className="form-row">
          {!policy.quoteLiquorLiabilityAmount && (
            <div className="col-auto">Host Liquor</div>
          )}
        </div>
        <div className="form-row justify-content-between">
          <div className="col-auto">
            Damage to rented premises
            {policy.damageToRentedPropertyCoverageAmount === 1000000
              ? " (up to $1M)"
              : ` (up to ${currencyFromDecimal(
                  policy.damageToRentedPropertyCoverageAmount
                ).format(DOLLAR_ONLY_FORMAT)})`}
          </div>
          <div className="col-auto bold-1">
            {currencyFromDecimal(
              policy.damageToRentedPropertyCoverageAmount
            ).format(DOLLAR_ONLY_FORMAT)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicCoverage;
