import React from "react";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";
import { OverlayTriggerType } from "react-bootstrap/esm/OverlayTrigger";

const PopoverTrigger = ({
  trigger = "click",
  placement = "auto",
  variant = "link",
  styleClass = "text-dark p-1 d-inline popover-trigger",
  content = "",
  icon = <i className="fal fa-info-circle" />,
}) => (
  <OverlayTrigger
    trigger={trigger as OverlayTriggerType}
    placement={placement as Placement}
    overlay={<Popover id="pop-over">{content}</Popover>}
    rootClose
    delay={200}
  >
    <Button variant={variant} className={styleClass}>
      {icon}
    </Button>
  </OverlayTrigger>
);

export default PopoverTrigger;
