import { ControlModels, RxFormActions } from "@reactables/react-forms";
import { EditPolicyProp, getHasPriceChangeError } from "../RxEditPolicy";
import { Alert } from "react-bootstrap";
import { EditPolicyFormValue } from "Features/Policies/Models/editPolicyFormValue.model";
import { Field } from "@reactables/react-forms";
import RadioBtn from "./RadioBtn";
import { VenueSearchTypes } from "../Configs/venue.config";
import SearchByVenueCode from "./SearchByVenueCode";
import SearchByAddress from "./SearchByAddress";
import ManualVenueAddress from "./ManualVenueAddress";
import SpecialTaxFields from "./SpecialTaxFields";

const parseAddress = (data: {
  address1: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  companyName: string;
}) => {
  const addressKeys = ["address1", "city", "state", "zip", "country"];

  const addressComponents: { companyName?: string } = {};
  let address = "";
  addressKeys.forEach((key) => {
    addressComponents[key] = data[key];
    address += `${data[key]}, `;
  });
  addressComponents.companyName = data.companyName;
  address = address.slice(0, -2);

  return { address, addressComponents };
};

export const getVenueAddressFromForm = (
  form: ControlModels.Form<EditPolicyFormValue>
) => {
  const { venue } = form.root.value;
  const knownVenue = venue.venueSearchResults.knownVenue;
  if (knownVenue) {
    const { address1, companyName, city, state, zip, country } =
      knownVenue.venue;
    return {
      address1,
      companyName,
      city,
      state,
      zip,
      country,
    };
  }

  if (venue.bySearch?.selectedPlace?.addressComponents) {
    const { address1, companyName, city, state, zip, country } =
      venue.bySearch?.selectedPlace?.addressComponents;

    return { address1, companyName, city, state, zip, country };
  }

  if (venue.byManualAddress) {
    const {
      address: { address: address1, city, state, zip },
      venueName,
    } = venue.byManualAddress;

    return {
      address1,
      companyName: venueName,
      city,
      state,
      zip,
      country: "US",
    };
  }

  return {
    address1: "",
    companyName: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  };
};

const VenueFields = ({ rxEditPolicy }: { rxEditPolicy: EditPolicyProp }) => {
  const [{ form: formState }, { form: formActions }] = rxEditPolicy;

  const rxForm = [formState, formActions] as [
    ControlModels.Form<EditPolicyFormValue>,
    RxFormActions
  ];

  const hasPriceChangeError = getHasPriceChangeError(rxEditPolicy[0]);

  const {
    venue: {
      searchType,
      byManualAddress,
      venueSearchResults: { knownVenue },
    },
  } = formState.root.value;

  const isBlocked =
    formState["venue.venueSearchResults.knownVenue"].errors.blockedVenue;

  const apiError =
    formState["venue.venueSearchResults.apiError"].errors.notTrue;

  const showTaxFields =
    Boolean(formState["venue.venueSearchResults.taxFields"]) &&
    formState["venue.venueSearchResults.hasTaxLookup"]?.value;

  const venueValid = formState["venue"].valid;

  const parsedVenueAddress = parseAddress(getVenueAddressFromForm(formState));

  const isCheckingAddressSearch =
    formState["venue.bySearch"]?.valid &&
    knownVenue === null &&
    (!formState["venue.venueSearchResults.apiError"].value as boolean);

  return (
    <div className="card mt-4 venue-fields">
      <div className="card-header bg-transparent">
        <strong>Venue</strong>
      </div>
      <div className="card-body">
        <div className="form-group form-row">
          <Field
            name="venue.searchType"
            component={RadioBtn}
            text="Use a venue code"
            value={VenueSearchTypes.ByVenueCode}
            cols="4"
            onChange={() =>
              formActions.selectVenueSearchType(VenueSearchTypes.ByVenueCode)
            }
          />
          <Field
            name="venue.searchType"
            component={RadioBtn}
            text="Search for a venue"
            value={VenueSearchTypes.BySearch}
            cols="4"
            onChange={() =>
              formActions.selectVenueSearchType(VenueSearchTypes.BySearch)
            }
          />
          <Field
            name="venue.searchType"
            component={RadioBtn}
            text="Enter my own venue information"
            value={VenueSearchTypes.ByManualAddress}
            cols="4"
            onChange={() =>
              formActions.selectVenueSearchType(
                VenueSearchTypes.ByManualAddress
              )
            }
          />
        </div>

        <>
          {searchType === VenueSearchTypes.ByVenueCode && (
            <SearchByVenueCode
              rxForm={rxForm}
              hasPriceChangeError={hasPriceChangeError}
            />
          )}

          {searchType === VenueSearchTypes.BySearch && (
            <SearchByAddress
              rxForm={rxForm}
              hasPriceChangeError={hasPriceChangeError}
            />
          )}
          {searchType === VenueSearchTypes.ByManualAddress && (
            <ManualVenueAddress
              rxForm={rxForm}
              hasPriceChangeError={hasPriceChangeError}
            />
          )}
          {formState.venue.errors.kentuckyCityNotFound && (
            <Alert variant="danger" className="mt-3">
              <i className="far fa-exclamation-circle alert__icon" />
              <div>Kentucky city not found.</div>
            </Alert>
          )}

          {isBlocked && (
            <Alert variant="info" className="mt-3">
              <i className="far fa-exclamation-circle alert__icon" />
              <div className="alert__text">
                Unfortunately, we cannot provide a policy from our online
                special event insurance program for the location provided. For
                more information, please call{" "}
                <span className="text-nowrap">844-747-6240</span>.
              </div>
            </Alert>
          )}
          {apiError && (
            <Alert variant="danger" className="mt-3">
              <i className="far fa-exclamation-circle alert__icon" />
              <div className="alert__text">
                Something went wrong. Please try again.
              </div>
            </Alert>
          )}

          {showTaxFields && (
            <>
              <SpecialTaxFields rxForm={rxForm} />
            </>
          )}

          {venueValid &&
            parsedVenueAddress.addressComponents.companyName &&
            !byManualAddress &&
            !isCheckingAddressSearch &&
            !apiError && (
              <>
                <div className="font-weight-bold">
                  Please verify the following address before confirming the
                  venue
                </div>
                <div>
                  <i className="fal fa-check-circle text-primary" />
                  &nbsp;
                  <span className="font-italic">
                    {parsedVenueAddress.addressComponents.companyName},
                    {parsedVenueAddress.address}
                  </span>
                </div>
              </>
            )}
          <hr />
        </>
      </div>
    </div>
  );
};

export default VenueFields;
