import { ControlModels } from "@reactables/forms";
import { CustomContact } from "./policyContacts.model";
import { Coverage } from "./coverage.model";
import { PolicyEmailsPayload } from "./policyEmailsPayload.model";

export interface PolicyContactsForm {
  emailInsuranceContact: boolean;
  emailProducerContacts: boolean[];
  emailFacilityContacts: boolean[];
  emailVenueContacts: boolean[];
  customContacts: {
    sendEmail: boolean;
    contactInfo: CustomContact;
  }[];
}

export const getContactsAndEmailPayload = ({
  coverage,
  form,
}: {
  form: ControlModels.Form<PolicyContactsForm>;
  coverage: Coverage;
}) => {
  const {
    id,
    insuranceContactEmail,
    producerContacts,
    facilityContacts,
    venueContacts,
    customContacts: policyContacts,
  } = coverage;

  const {
    emailInsuranceContact,
    emailProducerContacts,
    emailFacilityContacts,
    emailVenueContacts,
    customContacts,
  } = form.root.value;

  const customContactsPayload = customContacts.map(({ contactInfo }) =>
    Object.entries(contactInfo).reduce((acc, [key, value]) => {
      if (key === "id" && value === null) return acc;

      return {
        ...acc,
        [key]: value,
      };
    }, {})
  ) as CustomContact[];

  const emailPayload: PolicyEmailsPayload = {
    id,
    mainRecipients: emailInsuranceContact ? [insuranceContactEmail] : [],
    bccRecipients: [
      ...producerContacts
        .filter((_, index) => emailProducerContacts[index])
        .map(({ email }) => email),
      ...facilityContacts
        .filter((_, index) => emailFacilityContacts[index])
        .map(({ email }) => email),
      ...venueContacts
        .filter((_, index) => emailVenueContacts[index])
        .map(({ email }) => email),
      ...policyContacts
        .filter((_, i) => customContacts[i]?.sendEmail)
        .map(({ email }) => email),
    ],
  };

  return {
    emails: emailPayload,
    customContacts: customContactsPayload,
  };
};
