import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import MainMenuContainer from "../MainMenu/MainMenuContainer";
import PoliciesContainer from "../../Features/Policies/Components/Policy/PoliciesContainer";
import ProducersContainer from "../Producers/ProducersContainer";
import LoadStatesContainer from "../LoadStates/LoadStatesContainer";
import FacilitiesContainer from "../Facilities/FacilitiesContainer";
import AllVenuesSearchContainer from "../AllVenues/AllVenuesSearchContainer";
import AllContactsSearchContainer from "../AllContacts/AllContactsSearchContainer";
import BlockedVenuesContainer from "../BlockedVenues/BlockedVenuesContainer";
import AddNewBlockedVenue from "../BlockedVenues/AddNewBlockedVenue";
import ReportsContainer from "../Reports/ReportsContainer";
import MailcatcherContainer from "../Mailcatcher/MailcatcherContainer";
import UsersContainer from "../AdminUsers/AdminUsersContainer";
import AddNewUserContainer from "../AdminUsers/AddNewUserContainer";
import AlertKeywordsContainer from "../AlertKeywords/AlertKeywordsContainer";
import SettingsContainer from "../Settings/SettingsContainer";
import {
  getUrl,
  POLICIES_PAGE,
  FACILITIES_PAGE,
  PRODUCERS_PAGE,
  BLOCKED_VENUES_PAGE,
  ADD_BLOCKED_VENUE_PAGE,
  VENUES_SEARCH_PAGE,
  REPORTS_PAGE,
  CONTACTS_PAGE,
  MAILCATCHER_PAGE,
  USERS_PAGE,
  ADD_USER_PAGE,
  ALERT_KEYWORDS_PAGE,
  SETTINGS_PAGE,
} from "../../Helpers/URLParser";
import GuardedRoute from "../../Components/GuardedRoute";
import {
  canManageUsers,
  canManageSettings,
} from "../../Helpers/StoreSelectors";
import ActivityListener from "./ActivityListener";

const GuardRedirectComponent = () => <Redirect to={getUrl(POLICIES_PAGE)} />;

const libraries = ["places"];

const Admin = ({ fullwidth, isLoggedIn, isNotProductionEnv }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    language: "en",
  });

  return (
    (isLoggedIn && isLoaded && (
      <>
        <ActivityListener />
        <div className="container-main">
          <LoadStatesContainer />
          <MainMenuContainer />
          <div
            className={
              fullwidth /* addNewProducer || addNewFacility || addNewBlockedVenue || addNewUser */
                ? "admin-body admin-body--fullWidth scroll-part"
                : "admin-body"
            }
          >
            <Switch>
              {process.env.REACT_APP_MAILCATCHER_LOCATION && (
                <Route
                  exact
                  path={getUrl(MAILCATCHER_PAGE)}
                  component={MailcatcherContainer}
                />
              )}
              <Route
                path={getUrl(POLICIES_PAGE)}
                component={PoliciesContainer}
              />
              <Route
                path={getUrl(FACILITIES_PAGE)}
                component={FacilitiesContainer}
              />
              <Route
                path={getUrl(VENUES_SEARCH_PAGE)}
                component={AllVenuesSearchContainer}
              />
              <Route
                path={getUrl(PRODUCERS_PAGE)}
                component={ProducersContainer}
              />
              <Route
                path={getUrl(CONTACTS_PAGE)}
                component={AllContactsSearchContainer}
              />
              <Route
                exact
                path={getUrl(BLOCKED_VENUES_PAGE)}
                component={BlockedVenuesContainer}
              />
              <Route
                path={getUrl(ADD_BLOCKED_VENUE_PAGE)}
                component={AddNewBlockedVenue}
              />
              <GuardedRoute
                exact
                path={getUrl(USERS_PAGE)}
                component={UsersContainer}
                guards={[canManageUsers]}
                redirectComponent={GuardRedirectComponent}
              />
              <GuardedRoute
                path={getUrl(ADD_USER_PAGE)}
                component={AddNewUserContainer}
                guards={[canManageUsers]}
                redirectComponent={GuardRedirectComponent}
              />
              <Route
                exact
                path={getUrl(REPORTS_PAGE)}
                component={ReportsContainer}
              />
              <Route
                exact
                path={getUrl(ALERT_KEYWORDS_PAGE)}
                component={AlertKeywordsContainer}
              />
              <GuardedRoute
                exact
                path={getUrl(SETTINGS_PAGE)}
                component={SettingsContainer}
                guards={[canManageSettings, isNotProductionEnv]}
                redirectComponent={GuardRedirectComponent}
              />
              {/* Redirect to policies page if route not */}
              <Route component={GuardRedirectComponent} />
            </Switch>
          </div>
        </div>
      </>
    )) || <></>
  );
};

Admin.propTypes = {
  fullwidth: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  isNotProductionEnv: PropTypes.func.isRequired,
};
Admin.defaultProps = {
  fullwidth: false,
  isLoggedIn: false,
};

const mapDispatchToProps = () => ({
  isNotProductionEnv: () => {
    return process.env.REACT_APP_ENV !== "production";
  },
});

export default connect(null, mapDispatchToProps)(Admin);
