import { Action, ActionMap } from "@reactables/core";
import { ControlModels } from "@reactables/forms";
import { CustomReducers } from "@reactables/forms";
import { EventFrequency } from "../Configs/event.config";
import { selectEventFrequency } from "./selectEventFrequency.reducer";
import { calculateEventDates } from "Features/Shared/Helpers/calculateEventDates";
import { DateRange } from "Features/Shared/Models/event.model";
import { dateHelpers } from "@jauntin/utilities";

export const getEventDatesFromForm = (
  form: ControlModels.BaseForm<unknown> | ControlModels.Form<unknown>,
  timezone: string
) => {
  switch (form["event.eventFrequencyField"].value) {
    case EventFrequency.Continuous:
      return calculateEventDates({
        eventDateRangeField: form[
          `event.${EventFrequency.Continuous}.eventDateRange`
        ].value as DateRange,
        timezone,
      });
    case EventFrequency.Weekly:
      return calculateEventDates({
        daysOfWeekField: form[`event.${EventFrequency.Weekly}.daysOfWeek`]
          .value as Day[],
        eventDateRangeField: form[
          `event.${EventFrequency.Weekly}.eventDateRange`
        ].value as DateRange,
        timezone,
      });
    case EventFrequency.Custom:
      return form[`event.${EventFrequency.Custom}.eventDates`]
        .value as string[];
    default:
      return [];
  }
};

export interface EventActions extends ActionMap {
  selectEventFrequency: (payload: EventFrequency) => void;
  selectContinuousEventDates: (payload: {
    startDate: string;
    endDate: string;
  }) => void;
  selectWeeklyEventDates: (payload: {
    startDate: string;
    endDate: string;
  }) => void;
  deleteDate: (payload: string) => void;
}

export const eventReducers = ({
  timezone,
}: {
  timezone: string;
}): CustomReducers<EventActions> => ({
  selectEventFrequency,
  selectContinuousEventDates: (
    formReducers,
    state,
    { payload }: Action<{ startDate: string; endDate: string }>
  ) => {
    const { updateValues } = formReducers;
    state = updateValues(state, {
      controlRef: ["event", EventFrequency.Continuous, "eventDateRange"],
      value: payload,
    });

    return state;
  },
  /**
   * @description Selects dates for weekly event and handles date change
   */
  selectWeeklyEventDates: (
    formReducers,
    state,
    { payload }: Action<{ startDate: string; endDate: string }>
  ) => {
    const { updateValues } = formReducers;
    state = updateValues(state, {
      controlRef: ["event", EventFrequency.Weekly, "eventDateRange"],
      value: payload,
    });

    return state;
  },
  /**
   * @description Delete's a picked date from custom or weekly date selection
   * - If current frequency is weekly, switch to Custom
   */
  deleteDate: (formReducers, state, { payload }: Action<string>) => {
    const { updateValues } = formReducers;
    const { form } = state;
    const eventDates = getEventDatesFromForm(form, timezone);

    if (form["event.weekly"]) {
      state = selectEventFrequency(formReducers, state, {
        payload: EventFrequency.Custom,
      });
    }

    state = updateValues(state, {
      controlRef: ["event", "custom", "eventDates"],
      value: eventDates
        .filter((date) => date !== payload)
        .map((date) =>
          dateHelpers.localDateToTimezonedDate(new Date(date), timezone)
        ),
    });

    return state;
  },
});
