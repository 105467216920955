import {
  SelectPlaceAddressSearchPayload,
  VenueSearchResponse,
} from "../Models/venue.model";
import { ValidatorFn, Validators } from "@reactables/forms";
import { validators } from "@jauntin/utilities";
import { addressTypeIsForAddress } from "./AddressTypes";

const { minValue } = validators;

// VENUES

export const venueCode: ValidatorFn = (value: string) => ({
  venueCode: value && !/^\d{3,5}$/i.test(value),
});

export const facilityCode: ValidatorFn = (value: string) => ({
  facilityCode: value && !/^[a-zA-Z0-9]{3,4}$/i.test(value),
});

export const facilityAndVenueCode: ValidatorFn = (code: {
  facilityCode: string;
  venueCode: string;
}) => ({
  facilityAndVenueCode:
    !facilityAndVenueCodeRequired(code).facilityAndVenueCodeRequired &&
    (!/^\d{3,5}$/i.test(code.venueCode) ||
      !/^[a-zA-Z0-9]{3,4}$/i.test(code.facilityCode)),
});

export const facilityAndVenueCodeRequired: ValidatorFn = (code: {
  facilityCode: string;
  venueCode: string;
}) => ({
  facilityAndVenueCodeRequired: !code.facilityCode && !code.venueCode,
});

export const venueNameRequired: ValidatorFn = (
  value: SelectPlaceAddressSearchPayload
) => {
  const noVenueName = !Boolean(value?.addressComponents?.companyName);

  const isPostalCode =
    value?.addressComponents?.addressType.includes("postal_code");

  return {
    venueNameRequired: noVenueName || isPostalCode,
  };
};

export const venueSelectedPlaceRequired: ValidatorFn = (
  value: SelectPlaceAddressSearchPayload
) => {
  return {
    venueSelectedPlaceRequired: !value,
  };
};

export const venueAddressRequired: ValidatorFn = (
  value: SelectPlaceAddressSearchPayload
) => {
  if (!value?.addressComponents) return { venueAddressRequired: false };

  const { address1, city, state, country, zip, addressType } =
    value.addressComponents;

  return {
    venueAddressRequired: !Boolean(
      // Evaluate address type if it is supplied
      (!addressType || addressTypeIsForAddress(addressType)) &&
        address1 &&
        city &&
        state &&
        country &&
        zip
    ),
  };
};

// EVENT INFORMATION

export const atLeastOneDayMustBePicked: ValidatorFn = (
  value: [boolean, boolean, boolean, boolean, boolean, boolean, boolean]
) => ({ atLeastOneDayMustBePicked: !value.some((day) => day) });

export const requiredDates: ValidatorFn = (value: {
  startDate: string;
  endDate: string;
}) => ({
  requiredDates: !Object.values(value).every((date) => Boolean(date)),
});

export const minValue1 = (value) => ({
  minValue1: Boolean(minValue(1)(value)),
});

export const arrayNotEmpty: ValidatorFn = (value: unknown[]) => ({
  arrayNotEmpty: Boolean(validators.arrayNotEmpty(value)),
});

export const blockedVenue: ValidatorFn = (
  value: VenueSearchResponse | null
) => ({
  blockedVenue: Boolean(value?.venue?.blockedAt),
});

export const required = Validators.required;

export const zipCode: ValidatorFn = (value: string) => {
  // 90210 or 90210-0120
  const valid = !value || (value && /^\d{5}(?:-\d{4})?$/i.test(value));
  return {
    zipCode: !valid,
  };
};
