import {
  ExtendedFormControl,
  ExtendedMeta,
  mapExtendedErrors,
} from "@jauntin/utilities";
import { Field } from "@reactables/react-forms";
import PopoverTrigger from "Features/Shared/Components/Popover";
import { ControlModels, RxFormActions } from "@reactables/react-forms";
import { EditPolicyFormValue } from "Features/Policies/Models/editPolicyFormValue.model";
import VenueCodeField from "./VenueCodeField";

const SearchByVenueCode = ({
  rxForm: [formState, formActions],
  hasPriceChangeError,
}: {
  rxForm: [ControlModels.Form<EditPolicyFormValue>, RxFormActions];
  hasPriceChangeError: boolean;
}) => {
  const venueNotFound =
    formState["venue.venueSearchResults.knownVenue"]?.value === false;

  const isCheckingVenueCode =
    formState["venue.byVenueCode"]?.valid &&
    formState["venue.venueSearchResults.hasTaxLookup"].value === null &&
    (!formState["venue.venueSearchResults.apiError"].value as boolean);

  const {
    venue: {
      byVenueCode: { venueCode, facilityCode },
    },
  } = formState.root.value;

  const venueCodeGroupControl = mapExtendedErrors(
    formState["venue.byVenueCode"]
  ) as ExtendedFormControl;

  const showError = (() => {
    const venueCodeGroupControl = formState["venue.byVenueCode"];

    if (!venueCodeGroupControl) return false;

    const showError =
      venueNotFound ||
      (venueCodeGroupControl?.touched && !venueCodeGroupControl?.valid);

    return showError;
  })();

  const showPriceChangeDirtyError = ({ touched, error, dirty }: ExtendedMeta) =>
    (touched && Boolean(error)) ||
    (hasPriceChangeError && dirty && formState.root.childrenValid);

  return (
    <>
      <div className="d-flex align-items-center">
        <span
          className={
            showError ? "label form-error__label my-auto" : "label my-auto"
          }
        >
          Venue code
        </span>
        <PopoverTrigger content="Some participating venues provide a short code to identify their location for the event holders who are renting their facility. The code can usually be found on their website, rental brochures or through the location manager who facilitates the rental contracts." />
      </div>
      {venueNotFound && (
        <p className="form-error">
          The venue location for that code could not be found
        </p>
      )}
      {showError &&
        venueCodeGroupControl.errors.facilityAndVenueCodeRequired && (
          <p className="form-error">Required</p>
        )}
      {showError && venueCodeGroupControl.errors.facilityAndVenueCode && (
        <p className="form-error">Invalid venue code</p>
      )}
      <div className="d-flex align-items-center form-group venue-code">
        <Field
          component={VenueCodeField}
          name="venue.byVenueCode.facilityCode"
          className={`form-control form-control-lg text-center form-control--mw-100  ${
            (showError || showPriceChangeDirtyError(venueCodeGroupControl)) &&
            "venue-code-form-error"
          }`}
          onChange={(e) => {
            formActions.venueCodeSearchChange({
              facilityCode: e.target.value,
              venueCode,
              changedField: ["venue", "byVenueCode", "facilityCode"],
              newValue: e.target.value,
            });
          }}
        />
        <span className="col-auto text-center">&mdash;</span>
        <Field
          component={VenueCodeField}
          name="venue.byVenueCode.venueCode"
          className={`form-control form-control-lg text-center form-control--mw-100 ${
            (showError || showPriceChangeDirtyError(venueCodeGroupControl)) &&
            "venue-code-form-error"
          }`}
          onChange={(e) => {
            formActions.venueCodeSearchChange({
              venueCode: e.target.value,
              facilityCode,
              changedField: ["venue", "byVenueCode", "venueCode"],
              newValue: e.target.value,
            });
          }}
        />
      </div>
      {isCheckingVenueCode && <div>Checking venue code..</div>}
    </>
  );
};

export default SearchByVenueCode;
