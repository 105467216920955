import { EditPolicyProp } from "../RxEditPolicy";
import { Field } from "@reactables/react-forms";
import {
  TextInput,
  DateRangePicker,
  CustomDatePicker,
} from "@jauntin/react-ui";
import { useCallback, useRef, useEffect } from "react";
import { DateRange } from "Features/Shared/Models/event.model";
import { EventFrequency } from "../Configs/event.config";
import { dateHelpers, ExtendedMeta } from "@jauntin/utilities";
import { getEventDatesFromForm } from "../FormReducers/event.reducer";
import EventFrequencyField from "./EventFrequencyField";
import DaysOfWeekField from "./DaysOfWeekField";
import DateList from "./DateList";
import GuestsField from "./GuestsField";
import {
  policyDisabledDatesForCustomPicker,
  policyDisabledDatesForRangePicker,
} from "Helpers/DateHelpers";
import { useSelector } from "react-redux";

const { pickedToBlockedDays } = dateHelpers;

const EventFields = ({ rxEditPolicy }: { rxEditPolicy: EditPolicyProp }) => {
  const eventRef = useRef(null);
  const [
    {
      form: formState,
      quoteValidation: { data },
    },
    { form: formActions },
  ] = rxEditPolicy;
  useEffect(() => {
    setTimeout(() => {
      eventRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, []);
  const eventDateRange =
    (formState["event.continuous.eventDateRange"]?.value as DateRange) ||
    (formState["event.weekly.eventDateRange"]?.value as DateRange);
  const blockedDays = (() => {
    if (
      formState["event.eventFrequencyField"].value === EventFrequency.Weekly
    ) {
      const pickedDays = formState["event.weekly.daysOfWeek"].value as Day[];
      return pickedToBlockedDays(pickedDays);
    } else {
      return [];
    }
  })();
  const timezone = useSelector(
    (state: { app: { timezone } }) => state.app.timezone
  );
  const eventDates = getEventDatesFromForm(formState, timezone);

  const eventTotalGuests =
    (formState["event.eventDailyGuests"].value as number) * eventDates.length;

  const showDateList =
    formState["event.eventFrequencyField"].value !==
      EventFrequency.Continuous && eventDates.length > 0;

  const eventFrequency = formState["event.eventFrequencyField"].value;

  const disabledDatesRangePicker = useCallback(
    (day) =>
      policyDisabledDatesForRangePicker({
        startDate: eventDateRange.startDate,
        blockedDays,
      })(day),
    [eventDateRange, blockedDays]
  );

  const disabledCustomDatePicker = useCallback(
    (day) => policyDisabledDatesForCustomPicker(eventDates)(day),
    [eventDates]
  );

  const showPriceChangeDirtyError = ({ touched, error, dirty }: ExtendedMeta) =>
    (touched && Boolean(error)) || (!data?.quoteSame && dirty);

  return (
    <div ref={eventRef} className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Event</strong>
      </div>
      <div className="card-body">
        <Field
          component={TextInput}
          name="event.eventName"
          label="Please add a name for your event"
          ariaLabel="Event name"
          placeholder="Event name"
          maxLength={50}
          wrapperClassName="mb-1"
          inputClassName="form-control-lg"
          lengthClassName="eventName--charCount"
        />
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xl-6">
              <Field
                component={EventFrequencyField}
                name="event.eventFrequencyField"
                onChange={(eventFrequency: EventFrequency) =>
                  formActions.selectEventFrequency(eventFrequency)
                }
              />
            </div>
            {eventFrequency === EventFrequency.Weekly && (
              <div className="col-12 col-xl-6">
                <DaysOfWeekField name="event.weekly.daysOfWeek" />
              </div>
            )}
          </div>
          <div className="mb-2">
            In your selection, include the days required for setup and take down
            for the event.{" "}
            <span className="font-weight-bold">
              If your event will extend past midnight, include the following
              day.
            </span>
          </div>
          {eventFrequency === EventFrequency.Continuous && (
            <>
              <Field
                component={DateRangePicker}
                name={`event.${EventFrequency.Continuous}.eventDateRange`}
                disabledDates={disabledDatesRangePicker}
                inputClassName="form-control-lg"
                errorMessages={{ requiredDates: "Required" }}
                onChange={formActions.selectContinuousEventDates}
                showErrors={showPriceChangeDirtyError}
              />
            </>
          )}
          {eventFrequency === EventFrequency.Weekly &&
            formState["event.weekly.daysOfWeek"].valid && (
              <>
                <Field
                  component={DateRangePicker}
                  name={`event.${EventFrequency.Weekly}.eventDateRange`}
                  disabledDates={disabledDatesRangePicker}
                  inputClassName="form-control-lg"
                  errorMessages={{ requiredDates: "Required" }}
                  onChange={formActions.selectWeeklyEventDates}
                  showErrors={showPriceChangeDirtyError}
                />
              </>
            )}
          {eventFrequency === EventFrequency.Custom && (
            <>
              <div className="d-inline-flex">
                <Field
                  component={CustomDatePicker}
                  name={`event.${EventFrequency.Custom}.eventDates`}
                  disabledDates={disabledCustomDatePicker}
                />
              </div>
            </>
          )}

          {showDateList && (
            <div className="mb-2">
              <DateList
                dates={eventDates}
                deleteDate={formActions.deleteDate}
              />
            </div>
          )}

          <Field
            component={GuestsField}
            name="event.eventDailyGuests"
            disabled={false}
            eventTotalGuests={eventTotalGuests}
            eventDates={eventDates}
            hasMaxAttendeesError={formState["event"].errors.maxAttendees}
            showErrors={showPriceChangeDirtyError}
          />
        </div>
      </div>
    </div>
  );
};

export default EventFields;
