import { group, control, array } from "@reactables/forms";
import { Coverage } from "Features/Policies/Models/coverage.model";

export enum EventFrequency {
  Continuous = "continuous",
  Weekly = "weekly",
  Custom = "custom",
}

export const eventDateRange = ({ eventDateRange }: Coverage) =>
  control([eventDateRange, "requiredDates"]);

export const daysOfWeekField = (coverage: Coverage) =>
  array({
    controls: new Array(7)
      .fill(null)
      .map((_, index) => control([Boolean(coverage.daysOfWeekField[index])])),
    validators: ["atLeastOneDayMustBePicked"],
  });

export const continuousDatesField = (coverage: Coverage) =>
  group({
    controls: {
      eventDateRange: eventDateRange(coverage),
    },
  });

export const weeklyDatesField = (coverage: Coverage) =>
  group({
    controls: {
      eventDateRange: eventDateRange(coverage),
      daysOfWeek: daysOfWeekField(coverage),
    },
  });

export const customDatesField = ({ eventDates }: Coverage) =>
  group({
    controls: {
      eventDates: control({
        initialValue: eventDates,
        validators: ["arrayNotEmpty"],
        normalizers: ["sortedArray"],
      }),
    },
  });

export const event = (coverage: Coverage) => {
  const { eventName, eventFrequencyField, eventDailyGuests } = coverage;

  return group({
    validators: ["maxAttendees"],
    controls: {
      eventName: control({
        initialValue: eventName,
        validators: ["required"],
        normalizers: ["normalizeEventName"],
      }),
      eventFrequencyField: control([eventFrequencyField, "required"]),
      [eventFrequencyField]: (() => {
        switch (eventFrequencyField) {
          case EventFrequency.Continuous:
            return continuousDatesField(coverage);
          case EventFrequency.Weekly:
            return weeklyDatesField(coverage);
          case EventFrequency.Custom:
            return customDatesField(coverage);
        }
      })(),
      eventDailyGuests: control([eventDailyGuests, ["required", "minValue1"]]),
    },
  });
};
