import { Action } from "@reactables/core";
import { EventFrequency } from "../Configs/event.config";
import {
  continuousDatesField,
  weeklyDatesField,
  customDatesField,
} from "../Configs/event.config";
import { emptyCoverage } from "Helpers/CoverageModel";
import { Coverage } from "Features/Policies/Models/coverage.model";

/**
 * @description Selects event frequency and add appropriate date fields
 */
export const selectEventFrequency = (
  { removeControl, updateValues, addControl },
  state,
  { payload }: Action<EventFrequency>
) => {
  const prevEventFrequency = state.form["event.eventFrequencyField"]
    .value as EventFrequency;

  // If no change return the state;
  if (payload === prevEventFrequency) return state;

  // Remove previous date fields
  state = removeControl(state, ["event", prevEventFrequency]);

  state = updateValues(state, {
    controlRef: ["event", "eventFrequencyField"],
    value: payload,
  });

  // Add date controls based on frequency
  switch (payload) {
    case EventFrequency.Continuous:
      state = addControl(state, {
        controlRef: ["event", payload],
        config: continuousDatesField(emptyCoverage as unknown as Coverage),
      });
      break;
    case EventFrequency.Weekly:
      state = addControl(state, {
        controlRef: ["event", payload],
        config: weeklyDatesField(emptyCoverage as unknown as Coverage),
      });
      break;
    case EventFrequency.Custom:
      state = addControl(state, {
        controlRef: ["event", payload],
        config: customDatesField(emptyCoverage as unknown as Coverage),
      });
      break;
    default:
  }

  return state;
};
