import { dateHelpers } from "@jauntin/utilities";
import { ofTypes } from "@reactables/core";
import { Alert } from "react-bootstrap";
import { RxToggle } from "@jauntin/reactables";
import { useReactable } from "@reactables/react";
import { getValueFromControlConfig } from "@reactables/forms";
import { Link } from "react-router-dom";
import { Button, AsyncButton, usePollDocuments } from "@jauntin/react-ui";
import { canViewReports as getCanViewReports } from "Helpers/StoreSelectors";
import { useSelector } from "react-redux";
import { RxPolicyProp } from "../Policy/RxPolicy";
import {
  downloadDocument,
  downloadTransactionsXml,
  getDocumentsStatus,
} from "Helpers/DocumentDownload";
import { canChange, canCancel } from "Helpers/PolicyChangeBusinessRules";
import download from "downloadjs";
import PopoverTrigger from "Components/SearchEllipsisPopover";
import { EDIT_POLICY_PAGE, getUrl } from "Helpers/URLParser";
import listToStringDays from "Helpers/ListToString";
import { currencyFromInt } from "Helpers/CurrencyHelper";
import { stringHelpers } from "Helpers/FormattingHelpers";
import { getAttendanceRange } from "Helpers/AttendenceRangeHelper";
import {
  FOOD_VENDOR,
  GOODS_VENDOR,
  PERFORMER,
  EXHIBITOR,
  NO_AI_FACILITY,
  NO_AI_VENUE,
  NO_AI_VENUE_AI,
  NO_AI_OTHER,
  TYPE_TEXT,
} from "../../../../constants";
import DollarsAndCents from "Helpers/DollarsAndCents";
import ButtonCollapse from "Components/ButtonCollapse";
import DateList from "Containers/Policy/DateList";
import {
  parseAddress,
  addressView,
} from "../EditPolicy/AdditionalInsuredFields/AdditionalInsuredFields";
import {
  facility,
  venueAddress as venueAddressConfig,
  venue,
  text,
} from "../EditPolicy/Configs/additionalInsured.config";
import { AdditionalInsured } from "Features/Policies/Models/editPolicyFormValue.model";
import BasicCoverage from "./BasicCoverage";
import AdditionalCoverages from "./AdditionalCoverages";
import Payment from "./Payment";
import ModalUpdate from "Features/Shared/Components/ModalUpdate";
import { ContactType } from "../EditPolicy/Configs/contactTypes";

const duration = (eventDates, timezone) => {
  const days = eventDates.length;
  let firstDay;
  let lastDay;
  if (days) {
    firstDay = new Date(eventDates[0]);
    lastDay = new Date(eventDates[eventDates.length - 1]);
  } else {
    firstDay = new Date();
    lastDay = new Date();
  }
  if (days === 1) {
    return `1 day long - ${dateHelpers.lettersAndNumbers(firstDay, timezone)}`;
  }
  return `${days} days long - ${dateHelpers.lettersAndNumbers(
    firstDay,
    timezone
  )} to ${dateHelpers.lettersAndNumbers(lastDay, timezone)}`;
};

const providerText = (type, count, frequency, list) => {
  let provider = type;
  const plural = count > 1 ? "s" : "";

  if (type === PERFORMER) {
    provider = `performer${plural}`;
  }
  if (type === GOODS_VENDOR) {
    provider = `vendor${plural} selling goods`;
  }
  if (type === FOOD_VENDOR) {
    provider = `vendor${plural} selling food or beverages`;
  }
  if (type === EXHIBITOR) {
    provider = `exhibitor${plural}`;
  }

  // Check if count is 7:
  if (count === 7) {
    return `There will be 7 or more ${provider}.`;
  }

  // Check if 'everyday' is selected
  if (frequency === "everyday") {
    return `There will be ${count} ${provider} at the event everyday.`;
  }

  // Show detailed provider list
  return `There will be ${count} ${provider} at the event: ${listToStringDays(
    list
  )}.`;
};

const PopoverContent = ({ coverage, downloadTransactions }) => (
  <>
    <div className="mb-2">
      <Button
        className="popover-item__btn"
        onClick={() => {
          downloadTransactions(
            coverage,
            `${coverage.policyNumberGll}_${process.env.REACT_APP_ENV}_transactions.zip`
          );
        }}
        text={
          <>
            <i className="fal fa-code mr-2 col-1 p-0 "></i>
            <span className="col-auto p-0">Download Transactions XML</span>
          </>
        }
      />
    </div>
  </>
);

const CANCEL_SUCCESS_ACTION = "Policy - cancelPolicy - sendSuccess";
const RxCancelModalToggle = ({ sources }) =>
  RxToggle(false, {
    sources,
    reducers: {
      [CANCEL_SUCCESS_ACTION]: () => false,
    },
  });

const PolicyView = ({ rxPolicy }: { rxPolicy: RxPolicyProp }) => {
  const canViewReports = useSelector(getCanViewReports);
  const timezone = useSelector(
    ({ app }: { app: { timezone: string } }) => app.timezone
  );

  const [
    {
      entity: { data: policy },
      cancelPolicy,
    },
    policyActions,
    ,
    policyActions$,
  ] = rxPolicy;

  const { downloading, startDownload } = usePollDocuments({
    getDocumentsStatus: () => getDocumentsStatus(policy.latestOrderNumber),
    getDocuments: () => downloadDocument(policy),
    downloadFn: download,
    fileDownloadName: policy.policyDocumentFilename,
  });

  const [expandDateList, { toggle: toggleExpandDateList }] =
    useReactable(RxToggle);

  const [showCancelModal, cancelModalActions] = useReactable(
    RxCancelModalToggle,
    {
      sources: [policyActions$.pipe(ofTypes([CANCEL_SUCCESS_ACTION]))],
    }
  );

  return (
    <>
      <div className="content__header col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Policy</h4>
          <div className="d-flex float-right">
            <>
              <div className="policy__document text-right mx-4">
                <AsyncButton
                  spinning={downloading}
                  disabled={downloading}
                  className="btn btn-link d-flex policy__download"
                  onClick={async () => {
                    setTimeout(startDownload, 2000);
                  }}
                >
                  Download Latest Policy Document
                </AsyncButton>
                <div className="policy__updated">
                  <em>
                    {`Updated on ${dateHelpers.lettersAndNumbers(
                      policy.updated,
                      timezone
                    )} at ${dateHelpers.simpleTime(policy.updated, timezone)}`}
                  </em>
                </div>
              </div>
              {canViewReports && process.env.REACT_APP_ENV !== "production" && (
                <div className="mr-4">
                  <PopoverTrigger
                    placement="left"
                    styleClass="d-inline-block"
                    content={
                      <PopoverContent
                        coverage={policy}
                        downloadTransactions={downloadTransactionsXml}
                      />
                    }
                  />
                </div>
              )}
            </>
            {canChange(policy) && (
              <Link to={getUrl(EDIT_POLICY_PAGE, policy.id)}>
                <Button
                  onClick={() => {}}
                  text="Edit Policy"
                  className="btn-primary px-4"
                />
              </Link>
            )}
          </div>
        </div>
        {cancelPolicy.success && cancelPolicy.data?.refunded === false && (
          <Alert variant="danger" className="mx-auto mb-0 mt-3">
            <div className="form-row">
              <div className="col-auto">
                <i className="fal fa-info-circle icon--large" />
              </div>
              <div className="col">Manual refund required.</div>
            </div>
          </Alert>
        )}
      </div>
      <div className="content__body">
        {/* BASIC COVERAGE */}
        <BasicCoverage policy={policy} />

        {/* EVENT */}
        <div className="card mt-4">
          <div className="card-header bg-transparent">
            <strong>Event</strong>
          </div>
          <div className="card-body">
            <div className="form-row justify-content-between">
              <div className="col-auto">
                {stringHelpers.renterName(policy)}
                {`'s`}
                {` ${policy.eventTypeName}`}
              </div>
              <div className="col-auto">
                <strong>
                  <DollarsAndCents
                    currency={currencyFromInt(policy.quoteBasicCoverageAmount)}
                  />
                </strong>
              </div>
            </div>
            <div className="form-row">
              <div className="col-auto">{policy.eventName}</div>
            </div>
            {policy.isOneTime ? (
              <div className="form-row">
                <div className="col-auto">
                  {duration(policy.eventDates, timezone)}
                </div>
              </div>
            ) : (
              <>
                <div className="form-row">
                  <div className="col-auto">
                    {`Starts on ${dateHelpers.lettersAndNumbers(
                      policy.eventDateRange.startDate,
                      timezone
                    )} `}
                  </div>
                  <div className="col-auto">
                    {`Ends on ${dateHelpers.lettersAndNumbers(
                      policy.eventDateRange.endDate,
                      timezone
                    )}`}
                  </div>
                </div>
                <div className="form-row mb-1">
                  <ButtonCollapse
                    clickFunction={toggleExpandDateList}
                    showList={Boolean(expandDateList)}
                    title={`${policy.eventDates.length} date(s) selected`}
                  />

                  {expandDateList && <DateList dates={policy.eventDates} />}
                </div>
              </>
            )}
            {policy.eventDailyGuests ? (
              <>
                <div className="form-row">
                  <div className="col-auto">
                    <span className="font-weight-bold">
                      Daily Average Attendance
                    </span>
                    :{" "}
                    <span className="ml-2 mr-3">{policy.eventDailyGuests}</span>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-auto">
                    <span className="font-weight-bold">Total Attendance</span>:{" "}
                    <span className="ml-2 mr-3">
                      {policy.eventDailyGuests * policy.eventDates.length}
                    </span>
                    <span className="font-weight-bold">Attendance Range</span>:{" "}
                    <span className="ml-2 mr-3">
                      {getAttendanceRange(
                        policy.eventDailyGuests * policy.eventDates.length
                      )}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              // Legacy policies do not have averageDailyAttendance so use groupSize
              <div className="form-row">
                <div className="col-auto">
                  <span className="font-weight-bold">Attendance Range</span>:{" "}
                  <span className="ml-2 mr-3">
                    {getAttendanceRange(policy.groupSize)}
                  </span>
                </div>
              </div>
            )}
            <div className="form-row">
              <div className="col-auto">
                <div>
                  {policy.eventPerformers > 0 && (
                    <div>
                      {providerText(
                        PERFORMER,
                        policy.eventPerformers,
                        policy.performersFrequency,
                        policy.performersList
                      )}
                    </div>
                  )}
                  {policy.eventGoods > 0 && (
                    <div>
                      {providerText(
                        GOODS_VENDOR,
                        policy.eventGoods,
                        policy.goodsVendorsFrequency,
                        policy.goodsVendorsList
                      )}
                    </div>
                  )}
                  {policy.eventFood > 0 && (
                    <div>
                      {providerText(
                        FOOD_VENDOR,
                        policy.eventFood,
                        policy.foodVendorsFrequency,
                        policy.foodVendorslist
                      )}
                    </div>
                  )}
                  {policy.eventExhibitors > 0 && (
                    <div>
                      {providerText(
                        EXHIBITOR,
                        policy.eventExhibitors,
                        policy.exhibitorsFrequency,
                        policy.exhibitorsList
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ADDITIONAL COVERAGES */}
        <AdditionalCoverages policy={policy} />

        {/* INSURED */}
        <div className="card mt-4">
          <div className="card-header bg-transparent">
            <strong>Insured</strong>
          </div>
          <div className="card-body">
            <p>{stringHelpers.renterName(policy)}</p>
            <p>
              {policy.renterAddress1}
              {policy.renterAddress2 && (
                <>
                  <br />
                  {policy.renterAddress2}
                </>
              )}
            </p>
            <p>{`${policy.renterCity}, ${policy.renterState}, ${policy.renterZip}, ${policy.renterCountry}`}</p>
          </div>
        </div>

        {/* CO-HOST */}
        {policy.cohostAddress && (
          <div className="card mt-4">
            <div className="card-header bg-transparent">
              <strong>Co-host</strong>
            </div>
            <div className="card-body">
              <p>
                {policy.cohostAddress.contactFirstName}{" "}
                {policy.cohostAddress.contactLastName}
              </p>
              <p>{policy.cohostAddress.address1}</p>
              <p>{`${policy.cohostAddress.city}, ${policy.cohostAddress.state}, ${policy.cohostAddress.zip}, ${policy.cohostAddress.country}`}</p>
            </div>
          </div>
        )}

        {/* INSURANCE CONTACT */}
        <div className="card mt-4">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Insurance Contact</strong>
            </div>
          </div>
          <div className="card-body">
            <>
              <p>
                {policy.insuranceContactFirstName}{" "}
                {policy.insuranceContactLastName}
              </p>
              {policy.contactSame !== "yes" &&
                policy.insuranceContactType !== ContactType.Person && (
                  <p>{policy.insuranceContactCompanyName}</p>
                )}
              {policy.contactSame !== "yes" && (
                <p>
                  {stringHelpers.address(
                    policy.insuranceContactAddress1,
                    policy.insuranceContactAddress2,
                    policy.insuranceContactCity,
                    policy.insuranceContactState,
                    policy.insuranceContactCountry,
                    policy.insuranceContactZip
                  )}
                </p>
              )}
              <p>{`${policy.insuranceContactPhone} - ${policy.insuranceContactEmail}`}</p>
            </>
          </div>
        </div>

        {/* VENUE */}
        <div className="card mt-4">
          <div className="card-header bg-transparent">
            <strong>Venue</strong>
          </div>
          <div className="card-body">
            <p>
              {policy.facilityCode &&
                policy.venueCode &&
                `${policy.facilityCode}-${policy.venueCode}`}
            </p>
            <p>
              {policy.manualVenueCompanyName ||
                policy.venueName ||
                policy.venueCompanyName}
            </p>
            <p>
              {stringHelpers.address(
                policy.manualVenueAddress || policy.venueAddress1,
                "",
                policy.manualVenueCity || policy.venueCity,
                policy.manualVenueState || policy.venueState,
                policy.manualVenueCountry || policy.venueCountry,
                policy.manualVenueZip || policy.venueZip
              )}
            </p>
          </div>
        </div>

        {/* ADDITIONAL INSURED */}
        {(() => {
          const aiMap = policy.additionalInsured.reduce(
            (acc, ai) => ({ ...acc, [ai.type]: ai }),
            {}
          );
          const formatAdditionalInsuredText = (obj) => {
            if (TYPE_TEXT in obj) {
              return obj.text.split("\n").map((line, i) => {
                return <div key={i.toString()}>{line}</div>;
              });
            }
            return "";
          };

          const facilityAddress = (
            getValueFromControlConfig(
              facility(aiMap["facility"])
            ) as AdditionalInsured
          ).address;
          const facilityAddressView = parseAddress(facilityAddress);

          const venueAddress = (
            getValueFromControlConfig(
              venueAddressConfig(aiMap["venue_address"])
            ) as AdditionalInsured
          ).address;
          const venueAddressView = parseAddress(venueAddress);

          const venueAddressAdditionalInsured = (
            getValueFromControlConfig(
              venue(aiMap["venue"])
            ) as AdditionalInsured
          ).address;
          const venueAddressAdditionalInsuredView = parseAddress(
            venueAddressAdditionalInsured
          );
          const otherAdditionalInsured = getValueFromControlConfig(
            text(aiMap["text"])
          ) as { text: string };

          return (
            <>
              <div className="card mt-4">
                <div className="card-header bg-transparent">
                  <strong>Additional Insured</strong>
                </div>
                <div className="card-body">
                  <>
                    <div>
                      <strong>Facility</strong>
                    </div>
                    <div>
                      {addressView(
                        facilityAddress,
                        facilityAddressView,
                        NO_AI_FACILITY
                      )}
                    </div>
                    <br />

                    <div>
                      <strong>Venue</strong>
                    </div>
                    <div>
                      {addressView(venueAddress, venueAddressView, NO_AI_VENUE)}
                    </div>
                    <br />

                    <div>
                      <strong>Venue Additional Insured</strong>
                    </div>
                    <div>
                      {addressView(
                        venueAddressAdditionalInsured,
                        venueAddressAdditionalInsuredView,
                        NO_AI_VENUE_AI
                      )}
                    </div>
                    <br />

                    <div>
                      <strong>Other additional insured</strong>
                    </div>
                    <div>
                      {otherAdditionalInsured.text ? (
                        formatAdditionalInsuredText(otherAdditionalInsured)
                      ) : (
                        <span className="additionalInsured--noInfo">
                          {NO_AI_OTHER}
                        </span>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </>
          );
        })()}

        {/* PAYMENT */}
        <Payment policy={policy} />

        <div className="form-group text-right py-5">
          {canCancel(policy) && (
            <Button
              className="btn-outline-secondary"
              type="button"
              text="Cancel Policy"
              onClick={cancelModalActions.toggleOn}
            />
          )}
        </div>
      </div>
      {showCancelModal && (
        <ModalUpdate
          show={showCancelModal}
          actionBtnTxt="Confirm Cancellation"
          text={`Cancel coverage for "${
            policy.eventName
          }" ${dateHelpers.slashSeparated(
            policy.eventDateRange.startDate,
            timezone
          )} - ${dateHelpers.slashSeparated(
            policy.eventDateRange.endDate,
            timezone
          )}? This action will refund the full premium and automatically notify the insured and venue of the cancellation.`}
          action={policyActions.cancelPolicy.send}
          updating={cancelPolicy.loading}
          updatingText="Cancelling..."
          confirmMsg="Yes, I confirm that I would like to cancel this policy."
          discardMsg="Keep (Coverage)"
          actionBtnClassName="btn-danger"
          closeAction={cancelModalActions.toggleOff}
        />
      )}
    </>
  );
};

export default PolicyView;
