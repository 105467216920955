import { group, control } from "@reactables/forms";
import { CompanyTypes, ContactType, JointVentureTypes } from "./contactTypes";
import { address } from "./address.config";
import { Coverage } from "Features/Policies/Models/coverage.model";
import { emptyCoverage } from "Helpers/CoverageModel";

export const personName = (
  {
    renterFirstName,
    renterLastName,
  }: Coverage = emptyCoverage as unknown as Coverage
) =>
  group({
    controls: {
      firstName: control([renterFirstName, "required"]),
      lastName: control([renterLastName, "required"]),
    },
  });

export const insured = (coverage: Coverage) => {
  const { renterContactType } = coverage;
  const renterTypeControl =
    renterContactType === ContactType.Person
      ? {
          personName: personName(coverage),
        }
      : { companyDetails: companyDetails(coverage) };
  return group({
    controls: {
      type: control([
        renterContactType === ContactType.Person
          ? ContactType.Person
          : ContactType.Company,
      ]),
      ...renterTypeControl,
      address: address({
        address1: coverage.renterAddress1,
        city: coverage.renterCity,
        state: coverage.renterState,
        zip: coverage.renterZip,
        country: "US",
      }),
    },
  });
};

export const companyDetails = (
  coverage: Coverage = emptyCoverage as unknown as Coverage
) => {
  const { renterCompanyName, renterContactType } = coverage;
  const isJointVenture =
    renterContactType === JointVentureTypes.JointVentureLLC ||
    renterContactType === JointVentureTypes.JointVentureCorporation;

  return group({
    controls: {
      name: control([renterCompanyName, "required"]),
      type: control([
        isJointVenture
          ? CompanyTypes.JointVenture
          : renterContactType || CompanyTypes.Individual,
        "required",
      ]),
      ...(isJointVenture
        ? { jointVentureType: jointVentureType(coverage) }
        : undefined),
    },
  });
};

export const jointVentureType = (
  { renterContactType }: Coverage = emptyCoverage as unknown as Coverage
) =>
  control([
    renterContactType || JointVentureTypes.JointVentureCorporation,
    "required",
  ]);
