import { useSelector } from "react-redux";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";
import { Field } from "@reactables/react-forms";
import { Button, TextInput } from "@jauntin/react-ui";
import { EditPolicyProp } from "../RxEditPolicy";
import { RxPolicyProp } from "../../Policy/RxPolicy";
import { getLgSelectStyleFromControl } from "Helpers/ReactSelectStyle";

const CohostFields = ({
  rxEditPolicy: [{ form }, actions],
  rxPolicy: [
    {
      entity: { data: policy },
    },
  ],
}: {
  rxEditPolicy: EditPolicyProp;
  rxPolicy: RxPolicyProp;
}) => {
  const usStates = useSelector(
    (appState: { app: { states } }) => appState.app.states
  );

  const { cohost } = form.root.value;

  const hasExistingCohost = Boolean(policy.cohostAddress);

  return (
    <>
      {cohost ? (
        <div className="card mt-4">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <strong>Co-host</strong>
            {!hasExistingCohost && (
              <button
                className="btn-link--delete btn btn-link"
                onClick={actions.form.removeCohost}
              >
                Cancel
              </button>
            )}
          </div>
          <div className="card-body">
            <div id="contactIndividual" className="form-row">
              <Field
                component={TextInput}
                name="cohost.contactFirstName"
                label="First Name"
                ariaLabel="First Name"
                placeholder="First Name"
                wrapperClassName="col-sm"
                inputClassName="form-control-lg"
              />
              <Field
                component={TextInput}
                name="cohost.contactLastName"
                label="Last Name"
                ariaLabel="Last Name"
                placeholder="Last Name"
                wrapperClassName="col-sm"
                inputClassName="form-control-lg"
              />
            </div>
            <AddressFieldGroup
              groupName={"cohost"}
              usStates={usStates}
              stateSelectStyles={getLgSelectStyleFromControl(
                form["cohost.state"]
              )}
            />
          </div>
        </div>
      ) : (
        <Button
          className="btn-primary mt-3 w-100"
          onClick={actions.form.addCohost}
          text="Add a Co-host"
        />
      )}
    </>
  );
};

export default CohostFields;
