export const emptyCustomContact: CustomContact = {
  id: null,
  fullName: "",
  email: "",
};

export interface CustomContact {
  id?: number;
  fullName: string;
  email: string;
}
