import { useReactable } from "@reactables/react";
import { Form, Field, FormArray, ControlModels } from "@reactables/react-forms";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { TextInput } from "@jauntin/react-ui";
import { Button } from "@jauntin/react-ui";
import { RxPolicyProp } from "./RxPolicy";
import {
  CustomContact,
  emptyCustomContact,
} from "Features/Policies/Models/policyContacts.model";
import LoadingSpinner from "Components/LoadingSpinner";
import { RxPolicyContactsForm } from "./RxPolicyContactsForm";
import { stringHelpers } from "Helpers/FormattingHelpers";
import PolicyUpdateAlert from "./PolicyUpdateAlert";
import { RxToggle } from "@jauntin/reactables";
import ModalDiscardAddNew from "Components/ModalDiscardAddNew";
import { getUrl, POLICY_CONTACT_PAGE } from "Helpers/URLParser";
import { useHistory } from "react-router-dom";
import { MAX_CUSTOM_CONTACTS } from "../../../../constants";
import { customContact } from "Features/Policies/Configs/policyContacts.config";
import CustomContactEmailField from "./CustomContactEmailField";

const EditPolicyContacts = ({ rxPolicy }: { rxPolicy: RxPolicyProp }) => {
  const [
    {
      entity: { data: coverage },
      customContacts,
    },
    rxPolicyActions,
  ] = rxPolicy;

  const [state, actions] = useReactable(RxPolicyContactsForm, {
    coverage,
    sources: [],
    edit: true,
  });

  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  const history = useHistory();

  if (!state) return;

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Edit Contacts</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={discardModalToggle.toggleOn}
            />
            <Button
              text="Save Changes"
              disabled={
                !state.root.valid || !state.root.dirty || customContacts.loading
              }
              className="btn btn-primary ml-auto border-radius-0"
              onClick={() =>
                rxPolicyActions.customContacts.send({
                  form: state,
                  coverage,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="content__body">
        {coverage.policyHasUnacknowledgedChanges && (
          <PolicyUpdateAlert
            close={() =>
              rxPolicyActions.acknowledgePolicyUpdated.send(coverage)
            }
          />
        )}
        <Form rxForm={[state, actions]}>
          <Row>
            <Col>
              <Card className="mb-4">
                <Card.Header className="bg-white font-weight-bold">
                  Insurance Contact
                </Card.Header>
                <Card.Body>
                  <>
                    <p>
                      {coverage.insuranceContactFirstName}{" "}
                      {coverage.insuranceContactLastName}
                    </p>
                    {coverage.contactSame !== "yes" &&
                      coverage.insuranceContactType !== "Person" && (
                        <p>{coverage.insuranceContactCompanyName}</p>
                      )}
                    {coverage.contactSame !== "yes" && (
                      <p>
                        {stringHelpers.address(
                          coverage.insuranceContactAddress1,
                          coverage.insuranceContactAddress2,
                          coverage.insuranceContactCity,
                          coverage.insuranceContactState,
                          coverage.insuranceContactCountry,
                          coverage.insuranceContactZip
                        )}
                      </p>
                    )}
                    <p>{`${coverage.insuranceContactPhone} - ${coverage.insuranceContactEmail}`}</p>
                  </>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <FormArray name="emailProducerContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email, producerName, role } =
                    coverage.producerContacts[index];
                  return (
                    <Row key={key}>
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Producer Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            {fullName && <div>{fullName}</div>}
                            {producerName && <div>{producerName}</div>}
                            {role && <div>{role}</div>}
                            <div>{email}</div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="emailFacilityContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email, role } =
                    coverage.facilityContacts[index];

                  return (
                    <Row key={key}>
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Facility Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            {fullName && <div>{fullName}</div>}
                            {role && <div>{role}</div>}
                            <div>{email}</div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="emailVenueContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email, role } =
                    coverage.venueContacts[index];

                  return (
                    <Row key={key}>
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Venue Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            {fullName && <div>{fullName}</div>}
                            {role && <div>{role}</div>}
                            <div>{email}</div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="customContacts">
            {({ items, pushControl, removeControl }) => (
              <>
                {items.map(
                  (
                    {
                      key,
                      value: { contactInfo },
                    }: ControlModels.FormControl<{
                      sendEmail: boolean;
                      contactInfo: CustomContact;
                    }>,
                    index
                  ) => {
                    return (
                      <Row key={key}>
                        <Col>
                          <Card className="mb-4">
                            <Card.Header className="bg-white font-weight-bold d-flex justify-content-between">
                              Custom Contact #{index + 1}
                              <button
                                className="btn-link--black btn-link--delete btn btn-link"
                                onClick={() => removeControl(index)}
                              >
                                Delete
                              </button>
                            </Card.Header>
                            <Card.Body>
                              <Field
                                component={TextInput}
                                name={`customContacts.${index}.contactInfo.fullName`}
                                label="Full name"
                                ariaLabel="Full name"
                                inputClassName="form-control-lg mb-4"
                              />
                              <Field
                                name={`customContacts.${index}.contactInfo.email`}
                                label="Email"
                                inputClassName="form-control-lg"
                                component={CustomContactEmailField}
                                coverage={coverage}
                                index={index}
                                emailControl={
                                  state[
                                    `customContacts.${index}.contactInfo.email`
                                  ]
                                }
                                contactsFormGroup={state.root}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    );
                  }
                )}
                {coverage.status !== "expired" &&
                  items.length < MAX_CUSTOM_CONTACTS && (
                    <div className="d-flex">
                      <Button
                        text="Add Custom Contact"
                        className="btn btn-outline-secondary w-100 mt-4"
                        onClick={() =>
                          pushControl(customContact(emptyCustomContact))
                        }
                      />
                    </div>
                  )}
              </>
            )}
          </FormArray>
        </Form>
      </div>

      {customContacts.loading && (
        <div className="disable-overlay">
          <div className="disable-overlay__inner">
            <LoadingSpinner />
            <div className="h6 mt-2">Saving...</div>
          </div>
        </div>
      )}

      <Modal
        show={customContacts.success || Boolean(customContacts.error)}
        size="lg"
      >
        <Modal.Header
          className="w-100 border-0 pb-0"
          closeButton
          onHide={() => {
            rxPolicyActions.customContacts.resetState();
            history.push(getUrl(POLICY_CONTACT_PAGE, coverage.id));
          }}
        />
        <Modal.Body className="text-center px-5">
          <p>
            {customContacts.success
              ? "Policy contacts have been successfully saved."
              : "There was an error saving policy contacts. Please try again."}
          </p>
          <br />
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 mb-3">
          <Button
            className="btn btn-primary px-5"
            onClick={() => {
              rxPolicyActions.customContacts.resetState();
              history.push(getUrl(POLICY_CONTACT_PAGE, coverage.id));
            }}
            text="OK"
          />
        </Modal.Footer>
      </Modal>

      {showDiscardModal && (
        <ModalDiscardAddNew
          show={showDiscardModal}
          handleClose={discardModalToggle.toggleOff}
          goToPage={() => {
            history.push(getUrl(POLICY_CONTACT_PAGE, coverage.id));
          }}
        />
      )}
    </>
  );
};

export default EditPolicyContacts;
