import { group, control } from "@reactables/forms";
import { ContactType } from "./contactTypes";
import { Coverage } from "Features/Policies/Models/coverage.model";
import { emptyCoverage } from "Helpers/CoverageModel";

export const sameAddress = control(["yes"]);

interface Address {
  address1: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  address2?: string;
}

export const address = (address: Address, required = true) =>
  group({
    controls: {
      address: control([address.address1, required ? ["required"] : []]),
      city: control([address.city, required ? ["required"] : []]),
      state: control([address.state, required ? ["required"] : []]),
      zip: control({
        initialValue: address.zip,
        validators: ["zipCode"].concat(required ? ["required"] : []),
        normalizers: ["normalizeZip"],
      }),
    },
  });

export const addressWithLine2 = (add: Address, required = true) =>
  group({
    controls: {
      ...address(add, required).controls,
      address2: control([add.address2]),
    },
  });

export const differentAddressFields = (
  coverage: Coverage = emptyCoverage as unknown as Coverage
) =>
  group({
    controls: {
      type: control([coverage.insuranceContactType || ContactType.Person]),
      address: address({
        address1: coverage.insuranceContactAddress1,
        address2: coverage.insuranceContactAddress2,
        city: coverage.insuranceContactCity,
        state: coverage.insuranceContactState,
        zip: coverage.insuranceContactZip,
        country: "US",
      }),
      ...(coverage.insuranceContactType === ContactType.Person
        ? {
            firstName: control(["", "required"]),
            lastName: control(["", "required"]),
          }
        : { companyName: companyName(coverage.insuranceContactCompanyName) }),
    },
  });

export const companyName = (companyName: string = "") =>
  control([companyName, "required"]);

export const differentAddressFieldsNoPersonName = (
  coverage: Coverage = emptyCoverage as unknown as Coverage
) =>
  group({
    controls: {
      type: control([coverage.insuranceContactType || ContactType.Person]),
      address: address({
        address1: coverage.insuranceContactAddress1,
        address2: coverage.insuranceContactAddress2,
        city: coverage.insuranceContactCity,
        state: coverage.insuranceContactState,
        zip: coverage.insuranceContactZip,
        country: "US",
      }),
      ...(coverage.insuranceContactType === ContactType.Company
        ? { companyName: companyName(coverage.insuranceContactCompanyName) }
        : undefined),
    },
  });
