import { group, control } from "@reactables/forms";
import { addressWithLine2 } from "./address.config";
import { Coverage } from "Features/Policies/Models/coverage.model";

export const facility = (ai?) =>
  group({
    controls: {
      id: control([ai?.id || ""]),
      type: control(["facility"]),
      address: group({
        controls: {
          companyName: control([ai?.companyName || "", ["required"]]),
          ...addressWithLine2(
            {
              address1: ai?.address1 || "",
              address2: ai?.address2 || "",
              city: ai?.city || "",
              state: ai?.state || "",
              zip: ai?.zip || "",
              country: "US",
            },
            false
          ).controls,
        },
      }),
    },
  });

export const venueAddress = (ai) =>
  group({
    controls: {
      id: control([ai?.id || ""]),
      type: control(["venue_address"]),
      address: group({
        controls: {
          companyName: control([ai?.companyName || "", []]),
          ...addressWithLine2({
            address1: ai?.address1 || "",
            address2: ai?.address2 || "",
            city: ai?.city || "",
            state: ai?.state || "",
            zip: ai?.zip || "",
            country: "US",
          }).controls,
        },
      }),
    },
  });

export const venue = (ai) =>
  group({
    controls: {
      id: control([ai?.id || ""]),
      type: control(["venue"]),
      address: group({
        controls: {
          companyName: control([ai?.companyName || "", []]),
          ...addressWithLine2(
            {
              address1: ai?.address1 || "",
              address2: ai?.address2 || "",
              city: ai?.city || "",
              state: ai?.state || "",
              zip: ai?.zip || "",
              country: "US",
            },
            false
          ).controls,
        },
      }),
    },
  });

export const text = (ai) =>
  group({
    controls: {
      id: control([ai?.id || ""]),
      type: control(["text"]),
      text: control({
        initialValue: ai?.text || "",
        normalizers: ["maxLength200"],
      }),
    },
  });

export const additionalInsured = (coverage: Coverage) => {
  const aiMap = coverage.additionalInsured.reduce(
    (acc, ai) => ({ ...acc, [ai.type]: ai }),
    {}
  );

  return group({
    controls: {
      ...(aiMap["facility"] && aiMap["facility"].companyName
        ? {
            facility: facility(aiMap["facility"]),
          }
        : { facility: control([null]) }),
      venueAddress: venueAddress(aiMap["venue_address"]),
      venue: venue(aiMap["venue"]),
      text: text(aiMap["text"]),
    },
  });
};
