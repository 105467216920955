import { from, Observable } from "rxjs";
import {
  debounceTime,
  filter,
  map,
  distinctUntilChanged,
} from "rxjs/operators";
import { Action, combine, Reactable, ofTypes } from "@reactables/core";
import { HookedReactable } from "@reactables/react";
import { dateHelpers } from "@jauntin/utilities";
import {
  ControlModels,
  group,
  RxFormActions,
  ControlRef,
} from "@reactables/forms";
import {
  FormBuilders,
  RxToggle,
  RxToggleActions,
  RxToggleState,
  RxRequest,
  RequestState,
  RequestActions,
} from "@jauntin/reactables";
import { Coverage } from "Features/Policies/Models/coverage.model";
import { event } from "./Configs/event.config";
import { insured } from "./Configs/insured.config";
import { insuranceContact } from "./Configs/insuranceContact.config";
import { venue } from "./Configs/venue.config";
import { additionalInsured } from "./Configs/additionalInsured.config";
import { cohost } from "./Configs/cohost.config";
import formProviders from "Features/Shared/Helpers/formProviders";
import { validateUsState } from "Features/Shared/Helpers/asyncValidators";
import { eventReducers, EventActions } from "./FormReducers/event.reducer";
import { venueReducers, VenueActions } from "./FormReducers/venue.reducer";
import {
  insuredReducers,
  InsuredActions,
} from "./FormReducers/insured.reducer";
import { cohostReducers, CohostActions } from "./FormReducers/cohost.reducer";
import {
  additionalInsuredReducers,
  AdditionalInsuredActions,
} from "./FormReducers/additionalInsured.reducers";
import {
  insuranceContactReducers,
  InsuranceContactActions,
} from "./FormReducers/insuranceContact.reducer";
import VenuePresetsService from "Helpers/VenuePresetsService";
import StateTaxService from "Helpers/StateTaxService";
import AppInfoService from "Helpers/AppInfoService";
import { EditPolicyFormValue } from "Features/Policies/Models/editPolicyFormValue.model";
import CoverageService from "Helpers/CoverageService";
import { EventFrequency } from "./Configs/event.config";
import { calculateEventDates } from "Features/Shared/Helpers/calculateEventDates";
import { DateRange } from "Features/Shared/Models/event.model";
import { QuoteSameValidationPayload } from "Features/Policies/Models/quoteSameValidationPayload.model";
import { VenueSearchTypes } from "./Configs/venue.config";
import { isEqual } from "lodash";
import { getEventDatesFromForm } from "./FormReducers/event.reducer";
interface EditPolicyState {
  form: ControlModels.Form<EditPolicyFormValue>;
  quoteValidation: RequestState<{ formsSame: boolean; quoteSame: boolean }>;
  editFacilityAI: RxToggleState;
  editVenueAddressAI: RxToggleState;
  editVenueAI: RxToggleState;
}

type FormActions = EventActions &
  VenueActions &
  InsuredActions &
  InsuranceContactActions &
  AdditionalInsuredActions &
  CohostActions &
  RxFormActions;

interface EditPolicyActions {
  form: FormActions;
  quoteValidation: RequestActions<QuoteSameValidationPayload>;
  editFacilityAI: RxToggleActions;
  editVenueAddressAI: RxToggleActions;
  editVenueAI: RxToggleActions;
}

export type EditPolicyProp = HookedReactable<
  EditPolicyState,
  EditPolicyActions
>;

export const RxEditPolicy = ({
  coverage,
  appInfoService,
  venueService,
  stateTaxService,
  coverageService,
  timezone,
}: {
  coverage: Coverage;
  appInfoService: AppInfoService;
  venueService: VenuePresetsService;
  stateTaxService: StateTaxService;
  coverageService: CoverageService;
  timezone: string;
}): Reactable<EditPolicyState, EditPolicyActions> => {
  // Provide required validators, asyncValidators, and normalizer functions
  const extendedProviders = {
    ...formProviders,
    validators: {
      ...formProviders.validators,
      maxAttendees: (value: {
        eventDailyGuests: number;
        [EventFrequency.Continuous]?: {
          eventDateRange: DateRange;
        };
        [EventFrequency.Weekly]?: {
          daysOfWeek: Day[];
          eventDateRange: DateRange;
        };
        [EventFrequency.Custom]?: {
          eventDates: string[];
        };
      }) => {
        let numberOfDays = 0;

        if (value[EventFrequency.Continuous]) {
          numberOfDays = calculateEventDates({
            timezone,
            eventDateRangeField:
              value[EventFrequency.Continuous].eventDateRange,
          }).length;
        } else if (value[EventFrequency.Weekly]) {
          numberOfDays = calculateEventDates({
            timezone,
            daysOfWeekField: value[EventFrequency.Weekly].daysOfWeek,
            eventDateRangeField: value[EventFrequency.Weekly].eventDateRange,
          }).length;
        } else if (value[EventFrequency.Custom]) {
          numberOfDays = value[EventFrequency.Custom].eventDates.length;
        }

        return {
          maxAttendees: numberOfDays * value.eventDailyGuests > 5000,
        };
      },
    },
    asyncValidators: {
      validateUsState: validateUsState(() =>
        from(
          (
            appInfoService.getStates() as Promise<{
              data: Array<{ code: string; name: string }>;
            }>
          ).then(({ data }) => data)
        )
      ),
    },
  };

  /**
   * Form reactable for editting policy
   */
  const rxForm = FormBuilders.build(
    group({
      controls: {
        event: event(coverage),
        insured: insured(coverage),
        ...(coverage.cohostAddress ? { cohost: cohost(coverage) } : undefined),
        insuranceContact: insuranceContact(coverage),
        venue: venue(coverage),
        additionalInsured: additionalInsured(coverage),
      },
    }),
    {
      providers: extendedProviders,
      reducers: {
        ...eventReducers({ timezone }),
        ...venueReducers({
          venueService,
          appInfoService,
          stateTaxService,
          coverage,
        }),
        ...insuredReducers,
        ...cohostReducers,
        ...insuranceContactReducers,
        ...additionalInsuredReducers,
      },
    }
  ) as Reactable<ControlModels.Form<EditPolicyFormValue>, FormActions>;

  /**
   * Reactable to validate if there are quote or form changes
   */
  const rxValidateQuote = RxRequest<
    QuoteSameValidationPayload,
    { formsSame: boolean; quoteSame: boolean }
  >({
    sources: [
      rxForm[0].pipe(
        debounceTime(500),
        map((formState) => {
          const {
            root: { value },
          } = formState;
          // Prepare payload for validation with API
          const { event, venue, insured } = value;
          const eventDates = getEventDatesFromForm(formState, timezone).map(
            (date) => dateHelpers.dateOnlyStringFormat(date)
          );
          const payload: QuoteSameValidationPayload = {
            coverageId: coverage.id,
            eventDates,
            numberOfDays: eventDates.length,
            averageDailyAttendance: event.eventDailyGuests || 1,
            venueState: (() => {
              switch (venue.searchType) {
                case VenueSearchTypes.ByVenueCode:
                  return (
                    venue.venueSearchResults.knownVenue?.venue?.state || ""
                  );
                case VenueSearchTypes.ByManualAddress:
                  return venue.byManualAddress.address.state || "";
                case VenueSearchTypes.BySearch:
                  return (
                    venue.bySearch.selectedPlace?.addressComponents?.state || ""
                  );
                default:
                  return "";
              }
            })(),
            renterState: insured.address.state,
            federalEntity:
              venue.venueSearchResults.taxFields?.federalEntity === "yes"
                ? 1
                : 0,
            kentuckyStateEntity:
              venue.venueSearchResults.taxFields?.kentuckyStateEntity === "yes"
                ? 1
                : 0,
            venueMunicipalityCode:
              venue.venueSearchResults.taxFields?.venueMunicipalityCode || "",
            gll: (() => {
              if (
                venue.venueSearchResults.knownVenue &&
                venue.venueSearchResults.knownVenue.venue.glLimits.includes(
                  parseInt(coverage.venueGll)
                )
              ) {
                return coverage.venueGll;
              }

              return "1000000";
            })(),
          };

          return { type: "send", payload };
        }),
        filter(({ payload }) => Boolean(payload.venueState)),
        distinctUntilChanged(isEqual)
      ),
    ],
    resource: (payload) =>
      coverageService.prevalidateChanges(payload).then(({ data }) => data),
  });

  const formActions$ = rxForm[2];

  /**
   * Toggles to show/hide editting Additional Insured Views
   */
  const rxEditFacilityAI = RxToggle(false, {
    sources: [
      formActions$.pipe(
        ofTypes([
          "editFacilityAdditionalInsured",
          "clearFacilityAdditionalInsured",
          "resetFacilityAdditionalInsured",
        ])
      ),
    ],
    reducers: {
      editFacilityAdditionalInsured: () => true,
      clearFacilityAdditionalInsured: () => false,
      resetFacilityAdditionalInsured: () => false,
    },
  });

  const toggleOffOnFormChangeOperator =
    (aiKey: "venueAddress" | "venue") =>
    (source: Observable<Action<unknown>>) =>
      source.pipe(
        filter(({ type, payload }) => {
          switch (type) {
            case "clearFieldsAdditionalInsured":
              return payload === aiKey;
            case "resetControl":
              return (
                (payload as ControlRef).join(".") ===
                `additionalInsured.${aiKey}.address`
              );

            default:
              return false;
          }
        }),
        map(() => ({ type: "toggleOff" }))
      );

  const rxEditVenueAddressAI = RxToggle(false, {
    sources: [formActions$.pipe(toggleOffOnFormChangeOperator("venueAddress"))],
  });

  const rxEditVenueAI = RxToggle(false, {
    sources: [formActions$.pipe(toggleOffOnFormChangeOperator("venue"))],
  });

  return combine({
    form: rxForm,
    quoteValidation: rxValidateQuote,
    editFacilityAI: rxEditFacilityAI,
    editVenueAddressAI: rxEditVenueAddressAI,
    editVenueAI: rxEditVenueAI,
  });
};

export const getHasPriceChangeError = (state: EditPolicyState) =>
  state.quoteValidation.data?.quoteSame === false;
