import { ActionMap } from "@reactables/core";
import { CustomReducers } from "@reactables/forms";
import { contactType } from "./contactType.reducer";
import { sameAddress as sameAddressReducer } from "./sameAddress.reducer";
import { ContactType } from "../Configs/contactTypes";

export interface InsuranceContactActions extends ActionMap {
  selectSameAddressAsInsured: (payload: "yes" | "no") => void;
  selectDifferentInsuranceAddressContactType: (payload: ContactType) => void;
}

export const insuranceContactReducers: CustomReducers<InsuranceContactActions> =
  {
    selectSameAddressAsInsured: sameAddressReducer(
      ["insuranceContact"],
      "sameAddressAsInsured",
      false
    ),
    selectDifferentInsuranceAddressContactType: contactType(
      ["insuranceContact", "differentAddressFields"],
      false
    ),
  };
