import { IntegerNumberInput } from "@jauntin/react-ui";
import { getAttendanceRange } from "Helpers/AttendenceRangeHelper";

const GuestsField = ({
  input,
  meta,
  disabled,
  eventTotalGuests,
  eventDates,
  hasMaxAttendeesError,
  showErrors,
}) => (
  <div className="guestField form-group d-flex flex-column flex-wrap">
    <div className="col-sm-12 p-0">
      <span
        className={
          meta.touched && (meta.error || hasMaxAttendeesError)
            ? "label form-error__label"
            : "label"
        }
      >
        How many people are you expecting?
      </span>
      <div className="label__subtext d-block">
        Include all who will attend including hosts, guests, volunteers, and any
        performers, vendors or exhibitors.
      </div>
    </div>
    <div className="row flex-column">
      <div className="px-3 guestField__input">
        <IntegerNumberInput
          input={input}
          meta={{
            ...meta,
            errors: {
              ...meta.errors,
              maxAttendees: hasMaxAttendeesError,
            },
          }}
          label="Average Daily Attendance?"
          placeholder="Avg Daily Attendance"
          min={1}
          disabled={disabled}
          required
          showErrors={showErrors}
        />
      </div>
      <div className="px-3 flex-column">
        <div className="col-auto p-0 guestField__select mb-3"></div>
        <div className="form-row">
          <div className="col-auto">
            <span className="font-weight-bold">Total Attendance</span>:{" "}
            <span className="ml-2 mr-3">{eventTotalGuests}</span>
            <span className="font-weight-bold">Attendance Range</span>:{" "}
            <span className="ml-2 mr-3">
              {getAttendanceRange(eventTotalGuests)}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default GuestsField;
