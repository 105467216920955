import { NavLink } from "react-router-dom";
import {
  getUrl,
  POLICY_PAGE,
  POLICY_CONTACT_PAGE,
  POLICIES_NOTES_PAGE,
} from "../../../../Helpers/URLParser";
import PolicyDetails from "./PolicyDetailsHeader";
import { Coverage } from "Features/Policies/Models/coverage.model";

const PoliciesMenu = ({ policy }: { policy: Coverage }) => {
  return (
    <div className="subsection-menu">
      <PolicyDetails coverage={policy} />
      <div className="subsection-menu__items">
        <NavLink
          to={getUrl(POLICY_PAGE, policy.id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Policy
        </NavLink>
        <NavLink
          to={getUrl(POLICY_CONTACT_PAGE, policy.id)}
          className="subsection-menu__item d-flex justify-content-between"
          activeClassName="active"
        >
          Contacts &amp; E-mailing
          {policy.policyHasUnacknowledgedChanges && (
            <i className="fas fa-exclamation-triangle subsection-table__caution-icon my-auto" />
          )}
        </NavLink>
        <NavLink
          to={getUrl(POLICIES_NOTES_PAGE, policy.id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Notes
        </NavLink>
      </div>
    </div>
  );
};

export default PoliciesMenu;
