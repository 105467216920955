import React from "react";
import { EventFrequency } from "../Configs/event.config";
import SelectList from "Features/Shared/Components/SelectList";

const EventFrequencyField = ({ input, disabled, onChange, meta }) => (
  <div className="form-group mw--400">
    <label htmlFor={input.name} className="label mr-2">
      How often does this event occur?
    </label>
    <SelectList
      options={[
        { value: EventFrequency.Continuous, label: "One Time" },
        { value: EventFrequency.Weekly, label: "Weekly" },
        { value: EventFrequency.Custom, label: "Custom" },
      ]}
      searchable={false}
      input={input}
      meta={meta}
      disabled={disabled}
      onChange={onChange}
    />
  </div>
);

export default EventFrequencyField;
