import { group, control } from "@reactables/forms";
import { address } from "./address.config";
import { Coverage } from "Features/Policies/Models/coverage.model";

export const cohost = (coverage?: Coverage) =>
  group({
    controls: {
      contactFirstName: control([
        coverage?.cohostAddress?.contactFirstName || "",
        ["required"],
      ]),
      contactLastName: control([
        coverage?.cohostAddress?.contactLastName || "",
        ["required"],
      ]),
      ...address(
        coverage?.cohostAddress || {
          address1: "",
          city: "",
          state: "",
          zip: "",
          country: "US",
        }
      ).controls,
    },
  });
