import { TextInput, PhoneInput, EmailInput } from "@jauntin/react-ui";
import { useSelector } from "react-redux";
import { Field } from "@reactables/react-forms";
import RadioInput from "Features/Shared/Components/RadioInput";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";
import { ContactType } from "../Configs/contactTypes";
import ContactSameField from "./ContactSameField";
import { EditPolicyProp } from "../RxEditPolicy";
import { getLgSelectStyleFromControl } from "Helpers/ReactSelectStyle";

const InsuranceContactFields = ({
  rxEditPolicy,
}: {
  rxEditPolicy: EditPolicyProp;
}) => {
  const [state, actions] = rxEditPolicy;
  const { insuranceContact } = state.form.root.value;
  const contactSame = insuranceContact.sameAddressAsInsured === "yes";

  const usStates = useSelector(
    (appState: { app: { states } }) => appState.app.states
  );
  if (!usStates) return;

  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Insurance Contact</strong>
      </div>
      <div className="card-body">
        <>
          <div className="form-group">
            <ContactSameField
              name="insuranceContact.sameAddressAsInsured"
              onChange={(e) =>
                actions.form.selectSameAddressAsInsured(e.target.value)
              }
            />
          </div>
          <div id="contactIndividual" className="form-row">
            <Field
              component={TextInput}
              name="insuranceContact.firstName"
              label="First Name"
              ariaLabel="First Name"
              placeholder="First Name"
              wrapperClassName="col-sm"
              inputClassName="form-control-lg"
            />
            <Field
              component={TextInput}
              name="insuranceContact.lastName"
              label="Last Name"
              ariaLabel="Last Name"
              placeholder="Last Name"
              wrapperClassName="col-sm"
              inputClassName="form-control-lg"
            />
          </div>
          <div className="form-row">
            <Field
              component={PhoneInput}
              name="insuranceContact.phone"
              subtext="This will only be used for customer service."
              wrapperClassName="col-sm-6 d-flex flex-column"
              inputClassName="form-control-lg"
            />
            <Field
              component={EmailInput}
              name="insuranceContact.email"
              subtext="This is where the policy information will be sent."
              wrapperClassName="col-sm-6 d-flex flex-column"
              inputClassName="form-control-lg"
            />
          </div>
          {!contactSame && (
            <div id="newContactInfo">
              <div className="form-group mt-4">
                <div className="custom-control custom-radio custom-control-inline">
                  <Field
                    id={`insuranceContact.differentAddressFields.typeInd`}
                    name="insuranceContact.differentAddressFields.type"
                    className="custom-control-input"
                    component={RadioInput}
                    value={ContactType.Person}
                    onChange={() =>
                      actions.form.selectDifferentInsuranceAddressContactType(
                        ContactType.Person
                      )
                    }
                  />
                  <label
                    className="custom-control-label label"
                    htmlFor={`insuranceContact.differentAddressFields.typeInd`}
                  >
                    an individual
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <Field
                    id={`insuranceContact.differentAddressFields.typeCorp`}
                    name="insuranceContact.differentAddressFields.type"
                    className="custom-control-input"
                    component={RadioInput}
                    value={ContactType.Company}
                    onChange={() =>
                      actions.form.selectDifferentInsuranceAddressContactType(
                        ContactType.Company
                      )
                    }
                  />
                  <label
                    className="custom-control-label label"
                    htmlFor={`insuranceContact.differentAddressFields.typeCorp`}
                  >
                    a company/organization
                  </label>
                </div>
              </div>
              {insuranceContact.differentAddressFields.type ===
                ContactType.Company && (
                <div id="contactCompany" className="form-row">
                  <Field
                    component={TextInput}
                    name="insuranceContact.differentAddressFields.companyName"
                    label="Company name"
                    ariaLabel="Company name"
                    placeholder="Company name"
                    wrapperClassName="col-sm"
                    inputClassName="form-control-lg"
                  />
                </div>
              )}
              <AddressFieldGroup
                groupName={"insuranceContact.differentAddressFields.address"}
                usStates={usStates}
                stateSelectStyles={getLgSelectStyleFromControl(
                  state.form[
                    "insuranceContact.differentAddressFields.address.state"
                  ]
                )}
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default InsuranceContactFields;
