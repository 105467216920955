import { Coverage } from "Features/Policies/Models/coverage.model";
import { TimezoneFormatted } from "@jauntin/react-ui";
import { dateHelpers } from "@jauntin/utilities";
import DollarsAndCents from "Helpers/DollarsAndCents";
import { currencyFromInt } from "Helpers/CurrencyHelper";
import { KENTUCKY_STATE_CODE } from "../../../../constants";

const Payment = ({ policy }: { policy: Coverage }) => {
  let taxType = "State Tax";
  if (policy.venueState.toUpperCase() === "FL") {
    taxType = "State Surcharge";
  }
  if (policy.venueState.toUpperCase() === "KY") {
    taxType = "KY Municipal Tax";
  }

  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Payment</strong>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-between payment__item">
          <div>Purchase date</div>
          <div>
            <strong>
              <TimezoneFormatted
                date={policy.created}
                format={dateHelpers.SLASH_SEPARATED_FORMAT}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Basic Coverage</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(policy.quoteBasicCoverageAmount)}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex justify-content-between payment__item pt-2">
          <div>Additional Coverages</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(policy.quoteAdditionalCoverageAmount)}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Subtotal</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(policy.quoteSubtotal)}
              />
            </strong>
          </div>
        </div>
        {policy.quoteSurcharges.federalSurcharge > 0 && (
          <div
            className={`d-flex justify-content-between ${
              policy.venueState !== KENTUCKY_STATE_CODE && `payment__item`
            } pt-2`}
          >
            <div>State Surcharge</div>
            <div>
              <strong>
                <DollarsAndCents
                  currency={currencyFromInt(
                    policy.quoteSurcharges.federalSurcharge
                  )}
                />
              </strong>
            </div>
          </div>
        )}
        {policy.quoteSurcharges.stateSurcharge > 0 && (
          <div className="d-flex justify-content-between payment__item pt-2">
            <div>{taxType}</div>
            <div>
              <strong>
                <DollarsAndCents
                  currency={currencyFromInt(
                    policy.quoteSurcharges.stateSurcharge
                  )}
                />
              </strong>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between align-content-middle payment__item pb-2">
          <div>Total</div>
          <div>
            <strong>
              <DollarsAndCents currency={currencyFromInt(policy.quoteTotal)} />
            </strong>
          </div>
        </div>
        <div className="d-flex py-3">
          <div className="col-4 pl-0">
            <div>
              <strong>Credit Card</strong>
            </div>
            <div>{policy.nameOnCard}</div>
          </div>
          <div className="col-4">
            <div>
              <strong>Billing to</strong>
            </div>
            <div>{policy.billingTo}</div>
          </div>
          <div className="col-4">
            <div>
              <strong>Transaction ID</strong>
            </div>
            <div>{policy.transactionId}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
