import { RxPolicy } from "./RxPolicy";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import { useReactable } from "@reactables/react";
import { useParams } from "react-router-dom";
import {
  EDIT_POLICY_CONTACTS_PAGE,
  getUrl,
  POLICY_CONTACT_PAGE,
} from "Helpers/URLParser";
import {
  POLICY_PAGE,
  EDIT_POLICY_PAGE,
  POLICIES_NOTES_PAGE,
} from "Helpers/URLParser";
import CoverageService from "Helpers/CoverageService";
import API from "Helpers/API";
import PoliciesMenu from "../PoliciesMenu/PoliciesMenu";
import EditPolicy from "../EditPolicy/EditPolicy";
import ViewPolicyContacts from "./ViewPolicyContacts";
import EditPolicyContacts from "./EditPolicyContacts";
import PolicyNotes from "../PolicyNotes/PolicyNotes";
import PolicyView from "../PolicyView/PolicyView";

const Policy = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };

  const timezone = useSelector(
    ({ app }: { app: { timezone: string } }) => app.timezone
  );

  const rxPolicy = useReactable(RxPolicy, {
    coverageService: new CoverageService(new API()),
    id: parseInt(id),
    timezone,
    onUpdateSuccess: () => {
      history.push(getUrl(POLICY_PAGE, id));
    },
  });

  const [state] = rxPolicy;

  if (!state) return;

  const {
    entity: { loading, data: policy },
  } = state;

  if (loading && !policy)
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        <Spinner animation="border" role="status" />
        <span className="ml-3">Loading Policy...</span>
      </div>
    );

  return (
    <>
      <div className="subsection__wrapper">
        <PoliciesMenu policy={policy} />
        <div className="subsection__container subsection__container--policy">
          <div>
            <Switch>
              <Route path={getUrl(POLICY_PAGE)}>
                <PolicyView rxPolicy={rxPolicy} />
              </Route>
              <Route path={getUrl(EDIT_POLICY_PAGE)}>
                <EditPolicy rxPolicy={rxPolicy} />
              </Route>
              <Route path={getUrl(POLICIES_NOTES_PAGE)}>
                <PolicyNotes rxPolicy={rxPolicy} />
              </Route>
              <Route path={getUrl(POLICY_CONTACT_PAGE)}>
                <ViewPolicyContacts rxPolicy={rxPolicy} />
              </Route>
              <Route path={getUrl(EDIT_POLICY_CONTACTS_PAGE)}>
                <EditPolicyContacts rxPolicy={rxPolicy} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policy;

// <Route
//   path={getUrl(POLICIES_PAGE)}
//   render={() => (
//     <LoadPolicyContainer>
//       {/* LoadPolicyContainer will load the current policy when it is mounted */}
//       <div className="subsection__wrapper">
//         <PoliciesMenu
//           id={coverage.id || 0}
//           policyHasUnacknowledgedChanges={
//             coverage.policyHasUnacknowledgedChanges
//           }
//         />
//         <div className="subsection__container">
//           <div>
//             <Switch>
//               <Route
//                 path={getUrl(POLICY_PAGE)}
//                 component={PolicyContainer}
//               />
//               <Route
//                 path={getUrl(POLICY_CONTACT_PAGE)}
//                 component={ContactsContainer}
//               />
//               <Route
//                 path={getUrl(POLICIES_NOTES_PAGE)}
//                 component={NotesContainer}
//               />
//             </Switch>
//           </div>
//         </div>
//       </div>
//     </LoadPolicyContainer>
//   )}
// />
