import { DateRange } from "../Models/event.model";
import { dateHelpers } from "@jauntin/utilities";
import { isAfter, addDays } from "date-fns";

const {
  pickedToBlockedDays,
  sortDays,
  filterToUniqueDays,
  timezonedDateToLocalDate,
} = dateHelpers;

export const calculateEventDates = ({
  daysOfWeekField,
  eventDateRangeField,
  timezone,
}: {
  daysOfWeekField?: Day[];
  eventDateRangeField: DateRange;
  timezone: string;
}) => {
  let { startDate, endDate } = { ...eventDateRangeField };
  if (!startDate && !endDate) {
    return [];
  }

  const ignoredDays =
    (daysOfWeekField?.length && pickedToBlockedDays(daysOfWeekField)) || [];

  if (!startDate) {
    startDate = endDate;
  } else if (!endDate) {
    endDate = startDate;
  }
  const days = [];
  let currentDate = new Date(timezonedDateToLocalDate(startDate, timezone));
  while (!isAfter(currentDate, timezonedDateToLocalDate(endDate, timezone))) {
    if (!ignoredDays.includes(currentDate.getDay())) {
      days.push(currentDate);
    }
    currentDate = addDays(currentDate, 1);
  }
  return sortDays(filterToUniqueDays(days)).map((date) => date.toISOString());
};
