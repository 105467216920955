import { Observable } from "rxjs";
import { FormBuilders } from "@jauntin/reactables";
import { Action, Reactable } from "@reactables/core";
import { ControlModels, RxFormActions, CustomReducer } from "@reactables/forms";
import formProviders from "Features/Shared/Helpers/formProviders";
import { Coverage } from "Features/Policies/Models/coverage.model";
import { policyContacts } from "Features/Policies/Configs/policyContacts.config";
import { CustomContact } from "Features/Policies/Models/policyContacts.model";
import { PolicyContactsForm } from "Features/Policies/Models/policyContactsForm.model";

export type PolicyContactsFormState = ControlModels.Form<PolicyContactsForm>;
export type PolicyContactsFormActions = {
  selectAllEmails: () => void;
  deselectAllEmails: () => void;
} & RxFormActions;

export const getPolicyEmails = (coverage: Coverage) => {
  const {
    insuranceContactEmail,
    producerContacts,
    facilityContacts,
    venueContacts,
  } = coverage;

  return [
    insuranceContactEmail,
    ...producerContacts.map(({ email }) => email),
    ...facilityContacts.map(({ email }) => email),
    ...venueContacts.map(({ email }) => email),
  ];
};

const updateAllCheckboxes =
  (selectAll: boolean): CustomReducer =>
  (
    { updateValues },
    state: ControlModels.BaseFormState<PolicyContactsForm>
  ) => {
    const {
      emailProducerContacts,
      emailFacilityContacts,
      emailVenueContacts,
      customContacts,
    } = state.form.root.value;

    const value: PolicyContactsForm = {
      emailInsuranceContact: selectAll,
      emailProducerContacts: emailProducerContacts.map(() => selectAll),
      emailFacilityContacts: emailFacilityContacts.map(() => selectAll),
      emailVenueContacts: emailVenueContacts.map(() => selectAll),
      customContacts: customContacts.map(({ contactInfo }) => ({
        sendEmail: selectAll,
        contactInfo,
      })),
    };

    return updateValues(state, { controlRef: [], value });
  };

export const RxPolicyContactsForm = ({
  coverage,
  sources,
  edit,
}: {
  coverage: Coverage;
  sources: Observable<Action<unknown>>[];
  edit: boolean;
}) =>
  FormBuilders.build(policyContacts(coverage, edit), {
    sources,
    reducers: {
      selectAllEmails: updateAllCheckboxes(true),
      deselectAllEmails: updateAllCheckboxes(false),
      "Policy - policyEmails - sendSuccess": (
        { updateValues },
        state: ControlModels.BaseFormState<PolicyContactsForm>,
        { payload: updatedContacts }: Action<CustomContact[]>
      ) => {
        const { value } = state.form.root;
        const newValue: PolicyContactsForm = {
          ...value,
          customContacts: value.customContacts.map((contact, index) => ({
            sendEmail: contact.sendEmail,
            contactInfo: updatedContacts[index] || {
              ...coverage.customContacts[index],
              fullName: coverage.customContacts[index].fullName || "",
            },
          })),
        };
        state = updateValues(state, { controlRef: [], value: newValue });
        return state;
      },
      deletedContactSuccess: (
        { removeControl },
        state: ControlModels.BaseFormState<PolicyContactsForm>,
        { payload: { contactId } }: Action<{ contactId: number }>
      ) => {
        const i = state.form.root.value.customContacts.findIndex(
          (v) => v.contactInfo.id === contactId
        );

        return removeControl(state, ["customContacts", i]);
      },
    },
    providers: {
      ...formProviders,
      validators: {
        ...formProviders.validators,
        emailSelected: ({
          emailInsuranceContact,
          emailProducerContacts,
          emailFacilityContacts,
          emailVenueContacts,
          customContacts,
        }: PolicyContactsForm) => {
          return {
            emailSelected: ![
              emailInsuranceContact,
              ...emailProducerContacts,
              ...emailFacilityContacts,
              ...emailVenueContacts,
              ...customContacts.map(({ sendEmail }) => sendEmail),
            ].some((selected) => selected),
          };
        },
        uniqueCustomContactEmails: ({ customContacts }: PolicyContactsForm) => {
          const policyshipEmails = new Set(getPolicyEmails(coverage));

          const allEmails = [
            ...policyshipEmails,
            ...customContacts.map(({ contactInfo: { email } }) => email),
          ].filter((email) => Boolean(email));

          return {
            uniqueCustomContactEmails:
              new Set(allEmails).size !== allEmails.length,
          };
        },
      },
    },
  }) as Reactable<PolicyContactsFormState, PolicyContactsFormActions>;
