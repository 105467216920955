import { group, control } from "@reactables/forms";
import { address } from "./address.config";
import { Coverage } from "Features/Policies/Models/coverage.model";
import { emptyCoverage } from "Helpers/CoverageModel";
import { KnownVenue } from "Features/Policies/Models/editPolicyFormValue.model";

export enum VenueSearchTypes {
  BySearch = "bySearch",
  ByVenueCode = "byVenueCode",
  ByManualAddress = "byManualAddress",
}

export const byVenueCode = (
  coverage: Coverage = emptyCoverage as unknown as Coverage
) =>
  group({
    validators: ["facilityAndVenueCodeRequired", "facilityAndVenueCode"],
    controls: {
      facilityCode: control({
        initialValue: coverage.facilityCode,
        validators: ["required", "facilityCode"],
        normalizers: ["normalizeFacilityCode"],
      }),
      venueCode: control({
        initialValue: coverage.venueCode,
        validators: ["required", "venueCode"],
        normalizers: ["normalizeVenueCode"],
      }),
    },
  });

export const bySearch = (coverage?: Coverage) =>
  group({
    controls: {
      inputText: control([{ name: coverage?.venueCompanyName || "" }]),
      selectedPlace: control([
        coverage
          ? {
              placeId: coverage.venueGooglePlaceId,
              address: coverage.venueAddress1,
              addressComponents: {
                address1: coverage.venueAddress1,
                city: coverage.venueCity,
                state: coverage.venueState,
                zip: coverage.venueZip,
                country: "US",
                companyName: coverage.venueCompanyName,
                addressType: [],
              },
              utcOffset: coverage.venueUtcOffset,
              placeName: coverage.venueCompanyName,
            }
          : null,
        [
          "venueNameRequired",
          "venueAddressRequired",
          "venueSelectedPlaceRequired",
        ],
        ["validateUsState"],
      ]),
    },
  });

export const byManualAddress = (
  coverage: Coverage = emptyCoverage as unknown as Coverage
) =>
  group({
    controls: {
      venueName: control([coverage.manualVenueCompanyName, "required"]),
      address: address({
        address1: coverage.manualVenueAddress,
        city: coverage.manualVenueCity,
        state: coverage.manualVenueState,
        country: "US",
        zip: coverage.manualVenueZip,
      }),
    },
  });

export const venue = (coverage: Coverage) =>
  group({
    controls: {
      searchType: control([coverage.venueSearchTypeRadio, "required"]),
      ...(() => {
        switch (coverage.venueSearchTypeRadio) {
          case VenueSearchTypes.ByVenueCode:
            return { byVenueCode: byVenueCode(coverage) };
          case VenueSearchTypes.BySearch:
            return { bySearch: bySearch(coverage) };
          case VenueSearchTypes.ByManualAddress:
            return { byManualAddress: byManualAddress(coverage) };
          default:
            return {};
        }
      })(),
      venueSearchResults: group({
        controls: {
          knownVenue: control([
            coverage.venueCode
              ? ({
                  venue: {
                    id: coverage.venueId,
                    venueCode: coverage.venueCode,
                    companyName: coverage.venueCompanyName,
                    address1: coverage.venueAddress1,
                    address2: coverage.venueAddress2,
                    city: coverage.venueCity,
                    state: coverage.venueState,
                    zip: coverage.venueZip,
                    country: "US",
                    glLimits: coverage.venueGlLimits,
                  },
                  facility: {
                    code: coverage.facilityCode,
                  },
                } as KnownVenue)
              : false,
            ["notNull", "blockedVenue"],
          ]),
          hasTaxLookup: control([false, "notNull"]),
          taxRegions: control([[], "notNull"]),
          utcOffset: control([coverage.venueUtcOffset, "notNull"]),
          apiError: control([false, "notTrue"]),
          taxFields: taxFields(coverage),
        },
      }),
    },
  });

export const venueSearchResults = group({
  controls: {
    knownVenue: control([null, ["notNull", "blockedVenue"]]),
    hasTaxLookup: control([null, "notNull"]),
    taxRegions: control([null, "notNull"]),
    utcOffset: control([null, "notNull"]),
    apiError: control([false, "notTrue"]),
    // taxFields controls will be added here if applicable
  },
});
export const taxFields = (coverage?: Coverage) =>
  group({
    controls: {
      federalEntity: control([
        coverage ? (coverage.isFederalEntity ? "yes" : "no") : "",
        "required",
      ]),
      kentuckyStateEntity: control([
        coverage ? (coverage.isKentuckyEntity ? "yes" : "no") : "",
        "required",
      ]),
      venueMunicipalityCode: control([coverage?.venueMunicipalityCode || ""]),
    },
  });

export const venueMunicipalityCode = control([""]);
