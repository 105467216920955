import { Action } from "@reactables/core";
import { ControlRef, FormReducers, control } from "@reactables/forms";
import { ContactType } from "../Configs/contactTypes";
import { companyName } from "../Configs/address.config";

/**
 * @description Toggle controls based on contact type being a Person vs Company
 * Adds first/lastName fields for Person (if includePersonNameFields = true)
 * and companyName for Company
 */
export const contactType =
  (groupRef: ControlRef, includePersonNameFields = true) =>
  (
    { addControl, removeControl, updateValues }: FormReducers,
    state,
    { payload }: Action<ContactType>
  ) => {
    state = updateValues(state, {
      controlRef: groupRef.concat("type"),
      value: payload,
    });

    const companyNameRef = groupRef.concat("companyName");
    const firstNameRef = groupRef.concat("firstName");
    const lastNameRef = groupRef.concat("lastName");

    const isCompany = payload === ContactType.Company;

    if (isCompany) {
      state = addControl(state, {
        controlRef: companyNameRef,
        config: companyName(),
      });

      if (includePersonNameFields) {
        state = removeControl(state, firstNameRef);
        state = removeControl(state, lastNameRef);
      }
    } else {
      state = removeControl(state, companyNameRef);
      if (includePersonNameFields) {
        state = addControl(state, {
          controlRef: firstNameRef,
          config: control(["", "required"]),
        });
        state = addControl(state, {
          controlRef: lastNameRef,
          config: control(["", "required"]),
        });
      }
    }

    return state;
  };
