import { ControlModels, RxFormActions } from "@reactables/react-forms";
import { EditPolicyFormValue } from "Features/Policies/Models/editPolicyFormValue.model";
import { Field } from "@reactables/react-forms";
import { classname, ExtendedMeta, mapExtendedErrors } from "@jauntin/utilities";
import PopoverTrigger from "Features/Shared/Components/Popover";
import RadioInput from "Features/Shared/Components/RadioInput";

interface Tax {
  state: string;
  rate: number;
  code: string;
  name: string;
}

const SpecialTaxFields = ({
  rxForm: [formState, formActions],
}: {
  rxForm: [ControlModels.Form<EditPolicyFormValue>, RxFormActions];
}) => {
  const isNotKentuckyEntity =
    formState["venue.venueSearchResults.taxFields.kentuckyStateEntity"]
      ?.value === "no";
  const taxRegions = formState["venue.venueSearchResults.taxRegions"]
    ?.value as Tax[];

  const taxFields = "venue.venueSearchResults.taxFields";
  const federalEntityControl = mapExtendedErrors(
    formState[`${taxFields}.federalEntity`]
  );

  const kentuckyStateEntityControl = mapExtendedErrors(
    formState[`${taxFields}.kentuckyStateEntity`]
  );

  const showErrors = ({ error }: ExtendedMeta) => false;
  return (
    <>
      <>
        <div className="form-group">
          <div
            className={classname(
              "mb-1",
              showErrors(federalEntityControl) && "form-error__label"
            )}
          >
            Is the insured a government entity, resident education, or
            charitable institution qualifying under 501(c)(3)?
            <span className="ml-1">
              <PopoverTrigger content="Some qualifying organizations, like schools, may be exempt from certain charges." />
            </span>
          </div>
          {showErrors(federalEntityControl) && (
            <div className="form-error">Required</div>
          )}

          <div className="custom-control custom-radio">
            <Field
              name={`${taxFields}.federalEntity`}
              value="yes"
              id="federalEntityYes"
              component={RadioInput}
              className="custom-control-input"
            />
            <label
              className="custom-control-label label"
              htmlFor="federalEntityYes"
            >
              Yes
            </label>
          </div>

          <div className="custom-control custom-radio">
            <Field
              name={`${taxFields}.federalEntity`}
              value="no"
              id="federalEntityNo"
              component={RadioInput}
              className="custom-control-input"
            />
            <label
              className="custom-control-label label"
              htmlFor="federalEntityNo"
            >
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <div
            className={classname(
              "mb-1",
              showErrors(kentuckyStateEntityControl) && "form-error__label"
            )}
          >
            Is the insured the State of Kentucky, one of its agencies or
            political subdivisions?
          </div>
          {showErrors(kentuckyStateEntityControl) && (
            <div className="form-error">Required</div>
          )}

          <div className="custom-control custom-radio">
            <Field
              name={`${taxFields}.kentuckyStateEntity`}
              value="yes"
              id={`${taxFields}.kentuckyStateEntityYes`}
              component={RadioInput}
              type="radio"
              className="custom-control-input"
              onChange={() => {
                formActions.selectKentuckyStateEntity("yes");
              }}
            />
            <label
              className="custom-control-label label"
              htmlFor={`${taxFields}.kentuckyStateEntityYes`}
            >
              Yes
            </label>
          </div>

          <div className="custom-control custom-radio">
            <Field
              name={`${taxFields}.kentuckyStateEntity`}
              value="no"
              id={`${taxFields}.kentuckyStateEntityNo`}
              component={RadioInput}
              className="custom-control-input"
              onChange={() => {
                formActions.selectKentuckyStateEntity("no");
              }}
            />
            <label
              className="custom-control-label label"
              htmlFor={`${taxFields}.kentuckyStateEntityNo`}
            >
              No
            </label>
          </div>
        </div>

        {isNotKentuckyEntity && taxRegions.length > 0 && (
          <>
            <div className="mb-1">
              In which district is this address located?
              <span className="ml-1">
                <PopoverTrigger content="Kentucky tax and insurance regulations require payment from insurers for certain risk areas in KY. We need to know which municipality to pay when more than one could apply." />
              </span>
            </div>
            <div className="custom-control custom-radio">
              {taxRegions.map((region) => {
                return (
                  <div key={`${region.name.replace(/ /g, "")}`}>
                    <Field
                      id={`${region.name.replace(/ /g, "")}`}
                      name={`${taxFields}.venueMunicipalityCode`}
                      component={RadioInput}
                      className="custom-control-input"
                      value={region.code}
                    />
                    <label
                      className="custom-control-label label"
                      htmlFor={`${region.name.replace(/ /g, "")}`}
                    >
                      {region.name}
                    </label>
                  </div>
                );
              })}
              <div>
                <Field
                  type="radio"
                  id="noEventRegion"
                  name={`${taxFields}.venueMunicipalityCode`}
                  className="custom-control-input"
                  component={RadioInput}
                  value=""
                />
                <label
                  className="custom-control-label label"
                  htmlFor="noEventRegion"
                >
                  None
                </label>
              </div>
            </div>
            <br />
          </>
        )}
      </>
    </>
  );
};

export default SpecialTaxFields;
