import { Action, ActionMap } from "@reactables/core";
import { normalizers, validators } from "@jauntin/utilities";
import { Coverage } from "Features/Policies/Models/coverage.model";
import { Observable, of, EMPTY, from } from "rxjs";
import { CustomReducers, FormReducers } from "@reactables/forms";
import {
  map,
  switchMap,
  debounceTime,
  filter,
  catchError,
} from "rxjs/operators";
import { normalizeVenueCode, normalizeFacilityCode } from "normalizer";
import {
  venueAddressRequired,
  venueNameRequired,
} from "Features/Shared/Helpers/validators";
import VenuePresetsService from "Helpers/VenuePresetsService";
import StateTaxService from "Helpers/StateTaxService";
import AppInfoService from "Helpers/AppInfoService";
import {
  VenueCodeSearchChangePayload,
  VenueSearchResponse,
  VenueSearchResults,
  TaxLookupResults,
  SelectPlaceAddressSearchPayload,
} from "../../../../Shared/Models/venue.model";
import { venueSearchResults, VenueSearchTypes } from "../Configs/venue.config";
import {
  byVenueCode,
  bySearch,
  byManualAddress,
  venueMunicipalityCode,
  taxFields,
} from "../Configs/venue.config";
import {
  venueCodeLookup,
  selectedPlaceVenueLookup,
  taxLookup,
} from "../Operators/venue.operator";
import {
  clearFacilityAdditionalInsured,
  editFacilityAdditionalInsured,
  clearFieldsAdditionalInsured,
} from "./additionalInsured.reducers";
import {
  DEFAULT_FACILITY_CODE,
  DEFAULT_PRODUCER_NAME,
} from "../../../../../constants";

const { zipCode: zipCodeValidator } = validators;

const { normalizeZip } = normalizers;

export interface VenueActions extends ActionMap {
  selectVenueSearchType: (payload: VenueSearchTypes) => void;
  venueCodeSearchChange: (payload: VenueCodeSearchChangePayload) => void;
  selectPlaceAddressSearch: (payload: SelectPlaceAddressSearchPayload) => void;
  manualTaxAreaChange: (payload: { city: string; state: string }) => void;
  manualZipCodeChange: (payload: string) => void;
  clearPlaceAddressSearch: () => void;
  selectKentuckyStateEntity: (payload: "yes" | "no") => void;
}

const handleVenueChange = (
  formReducers: FormReducers,
  state,
  hasFacilityReferral
) => {
  const { removeControl, addControl } = formReducers;
  state = removeControl(state, ["venue", "venueSearchResults"]);
  state = addControl(state, {
    controlRef: ["venue", "venueSearchResults"],
    config: venueSearchResults,
  });

  if (!hasFacilityReferral) {
    state = clearFacilityAdditionalInsured(formReducers, state);
  }

  state = clearFieldsAdditionalInsured(formReducers, state, {
    payload: "venueAddress",
  });

  state = clearFieldsAdditionalInsured(formReducers, state, {
    payload: "venueAddress",
  });

  return state;
};

export const venueReducers = ({
  venueService,
  stateTaxService,
  appInfoService,
  coverage,
}: {
  venueService: VenuePresetsService;
  stateTaxService: StateTaxService;
  appInfoService: AppInfoService;
  coverage: Coverage;
}): CustomReducers<VenueActions> => ({
  /**
   * @description Selects venue search type and applies the correct search controls accordingly
   */
  selectVenueSearchType: (
    formReducers,
    state,
    { payload: searchType }: Action<VenueSearchTypes>
  ) => {
    const { updateValues, removeControl, addControl } = formReducers;

    state = removeControl(state, [
      "venue",
      state.form["venue.searchType"].value as string,
    ]);

    state = updateValues(state, {
      controlRef: ["venue", "searchType"],
      value: searchType,
    });

    state = handleVenueChange(
      formReducers,
      state,
      Boolean(coverage.facilityReferralCode)
    );

    switch (searchType) {
      case VenueSearchTypes.ByVenueCode:
        state = addControl(state, {
          controlRef: ["venue", searchType],
          config: byVenueCode(),
        });
        break;
      case VenueSearchTypes.BySearch:
        state = addControl(state, {
          controlRef: ["venue", searchType],
          config: bySearch(),
        });
        break;
      case VenueSearchTypes.ByManualAddress:
        state = addControl(state, {
          controlRef: ["venue", searchType],
          config: byManualAddress(),
        });
        state = updateValues(state, {
          controlRef: ["venue", "venueSearchResults", "knownVenue"],
          value: false,
        });
        break;
      default:
    }

    return state;
  },
  /**
   * @description updates venue code control and performs venue lookup
   */
  venueCodeSearchChange: {
    reducer: (
      formReducers,
      state,
      { payload }: Action<VenueCodeSearchChangePayload>
    ) => {
      const { updateValues } = formReducers;

      state = updateValues(state, {
        controlRef: payload.changedField,
        value: payload.newValue,
      });

      state = handleVenueChange(
        formReducers,
        state,

        Boolean(coverage.facilityReferralCode)
      );

      return state;
    },
    effects: [
      (actions$: Observable<Action<VenueCodeSearchChangePayload>>) =>
        actions$.pipe(
          map(({ payload: { venueCode, facilityCode } }) => ({
            venueCode: normalizeVenueCode(venueCode),
            facilityCode: normalizeFacilityCode(facilityCode),
          })),
          debounceTime(500),
          venueCodeLookup(venueService, stateTaxService)
        ),
    ],
  },
  /**
   * @description Selects google venue and performs venue lookup
   */
  selectPlaceAddressSearch: {
    reducer: (
      formReducers,
      state,
      { payload }: Action<SelectPlaceAddressSearchPayload>
    ) => {
      const { updateValues } = formReducers;

      state = updateValues(state, {
        controlRef: ["venue", "bySearch", "selectedPlace"],
        value: payload,
      });

      state = handleVenueChange(
        formReducers,
        state,
        Boolean(coverage.facilityReferralCode)
      );

      state = updateValues(state, {
        controlRef: ["additionalInsured", "venueAddress"],
        value: {
          id: "",
          type: "venue_address",
          address: {
            companyName: payload.addressComponents.companyName,
            address: payload.addressComponents.address1,
            address2: "",
            city: payload.addressComponents.city,
            state: payload.addressComponents.state,
            zip: payload.addressComponents.zip,
          },
        },
      });

      return state;
    },
    effects: [
      (actions$: Observable<Action<SelectPlaceAddressSearchPayload>>) =>
        actions$.pipe(
          switchMap(({ payload: selectedPlace }) => {
            return from(
              appInfoService.getStates() as Promise<{
                data: Array<{ code: string; name: string }>;
              }>
            ).pipe(
              // Only search if selection is valid
              filter(({ data }) => {
                const validState = data.some(
                  (usState) =>
                    usState.code === selectedPlace.addressComponents.state
                );

                return (
                  validState &&
                  !venueAddressRequired(selectedPlace).venueAddressRequired &&
                  !venueNameRequired(selectedPlace).venueNameRequired
                );
              }),
              map(() => selectedPlace),
              selectedPlaceVenueLookup(venueService, stateTaxService),
              catchError(() => of({ type: "venueLookupFailure" }))
            );
          })
        ),
    ],
  },
  /**
   * @description updates tax and/or city field form manual venue and perform taxLookup
   */
  manualTaxAreaChange: {
    reducer: (
      { updateValues, resetControl, removeControl },
      state,
      {
        payload: { city, state: usState },
      }: Action<{ city: string; state: string }>
    ) => {
      state = resetControl(state, [
        "venue",
        "venueSearchResults",
        "hasTaxLookup",
      ]);

      state = resetControl(state, [
        "venue",
        "venueSearchResults",
        "taxRegions",
      ]);

      const taxFieldsRef = ["venue", "venueSearchResults", "taxFields"];
      if (state.form[taxFieldsRef.join(".")]) {
        state = removeControl(state, taxFieldsRef);
      }

      state = updateValues(state, {
        controlRef: ["venue", "byManualAddress", "address", "city"],
        value: city,
      });

      state = updateValues(state, {
        controlRef: ["venue", "byManualAddress", "address", "state"],
        value: usState,
      });

      return state;
    },
    effects: [
      (taxAreaChange$: Observable<Action<{ city: string; state: string }>>) =>
        taxAreaChange$.pipe(
          debounceTime(500),
          map(({ payload }) => payload),
          taxLookup(stateTaxService),
          map((payload) => ({
            type: "manualTaxAreaLookupSuccess",
            payload,
          })),
          catchError(() => of({ type: "venueLookupFailure" }))
        ),
    ],
  },
  manualTaxAreaLookupSuccess: (
    { updateValues, addControl },
    state,
    { payload: { hasTaxLookup, taxRegions } }: Action<TaxLookupResults>
  ) => {
    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "hasTaxLookup"],
      value: hasTaxLookup,
    });

    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "taxRegions"],
      value: taxRegions,
    });

    if (hasTaxLookup) {
      state = addControl(state, {
        controlRef: ["venue", "venueSearchResults", "taxFields"],
        config: taxFields(),
      });
    }

    return state;
  },
  /** @description update manual zip code and look up utcOffset */
  manualZipCodeChange: {
    reducer: (
      { updateValues, resetControl },
      state,
      { payload }: Action<string>
    ) => {
      state = resetControl(state, ["venue", "venueSearchResults", "utcOffset"]);

      state = updateValues(state, {
        controlRef: ["venue", "byManualAddress", "address", "zip"],
        value: payload,
      });
      return state;
    },
    effects: [
      (zipCodeChange$: Observable<Action<string>>) =>
        zipCodeChange$.pipe(
          debounceTime(500),
          map(({ payload }) => normalizeZip(payload)),
          switchMap((zip) => {
            if (zipCodeValidator(zip)) {
              return EMPTY;
            }

            return from(venueService.getUtcOffsetByZip(zip)).pipe(
              map(
                ({
                  data: { utcOffsetMinutes },
                }: {
                  data: { utcOffsetMinutes: number };
                }) => ({
                  type: "manualUtcOffsetLookupSuccess",
                  payload: utcOffsetMinutes || 0,
                })
              ),
              catchError(() => of({ type: "manualUtcOffsetLookupFailure" }))
            );
          })
        ),
    ],
  },
  manualUtcOffsetLookupSuccess: (
    { updateValues },
    state,
    { payload }: Action<number>
  ) => {
    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "utcOffset"],
      value: payload,
    });
    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "apiError"],
      value: false,
    });

    return state;
  },
  manualUtcOffsetLookupFailure: ({ updateValues }, state) =>
    updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "utcOffset"],
      value: 0,
    }),
  clearPlaceAddressSearch: (formReducers, state) => {
    const { removeControl, addControl } = formReducers;

    if (state.form["venue.bySearch"]) {
      state = removeControl(state, ["venue", "bySearch"]);
      state = addControl(state, {
        controlRef: ["venue", "bySearch"],
        config: bySearch(),
      });
    }

    state = handleVenueChange(
      formReducers,
      state,
      Boolean(coverage.facilityReferralCode)
    );

    return state;
  },
  invalidVenueCode: ({ resetControl }, state) => {
    return (state = resetControl(state, ["venue", "venueSearchResults"]));
  },
  knownVenueFound: (
    { updateValues },
    state,
    action: Action<VenueSearchResponse>
  ) => {
    return updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "knownVenue"],
      value: action.payload,
    });
  },
  venueLookupSuccess: (
    formReducers,
    state,
    { payload }: Action<VenueSearchResults>
  ) => {
    const { updateValues, addControl } = formReducers;
    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults"],
      value: payload,
    });

    if (
      payload.hasTaxLookup &&
      !(payload.knownVenue as VenueSearchResponse)?.venue?.blockedAt
    ) {
      state = addControl(state, {
        controlRef: ["venue", "venueSearchResults", "taxFields"],
        config: taxFields(),
      });
    }

    if (payload.knownVenue) {
      const { facility, venue } = payload.knownVenue;
      // Handle facility Additional Insured
      state = editFacilityAdditionalInsured(formReducers, state);

      const { code, producerName } = payload.knownVenue.facility;
      const isDefaultProducerOrDefaultFacility =
        code === DEFAULT_FACILITY_CODE ||
        producerName === DEFAULT_PRODUCER_NAME;

      const { address: facilityAddress } = facility;

      state = updateValues(state, {
        controlRef: ["additionalInsured", "facility"],
        value: {
          id: "",
          type: "facility",
          address: {
            companyName: facilityAddress.companyName,
            address: isDefaultProducerOrDefaultFacility
              ? ""
              : facilityAddress.address1,
            address2: isDefaultProducerOrDefaultFacility
              ? ""
              : facilityAddress.address2,
            city: isDefaultProducerOrDefaultFacility
              ? ""
              : facilityAddress.city,
            state: isDefaultProducerOrDefaultFacility
              ? ""
              : facilityAddress.state,
            zip: isDefaultProducerOrDefaultFacility ? "" : facilityAddress.zip,
          },
        },
      });

      // Update venue address
      state = updateValues(state, {
        controlRef: ["additionalInsured", "venueAddress"],
        value: {
          id: "",
          type: "venue_address",
          address: {
            companyName: venue.companyName,
            address: venue.address1,
            address2: venue.address2,
            city: venue.city,
            state: venue.state,
            zip: venue.zip,
          },
        },
      });

      // Update venue additional insured
      state = updateValues(state, {
        controlRef: ["additionalInsured", "venue"],
        value: {
          id: "",
          type: "venue",
          address: {
            companyName: isDefaultProducerOrDefaultFacility
              ? ""
              : venue.additionalInsuredAddress.companyName,
            address: isDefaultProducerOrDefaultFacility
              ? ""
              : venue.additionalInsuredAddress.address1,
            address2: isDefaultProducerOrDefaultFacility
              ? ""
              : venue.additionalInsuredAddress.address2,
            city: isDefaultProducerOrDefaultFacility
              ? ""
              : venue.additionalInsuredAddress.city,
            state: isDefaultProducerOrDefaultFacility
              ? ""
              : venue.additionalInsuredAddress.state,
            zip: isDefaultProducerOrDefaultFacility
              ? ""
              : venue.additionalInsuredAddress.zip,
          },
        },
      });
    }

    return state;
  },
  venueCodeNotFound: ({ updateValues }, state) => {
    state = updateValues(state, {
      controlRef: ["venue", "venueSearchResults"],
      value: {
        knownVenue: false,
        hasTaxLookup: false,
        taxRegions: [],
        utcOffset: null,
      },
    });
    return state;
  },
  venueLookupFailure: ({ updateValues }, state) =>
    updateValues(state, {
      controlRef: ["venue", "venueSearchResults", "apiError"],
      value: true,
    }),
  /**
   * @description Selects if venue is KentuckyStateEntity and adds/remove venueMunicipalityCode control accordingly
   */
  selectKentuckyStateEntity: (
    { updateValues, addControl, removeControl },
    state,
    { payload }: Action<"yes" | "no">
  ) => {
    const taxFieldRef = ["venue", "venueSearchResults", "taxFields"];
    const kentuckyStateEntityRef = taxFieldRef.concat("kentuckyStateEntity");
    const venueMunicipalityCodeRef = taxFieldRef.concat(
      "venueMunicipalityCode"
    );
    state = updateValues(state, {
      controlRef: kentuckyStateEntityRef,
      value: payload,
    });

    if (payload === "no") {
      state = addControl(state, {
        controlRef: venueMunicipalityCodeRef,
        config: venueMunicipalityCode,
      });
    } else if (
      payload === "yes" &&
      state.form[venueMunicipalityCodeRef.join(".")]
    ) {
      state = removeControl(state, venueMunicipalityCodeRef);
    }
    return state;
  },
});
