import { group, control } from "@reactables/forms";
import { differentAddressFieldsNoPersonName } from "./address.config";
import { Coverage } from "Features/Policies/Models/coverage.model";

export const sameAddress = control(["yes"]);

export const insuranceContact = (coverage: Coverage) =>
  group({
    controls: {
      sameAddressAsInsured: control([coverage.contactSame]),
      firstName: control([coverage.insuranceContactFirstName, "required"]),
      lastName: control([coverage.insuranceContactLastName, "required"]),
      phone: control({
        initialValue: coverage.insuranceContactPhone,
        normalizers: ["normalizePhone"],
        validators: ["required", "phoneNumber"],
      }),
      email: control({
        initialValue: coverage.insuranceContactEmail,
        normalizers: ["normalizeEmail"],
        validators: ["required", "email"],
      }),
      ...(coverage.contactSame === "no"
        ? {
            differentAddressFields:
              differentAddressFieldsNoPersonName(coverage),
          }
        : undefined),
    },
  });
