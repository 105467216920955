import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { dateHelpers } from "@jauntin/utilities";
import { stringHelpers } from "../../../../Helpers/FormattingHelpers";
import PolicyBreadcrumb from "./PolicyBreadcrumb";

const PolicyDetailsHeader = ({ coverage }) => {
  const location = useLocation();
  const timezone = useSelector(
    ({ app }: { app: { timezone: string } }) => app.timezone
  );

  return (
    <div className="subsection-menu__header">
      <PolicyBreadcrumb
        location={location.pathname}
        policyNumber={coverage.policyNumberGll}
      />
      <h3>
        {stringHelpers.renterName(coverage)}
        {`'s`}
        {` ${coverage.eventTypeName}`}
      </h3>
      <div>
        {coverage.status === "active" && (
          <span className="subsection-menu__badge subsection-menu__badge--bound">
            Bound
          </span>
        )}
        {coverage.status === "cancelled" && (
          <span className="subsection-menu__badge subsection-menu__badge--cancelled">
            Canceled
          </span>
        )}
        {coverage.status === "expired" && (
          <span className="subsection-menu__badge subsection-menu__badge--expired">
            Expired
          </span>
        )}
        <span className="subsection-menu__date ml-2">
          {`Created on ${dateHelpers.lettersAndNumbers(
            coverage.created,
            timezone
          )}`}
        </span>
      </div>
    </div>
  );
};

export default PolicyDetailsHeader;
