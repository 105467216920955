import React from "react";
import { TextInput, StateField } from "@jauntin/react-ui";
import { Field } from "@reactables/react-forms";
import { getLgSelectStyle } from "Helpers/ReactSelectStyle";
import { ExtendedMeta } from "@jauntin/utilities";
import { StylesConfig } from "react-select";

const AddressFieldGroup = ({
  groupName,
  usStates,
  disabled,
  onAddressChange,
  onAddress2Change,
  onCityChange,
  onStateChange,
  onZipChange,
  includeAddress2 = false,
  showErrors = ({ touched, error }: ExtendedMeta) => Boolean(touched && error),
  stateSelectStyles = getLgSelectStyle(),
  stateIsClearable = false,
  stateIsSearchable = true,
}: {
  groupName: string;
  usStates: Array<{ code: string; name: string }>;
  disabled?: boolean;
  onAddressChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddress2Change?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCityChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onStateChange?: (value: string) => void;
  onZipChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  includeAddress2?: boolean;
  showErrors?: ({ touched, error }: ExtendedMeta) => boolean;
  stateSelectStyles?: StylesConfig;
  stateIsClearable?: boolean;
  stateIsSearchable?: boolean;
}) => (
  <>
    <Field
      component={TextInput}
      name={`${groupName}.address`}
      label="Street Address"
      ariaLabel="Address"
      placeholder="Address"
      inputClassName="form-control-lg"
      subtext="Mailing address, including apartment, suite, unit, building, floor, etc.."
      onChange={onAddressChange}
      disabled={disabled}
      showErrors={showErrors}
    />
    {includeAddress2 && (
      <Field
        component={TextInput}
        name={`${groupName}.address2`}
        inputClassName="form-control-lg"
        onChange={onAddress2Change}
        disabled={disabled}
        showErrors={showErrors}
      />
    )}

    <div className="form-row">
      <Field
        component={TextInput}
        name={`${groupName}.city`}
        label="City"
        ariaLabel="City"
        placeholder="City"
        wrapperClassName="col-sm"
        inputClassName="form-control-lg"
        onChange={onCityChange}
        disabled={disabled}
        showErrors={showErrors}
      />
      <Field
        component={StateField}
        name={`${groupName}.state`}
        usStates={usStates}
        customStyles={stateSelectStyles}
        onChange={onStateChange}
        disabled={disabled}
        selectClassName="select-input"
        showErrors={showErrors}
        isClearable={stateIsClearable}
        searchable={stateIsSearchable}
      />
      <Field
        component={TextInput}
        name={`${groupName}.zip`}
        label="Zipcode"
        ariaLabel="Zipcode"
        placeholder="Zipcode"
        pattern="\d*"
        inputMode="numeric"
        wrapperClassName="col-sm"
        inputClassName="form-control-lg"
        errorMessages={{
          zipCode: "Invalid. Valid examples: 90210 or 53203-2104",
        }}
        onChange={onZipChange}
        disabled={disabled}
        showErrors={showErrors}
      />
    </div>
  </>
);

export default AddressFieldGroup;
