import { TextInput } from "@jauntin/react-ui";
import { useSelector } from "react-redux";
import { Field } from "@reactables/react-forms";
import RadioInput from "Features/Shared/Components/RadioInput";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";
import { ContactType } from "../Configs/contactTypes";
import CompanyTypeField from "./CompanyTypeField";
import JointVentureField from "./JointVentureField";
import { EditPolicyProp } from "../RxEditPolicy";
import { ExtendedFormControl } from "@jauntin/utilities";

const InsuredFields = ({
  rxEditPolicy: [state, actions],
}: {
  rxEditPolicy: EditPolicyProp;
}) => {
  const usStates = useSelector(
    (state: { app: { states } }) => state.app.states
  );
  if (!usStates) return;

  const { insured } = state.form.root.value;
  const insuredPerson = insured.personName;
  const hasQuoteSameError = state.quoteValidation.data?.quoteSame === false;

  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Insured</strong>
      </div>
      <div className="card-body">
        <>
          <div className="form-group">
            <div className="label mb-3">
              Who is the renter of the venue/premises? OR Who is the permit
              holder?
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <Field
                id={`insured.typeInd`}
                name="insured.type"
                className="custom-control-input"
                component={RadioInput}
                value={ContactType.Person}
                onChange={() =>
                  actions.form.selectInsuredType(ContactType.Person)
                }
              />
              <label
                className="custom-control-label label"
                htmlFor={`insured.typeInd`}
              >
                an individual
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <Field
                id={`insured.typeCorp`}
                name="insured.type"
                className="custom-control-input"
                component={RadioInput}
                value={ContactType.Company}
                onChange={() =>
                  actions.form.selectInsuredType(ContactType.Company)
                }
              />
              <label
                className="custom-control-label label"
                htmlFor={`insured.typeCorp`}
              >
                a company/organization
              </label>
            </div>
          </div>
          {insuredPerson && (
            <div id="insuredIndividual" className="form-row">
              <Field
                component={TextInput}
                name="insured.personName.firstName"
                label="First Name"
                ariaLabel="First Name"
                placeholder="First Name"
                wrapperClassName="col-sm"
                inputClassName="form-control-lg"
              />
              <Field
                component={TextInput}
                name="insured.personName.lastName"
                label="Last Name"
                ariaLabel="Last Name"
                placeholder="Last Name"
                wrapperClassName="col-sm"
                inputClassName="form-control-lg"
              />
            </div>
          )}
          {insured.companyDetails && (
            <div id="insuredCompanyGroup">
              <div className="row">
                <Field
                  component={TextInput}
                  name="insured.companyDetails.name"
                  label="Company name"
                  ariaLabel="Company name"
                  placeholder="Company name"
                  wrapperClassName="col-sm"
                  inputClassName="form-control-lg"
                />
              </div>
              <div className="row">
                <Field
                  component={CompanyTypeField}
                  name="insured.companyDetails.type"
                  onChange={actions.form.selectInsuredCompanyType}
                />
                {insured.companyDetails?.jointVentureType && (
                  <JointVentureField name="insured.companyDetails.jointVentureType" />
                )}
              </div>
            </div>
          )}
          <AddressFieldGroup
            groupName="insured.address"
            usStates={usStates}
            showErrors={({
              touched,
              error,
              dirty,
              controlRef,
            }: ExtendedFormControl) => {
              return (
                (touched && Boolean(error)) ||
                (dirty &&
                  controlRef.join(".") === "insured.address.state" &&
                  hasQuoteSameError)
              );
            }}
          />
        </>
      </div>
    </div>
  );
};

export default InsuredFields;
