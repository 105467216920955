import { useReactable } from "@reactables/react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { dateHelpers } from "@jauntin/utilities";
import { ControlModels, Form, RxFormActions } from "@reactables/react-forms";
import { RxToggle } from "@jauntin/reactables";
import { getHasPriceChangeError, RxEditPolicy } from "./RxEditPolicy";
import { Button } from "@jauntin/react-ui";
import { RxPolicyProp } from "../Policy/RxPolicy";
import VenuePresetsService from "Helpers/VenuePresetsService";
import StateTaxService from "Helpers/StateTaxService";
import AppInfoService from "Helpers/AppInfoService";
import CoverageService from "Helpers/CoverageService";
import API from "Helpers/API";
import { EditPolicyFormValue } from "Features/Policies/Models/editPolicyFormValue.model";
import EventFields from "./EventFields/EventFields";
import InsuredFields from "./InsuredFields/InsuredFields";
import CohostFields from "./CohostFields/CohostFields";
import InsuranceContactFields from "./InsuranceContactFields/InsuranceContactFields";
import VenueFields from "./VenueFields/VenueFields";
import AdditionalInsuredFields from "./AdditionalInsuredFields/AdditionalInsuredFields";
import ModalUpdate from "Features/Shared/Components/ModalUpdate";
import { Link } from "react-router-dom";
import { getUrl, POLICY_PAGE } from "Helpers/URLParser";
import BasicCoverage from "../PolicyView/BasicCoverage";
import AdditionalCoverages from "../PolicyView/AdditionalCoverages";
import Payment from "../PolicyView/Payment";

const AlertPremiumChange = ({ show }) => (
  <Alert show={show} variant="danger" className="mx-auto mb-0 mt-3">
    <div className="form-row">
      <div className="col-auto">
        <i className="fal fa-info-circle icon--large" />
      </div>
      <div className="col">
        This edit requires a change in premium and cannot be saved due to a
        change in the highlighted field(s). To make this change, please cancel
        the policy and have the customer repurchase a policy with different
        parameters.
      </div>
    </div>
  </Alert>
);

const AlertFormsAreDifferent = ({ show }) => (
  <Alert show={show} variant="danger" className="mx-auto mb-0 mt-3">
    <div className="form-row">
      <div className="col-auto">
        <i className="fal fa-info-circle icon--large" />
      </div>
      <div className="col">
        This policy is impacted by policy form updates. If a change is required,
        please cancel the policy and have the policyholder purchase another
        policy. Also advise the policyholder that some forms may change on
        repurchase and could potentially result in a restriction in coverage.
      </div>
    </div>
  </Alert>
);

const EditPolicy = ({ rxPolicy }: { rxPolicy: RxPolicyProp }) => {
  const [
    {
      entity: { data: policy },
      updatePolicy,
    },
    policyActions,
  ] = rxPolicy;

  const { timezone } = useSelector(
    (appState: { app: { timezone } }) => appState.app
  );

  const rxEditPolicy = useReactable(RxEditPolicy, {
    coverage: policy,
    venueService: new VenuePresetsService(new API()),
    stateTaxService: new StateTaxService(new API()),
    appInfoService: new AppInfoService(new API()),
    coverageService: new CoverageService(new API()),
    timezone,
  });

  const [showUpdateModal, updateModalActions] = useReactable(RxToggle);

  const [state, { form: formActions }] = rxEditPolicy;

  if (!state) return;

  const { form: formState, quoteValidation } = state;

  const hasFormSameError = quoteValidation.data?.formsSame === false;
  const showPriceChangeError =
    getHasPriceChangeError(state) && !hasFormSameError;

  const rxForm = [formState, formActions] as [
    ControlModels.Form<EditPolicyFormValue>,
    RxFormActions
  ];

  return (
    <>
      <div className="content__header col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Edit Policy</h4>
          <div className="d-flex float-right">
            <Link to={getUrl(POLICY_PAGE, policy.id)}>
              <Button
                onClick={() => {}}
                text="Discard"
                className="btn-outline-secondary px-4 mx-4"
              />
            </Link>
            <Button
              onClick={updateModalActions.toggleOn}
              disabled={
                !formState.root.valid ||
                !formState.root.dirty ||
                showPriceChangeError ||
                hasFormSameError
              }
              text="Update Policy"
              className="btn-primary px-4"
            />
          </div>
        </div>

        <AlertPremiumChange show={showPriceChangeError} />
        <AlertFormsAreDifferent show={hasFormSameError} />
      </div>
      <div className="content__body">
        <Form rxForm={rxForm}>
          <BasicCoverage policy={policy} />
          <EventFields rxEditPolicy={rxEditPolicy} />
          <AdditionalCoverages policy={policy} />
          <InsuredFields rxEditPolicy={rxEditPolicy} />
          <CohostFields rxPolicy={rxPolicy} rxEditPolicy={rxEditPolicy} />
          <InsuranceContactFields rxEditPolicy={rxEditPolicy} />
          <VenueFields rxEditPolicy={rxEditPolicy} />
          <AdditionalInsuredFields rxEditPolicy={rxEditPolicy} />
          <Payment policy={policy} />
        </Form>
      </div>
      {showUpdateModal && (
        <ModalUpdate
          show={showUpdateModal}
          text={`Update coverage for "${
            policy.eventName
          }" ${dateHelpers.slashSeparated(
            policy.eventDateRange.startDate,
            timezone
          )} - ${dateHelpers.slashSeparated(
            policy.eventDateRange.endDate,
            timezone
          )}?`}
          confirmMsg="Yes, I confirm that I would like to update this policy."
          action={() =>
            policyActions.updatePolicy.send({
              form: formState,
              policy,
              timezone,
            })
          }
          actionBtnTxt="Confirm Update"
          updating={updatePolicy.loading}
          closeAction={updateModalActions.toggleOff}
        />
      )}
    </>
  );
};

export default EditPolicy;
