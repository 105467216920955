import { CustomReducers } from "@reactables/forms";
import { Action } from "@reactables/core";
import { facility } from "../Configs/additionalInsured.config";
import { control } from "@reactables/forms";

export interface AdditionalInsuredActions {
  editFacilityAdditionalInsured: () => void;
  clearFacilityAdditionalInsured: () => void;
  clearFieldsAdditionalInsured: (payload: "venueAddress" | "venue") => void;
  resetFacilityAdditionalInsured: () => void;
}

export const clearFacilityAdditionalInsured = (
  { removeControl, addControl },
  state
) => {
  state = removeControl(state, ["additionalInsured", "facility"]);

  state = addControl(state, {
    controlRef: ["additionalInsured", "facility"],
    config: control([null]),
  });

  return state;
};

export const editFacilityAdditionalInsured = (
  { addControl, removeControl },
  state
) => {
  if (state.form["additionalInsured.facility"].value === null) {
    state = removeControl(state, ["additionalInsured", "facility"]);
    state = addControl(state, {
      controlRef: ["additionalInsured", "facility"],
      config: facility(),
    });
  }

  return state;
};
export const clearFieldsAdditionalInsured = (
  { updateValues },
  state,
  action: Action<"venueAddress" | "venue">
) => {
  state = updateValues(state, {
    controlRef: ["additionalInsured", action.payload, "address"],
    value: {
      companyName: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
  });
  return state;
};

export const additionalInsuredReducers: CustomReducers<AdditionalInsuredActions> =
  {
    editFacilityAdditionalInsured,
    clearFacilityAdditionalInsured,
    clearFieldsAdditionalInsured,
    resetFacilityAdditionalInsured: ({ resetControl }, state) =>
      resetControl(state, ["additionalInsured", "facility"]),
  };
