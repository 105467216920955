import { ControlModels } from "@reactables/forms";
import { Coverage } from "Features/Policies/Models/coverage.model";
import { PolicyContactsForm } from "Features/Policies/Models/policyContactsForm.model";
import { getPolicyEmails } from "./RxPolicyContactsForm";
import { TextInput } from "@jauntin/react-ui";
import { WrappedFieldProps } from "@jauntin/utilities";

const CustomContactEmailField = ({
  coverage,
  contactsFormGroup,
  emailControl,
  index,
  ...props
}: {
  coverage: Coverage;
  contactsFormGroup: ControlModels.FormControl<PolicyContactsForm>;
  emailControl: ControlModels.FormControl<string>;
  index: number;
}) => {
  const filteredCustomContactsEmails = contactsFormGroup.value.customContacts
    .filter((_, i) => i !== index)
    .map(({ contactInfo }) => contactInfo.email);

  const policyContacts = getPolicyEmails(coverage);

  const allEmails = [...filteredCustomContactsEmails, ...policyContacts].filter(
    (email) => Boolean(email)
  );

  const extendedControl = {
    ...emailControl,
    errors: {
      ...emailControl.errors,
      uniqueCustomContactEmails:
        contactsFormGroup.errors.uniqueCustomContactEmails &&
        allEmails.includes(emailControl.value),
    },
  };

  const fieldProps = {
    ...props,
    meta: extendedControl,
  } as unknown as WrappedFieldProps;

  return (
    <TextInput
      {...fieldProps}
      errorMessages={{
        uniqueCustomContactEmails: "This email already exists for this policy",
      }}
    />
  );
};

export default CustomContactEmailField;
