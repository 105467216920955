import { Observable } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { ControlModels, ValidatorAsyncFn } from "@reactables/forms";
import { SelectPlaceAddressSearchPayload } from "../Models/venue.model";

export const validateUsState =
  (
    usStates$: () => Observable<Array<{ code: string; name: string }>>
  ): ValidatorAsyncFn =>
  (control$) =>
    control$.pipe(
      switchMap((control) => {
        return usStates$().pipe(
          map((states) => {
            const { value } =
              control as ControlModels.BaseControl<SelectPlaceAddressSearchPayload>;

            const stateInvalid =
              value?.addressComponents &&
              !states.some((e) => e.code === value.addressComponents?.state);

            return {
              validateUsState: stateInvalid,
            };
          })
        );
      })
    );
