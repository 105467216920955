import { ExtendedFormControl } from "@jauntin/utilities";
import { useSelector } from "react-redux";
import { ControlModels, RxFormActions } from "@reactables/react-forms";
import { EditPolicyFormValue } from "Features/Policies/Models/editPolicyFormValue.model";
import { Field } from "@reactables/react-forms";
import { TextInput } from "@jauntin/react-ui";
import AddressFieldGroup from "Features/Shared/Components/AddressFieldGroup";
import { getLgSelectStyleFromControl } from "Helpers/ReactSelectStyle";

const ManualVenueAddress = ({
  rxForm,
  hasPriceChangeError,
}: {
  rxForm: [ControlModels.Form<EditPolicyFormValue>, RxFormActions];
  hasPriceChangeError: boolean;
}) => {
  const usStates = useSelector(({ app }: { app: { states } }) => app.states);

  const [formState, formActions] = rxForm;
  const {
    venue: {
      byManualAddress: { address },
    },
  } = formState.root.value;

  if (!usStates?.length) {
    return;
  }
  const showPriceChangeDirtyError = ({
    touched,
    error,
    dirty,
    controlRef,
  }: ExtendedFormControl) =>
    (touched && Boolean(error)) ||
    (hasPriceChangeError &&
      dirty &&
      controlRef.join(".") === "venue.byManualAddress.address.state");
  return (
    <>
      <div className="form-group">
        <Field
          component={TextInput}
          name="venue.byManualAddress.venueName"
          placeholder="Venue Name"
          label="Venue Name"
          inputClassName="form-control-lg"
          onChange={(e) => {
            const value = e.target.value;
            formActions.updateValues({
              controlRef: ["venue", "byManualAddress", "venueName"],
              value,
            });
            formActions.updateValues({
              controlRef: [
                "additionalInsured",
                "venueAddress",
                "address",
                "companyName",
              ],
              value,
            });
          }}
        />
      </div>
      <div className="form-group">
        <AddressFieldGroup
          groupName="venue.byManualAddress.address"
          usStates={usStates}
          onAddressChange={(e) => {
            const value = e.target.value;
            formActions.updateValues({
              controlRef: ["venue", "byManualAddress", "address", "address"],
              value,
            });
            formActions.updateValues({
              controlRef: [
                "additionalInsured",
                "venueAddress",
                "address",
                "address",
              ],
              value,
            });
          }}
          onCityChange={(e) => {
            formActions.manualTaxAreaChange({
              city: e.target.value,
              state: address.state as string,
            });
            formActions.updateValues({
              controlRef: [
                "additionalInsured",
                "venueAddress",
                "address",
                "city",
              ],
              value: e.target.value,
            });
          }}
          onStateChange={(usState) => {
            formActions.manualTaxAreaChange({
              city: address.city,
              state: usState,
            });
            formActions.updateValues({
              controlRef: [
                "additionalInsured",
                "venueAddress",
                "address",
                "state",
              ],
              value: usState,
            });
          }}
          onZipChange={(e) => {
            formActions.manualZipCodeChange(e.target.value);
            formActions.updateValues({
              controlRef: [
                "additionalInsured",
                "venueAddress",
                "address",
                "zip",
              ],
              value: e.target.value,
            });
          }}
          stateSelectStyles={getLgSelectStyleFromControl(
            formState["venue.byManualAddress.address.state"],
            showPriceChangeDirtyError
          )}
          showErrors={showPriceChangeDirtyError}
        />
      </div>
    </>
  );
};

export default ManualVenueAddress;
