import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  getUrl,
  getPolicyId,
  getPageType,
  POLICIES_PAGE,
  POLICY_PAGE,
  POLICIES_NOTES_PAGE,
  POLICY_CONTACT_PAGE,
} from "../../../../Helpers/URLParser";

const PolicyBreadcrumbs = ({ location, policyNumber }) => {
  const pageType = getPageType(location);

  return (
    <div className="breadcrumbs">
      <NavLink to={getUrl(POLICIES_PAGE)}>Policies</NavLink>
      {pageType === POLICY_PAGE && ` / Policy # ${policyNumber}`}
      {pageType !== POLICY_PAGE && (
        <>
          {" / "}
          <NavLink to={getUrl(POLICY_PAGE, getPolicyId(location))}>
            {`Policy # ${policyNumber}`}
          </NavLink>
          {" / "}
        </>
      )}
      {pageType === POLICY_CONTACT_PAGE && "Contacts"}
      {pageType === POLICIES_NOTES_PAGE && "Notes"}
    </div>
  );
};

PolicyBreadcrumbs.propTypes = {
  location: PropTypes.string.isRequired,
  policyNumber: PropTypes.string,
};

PolicyBreadcrumbs.defaultProps = {
  policyNumber: "",
};

export default PolicyBreadcrumbs;
