import { Reactable } from "@reactables/core";
import { ControlModels } from "@reactables/forms";
import { RequestActions, RequestState, RxEntity } from "@jauntin/reactables";
import {
  AddressContactType,
  ContactType,
} from "../EditPolicy/Configs/contactTypes";
import { CompanyTypes } from "../EditPolicy/Configs/contactTypes";
import CoverageService from "../../../../Helpers/CoverageService";
import { convertServerCoverageToCoverageModel } from "../../../../Helpers/CoverageModel";
import { Coverage } from "../../Models/coverage.model";
import { HookedReactable } from "@reactables/react";
import { EditPolicyFormValue } from "Features/Policies/Models/editPolicyFormValue.model";
import { getEventDatesFromForm } from "../EditPolicy/FormReducers/event.reducer";
import { dateHelpers } from "@jauntin/utilities";
import { getVenueAddressFromForm } from "../EditPolicy/VenueFields/VenueFields";
import {
  getContactsAndEmailPayload,
  PolicyContactsForm,
} from "Features/Policies/Models/policyContactsForm.model";

interface PolicyState {
  entity: RequestState<Coverage>;
  updatePolicy: RequestState<unknown>;
  policyEmails: RequestState<unknown>;
  customContacts: RequestState<unknown>;
  acknowledgePolicyUpdated: RequestState<unknown>;
  cancelPolicy: RequestState<{ refunded: boolean; cancelled: boolean }>;
  addNote: RequestState<unknown>;
}

interface PolicyActions {
  updatePolicy: RequestActions<{
    form: ControlModels.Form<EditPolicyFormValue>;
    policy: Coverage;
    timezone: string;
  }>;
  policyEmails: RequestActions<{
    form: ControlModels.Form<PolicyContactsForm>;
    coverage: Coverage;
  }>;
  customContacts: RequestActions<{
    form: ControlModels.Form<PolicyContactsForm>;
    coverage: Coverage;
  }>;
  acknowledgePolicyUpdated: RequestActions<Coverage>;
  cancelPolicy: RequestActions<undefined>;
  addNote: RequestActions<{ id: number; message: string }>;
}

export type RxPolicyProp = HookedReactable<PolicyState, PolicyActions>;

export const RxPolicy = ({
  id,
  coverageService,
  timezone,
  onUpdateSuccess,
}: {
  id: number;
  coverageService: CoverageService;
  timezone: string;
  onUpdateSuccess?: () => void;
}) =>
  RxEntity<Coverage, PolicyActions>({
    name: "Policy",
    getEntityResource: () =>
      coverageService
        .getCoverage(id)
        .then(({ data: coverage }) =>
          convertServerCoverageToCoverageModel(coverage, timezone)
        ),
    entityActions: {
      addNote: {
        resource: ({ message }: { message: string }) =>
          coverageService.postAddNote({ id, message }),
      },
      cancelPolicy: {
        resource: () =>
          coverageService.postCancelCoverage(id).then(({ data }) => data),
      },
      updatePolicy: {
        resource: ({
          form,
          policy,
          timezone,
        }: {
          form: ControlModels.Form<EditPolicyFormValue>;
          policy: Coverage;
          timezone: string;
        }) => {
          // Map values to payload
          const formValue = form.root.value;
          const {
            insured,
            event,
            venue,
            insuranceContact,
            additionalInsured,
            cohost,
          } = formValue;

          const additionalInsureds = Object.entries(additionalInsured).map(
            ([key, ai]) => {
              if (key === "facility" && ai === null) {
                return {
                  type: "facility",
                  address: {
                    address1: "",
                    address2: "",
                    city: "",
                    companyName: "",
                    country: "US",
                    state: "",
                    zip: "",
                  },
                };
              }
              if (ai.type === "text") return ai;

              const { address: address1, ...otherAddressFields } = ai.address;

              return {
                ...ai,
                address: {
                  address1,
                  ...otherAddressFields,
                  country: "US",
                },
              };
            }
          );

          const { id } = policy;

          const { taxFields } = venue.venueSearchResults;
          const kentuckyEntity =
            taxFields?.kentuckyStateEntity === "yes" || policy.isKentuckyEntity;
          const federalEntity =
            taxFields?.federalEntity === "yes" || policy.isFederalEntity;

          const venueSearchType = venue.searchType;

          const { knownVenue } = venue.venueSearchResults;

          const insuredContactType: AddressContactType = (() => {
            if (insured.type === ContactType.Person) {
              return insured.type;
            }

            if (insured.companyDetails.type === CompanyTypes.JointVenture) {
              return insured.companyDetails.jointVentureType;
            } else {
              return insured.companyDetails.type;
            }
          })() as AddressContactType;

          const renterAddress = {
            address1: insured.address.address,
            city: insured.address.city,
            state: insured.address.state,
            country: "US",
            zip: insured.address.zip,
            contactType: insuredContactType,
            ...(() => {
              if (insuredContactType === ContactType.Person) {
                return {
                  contactFirstName: insured.personName.firstName,
                  contactLastName: insured.personName.lastName,
                };
              } else {
                return {
                  companyName: insured.companyDetails.name,
                };
              }
            })(),
          };

          const data = {
            id,
            coverageApplication: {
              isKentuckyEntity: kentuckyEntity ? 1 : 0,
              isFederalEntity: federalEntity ? 1 : 0,
              venueSearchType,
              event: {
                name: event.eventName,
                dates: getEventDatesFromForm(form, timezone).map((m) =>
                  dateHelpers.dateOnlyStringFormat(m)
                ),
                eventFrequency: event.eventFrequencyField,
                averageDailyAttendance: event.eventDailyGuests,
              },
              renter_address: renterAddress,
              ...(cohost
                ? {
                    cohost_address: {
                      address1: cohost.address,
                      contactFirstName: cohost.contactFirstName,
                      contactLastName: cohost.contactLastName,
                      city: cohost.city,
                      state: cohost.state,
                      zip: cohost.zip,
                      country: "US",
                    },
                  }
                : undefined),
              insuranceContactPhone: insuranceContact.phone,
              insuranceContactEmail: insuranceContact.email,
              insurance_contact_address: {
                contactFirstName: insuranceContact.firstName,
                contactLastName: insuranceContact.lastName,
                ...(() => {
                  if (insuranceContact.sameAddressAsInsured === "yes") {
                    return {
                      companyName:
                        insured.type === ContactType.Company
                          ? insured.companyDetails.name
                          : undefined,
                      address1: insured.address.address,
                      city: insured.address.city,
                      state: insured.address.state,
                      country: "US",
                      zip: insured.address.zip,
                      contactType: insuredContactType,
                    };
                  } else {
                    return {
                      companyName:
                        insuranceContact.differentAddressFields.type ===
                        ContactType.Company
                          ? insuranceContact.differentAddressFields.companyName
                          : insured.companyDetails?.name || undefined,
                      address1:
                        insuranceContact.differentAddressFields.address.address,
                      city: insuranceContact.differentAddressFields.address
                        .city,
                      state:
                        insuranceContact.differentAddressFields.address.state,
                      country: "US",
                      zip: insuranceContact.differentAddressFields.address.zip,
                      contactType:
                        insuranceContact.differentAddressFields.type ===
                        ContactType.Person
                          ? ContactType.Person
                          : CompanyTypes.Other,
                    };
                  }
                })(),
              },
              venue_address: [
                {
                  id: policy.venueAddressId,
                  placeId: (() => {
                    if (knownVenue && knownVenue.places?.length) {
                      return knownVenue.places[0]?.placeId;
                    } else {
                      return venue.bySearch?.selectedPlace?.placeId || "";
                    }
                  })(),
                  facilityCode:
                    (venue.byVenueCode && knownVenue?.facility?.code) || "",
                  venueCode:
                    (venue.byVenueCode && knownVenue?.venue?.venueCode) || "",
                  ...getVenueAddressFromForm(form),
                  utcOffset: venue.venueSearchResults.utcOffset || 0,
                  municipalTaxCode:
                    venue.venueSearchResults.taxFields?.venueMunicipalityCode ||
                    null,
                },
              ],
            },
            additional_insureds: additionalInsureds,
          };

          return coverageService.putCoverageEditDetails(
            data
          ) as Promise<unknown>;
        },
        onSuccess: () => {
          onUpdateSuccess && onUpdateSuccess();
        },
      },
      policyEmails: {
        resource: (params: {
          form: ControlModels.Form<PolicyContactsForm>;
          coverage: Coverage;
        }) => {
          const { emails } = getContactsAndEmailPayload(params);

          return coverageService.postSendPolicyEmails(emails);
        },
      },
      customContacts: {
        resource: (params: {
          form: ControlModels.Form<PolicyContactsForm>;
          coverage: Coverage;
        }) => {
          const { customContacts } = getContactsAndEmailPayload(params);

          return coverageService.postCustomContacts(
            params.coverage.id,
            customContacts
          );
        },
      },
      acknowledgePolicyUpdated: {
        resource: (coverage: Coverage) =>
          coverageService.postAcknowledgeChanges(coverage),
      },
    },
  }) as Reactable<PolicyState, PolicyActions>;
